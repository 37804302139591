import { useContext } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { datadogLogs } from '@datadog/browser-logs';
import { Button, FormControl, TextField } from '@mui/material';

import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import useTraining from 'src/hooks/useTraining';
import { selectFacility, selectStaffMemberId } from 'src/store/configSlice';
import {
    Task,
    useAddCareEventNoteMutation,
    useCreateNoteForTaskMutation,
} from 'src/store/sageAdminApi';
import { snackActions } from 'src/utils/snackBarTools';

type FormState = {
    notes: string;
};

interface AddTaskNoteDrawerProps extends DrawerComponentProps {
    characterLimit?: number;
    isRequired?: boolean;
    noteSubject?: string;
    pageName?: string;
    careEventId?: string;
    showCancelAction?: boolean;
    submitButtonLabel?: string;
    task?: Task;
    taskId: string;
    title?: string;
}

function AddTaskNoteDrawer(props: AddTaskNoteDrawerProps) {
    const {
        characterLimit = 255,
        isRequired = false,
        noteSubject = 'Task Note',
        careEventId,
        onPrevious,
        onNext,
        pageName,
        showCancelAction = true,
        submitButtonLabel = 'Submit',
        task,
        taskId,
        title = 'Add a Note',
    } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const staffMemberProfileId = useSelector(selectStaffMemberId);
    const facility = useSelector(selectFacility);

    const [addTaskNote, { isLoading: isCreatingNote }] = useCreateNoteForTaskMutation();
    const [addCareEventNote, { isLoading: isAddingCareEventNote }] = useAddCareEventNoteMutation();

    const { control, handleSubmit, formState } = useForm<FormState>({
        mode: 'onChange',
        defaultValues: { notes: '' },
    });

    const { addNoteToTrainingAlert, isTrainingAlert } = useTraining({
        taskRid: taskId,
    });

    const notes = useWatch({ control, name: 'notes' });
    const difference = Math.abs((notes?.length ?? 0) - characterLimit);

    const shouldDisableActions =
        !formState.isDirty || !formState.isValid || isCreatingNote || isAddingCareEventNote;

    const handleGoBack = () => {
        onPrevious(true, ['notes']);
    };

    const validateNoteValue = (value: string) => {
        if (isRequired && !value.length) {
            return 'This field is required';
        }

        const difference = Math.abs(value.length - characterLimit);
        return value.length > characterLimit ? `${difference} characters over the limit` : true;
    };

    const handleSubmitCareEventNotes = async (data: FormState) => {
        trackEvent('Add Care Event Note Clicked', {
            objectType: ObjectTypes.Button,
            pageName,
            careEventId,
            buttonText: submitButtonLabel,
        });

        try {
            await addCareEventNote({
                careEventId: careEventId!,
                facilityId: facility.id!,
                createCareEventNoteRequest: {
                    noteType: 'CAREGIVER_NOTE',
                    content: data.notes,
                    creatorRid: staffMemberProfileId,
                },
            }).unwrap();

            snackActions.success('Note added successfully');
            onNext();
        } catch (error: any) {
            datadogLogs.logger.error(
                'Failed to add note',
                {
                    noteLength: data.notes.length,
                    pageName,
                    careEventId,
                    noteType: noteSubject,
                    taskSeverity: task?.severity,
                },
                error
            );
            snackActions.error('Failed to add note');
        }
    };

    const handleSubmitNotes = async (data: FormState) => {
        const { notes } = data;

        if (careEventId) {
            return handleSubmitCareEventNotes(data);
        } else {
            trackEvent('Add Task Note Clicked', {
                objectType: ObjectTypes.Button,
                pageName,
                taskStatus: task?.status,
                taskSeverity: task?.severity,
                taskId,
                buttonText: submitButtonLabel,
                isTrainingAlert,
            });

            try {
                if (isTrainingAlert) {
                    addNoteToTrainingAlert(notes);
                } else {
                    await addTaskNote({
                        taskId,
                        createOrUpdateTaskNoteRequest: { subject: noteSubject, content: notes },
                    }).unwrap();
                }

                snackActions.success('Note added successfully');
                onNext();
            } catch (error: any) {
                datadogLogs.logger.error(
                    'Failed to add note',
                    {
                        noteLength: data.notes.length,
                        pageName,
                        taskId,
                        taskStatus: task?.status,
                        taskSeverity: task?.severity,
                    },
                    error
                );
                snackActions.error('Failed to add note');
            }
        }
    };

    return (
        <>
            <DrawerTitle title={title} />

            <form onSubmit={handleSubmit(handleSubmitNotes)}>
                <DrawerBody>
                    <FormControl fullWidth sx={{ py: 0 }}>
                        <Controller
                            name="notes"
                            control={control}
                            rules={{ validate: validateNoteValue }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    id="notes"
                                    label={
                                        isRequired
                                            ? 'Enter notes here'
                                            : 'Enter notes here (optional)'
                                    }
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    error={!!fieldState.error?.message}
                                    helperText={
                                        fieldState.error?.message ||
                                        `${difference} characters remaining`
                                    }
                                />
                            )}
                        />
                    </FormControl>
                </DrawerBody>

                <DrawerFooter sx={{ gap: 2 }}>
                    <Button
                        fullWidth
                        color="primary"
                        type="submit"
                        variant="contained"
                        loading={isCreatingNote || isAddingCareEventNote}
                        disabled={shouldDisableActions}
                        disableElevation
                    >
                        {submitButtonLabel}
                    </Button>

                    {showCancelAction && (
                        <Button color="primary" disableElevation fullWidth onClick={handleGoBack}>
                            Cancel
                        </Button>
                    )}
                </DrawerFooter>
            </form>
        </>
    );
}

export default AddTaskNoteDrawer;
