import { useContext, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { Switch } from '@sage/ui-components';

import RadioGroupOptions, { Variants } from '../fragments/RadioGroupOptions';
import SearchInput from '../fragments/SearchInput';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { Unit } from 'src/store/sageAdminApi';
import { pluralize } from 'src/utils/intlUtils';

import '../../assets/styles/buttonStyles.scss';

const DrawerName = 'Select Unit Drawer';

interface SelectUnitDrawerProps extends DrawerComponentProps {
    units: (Unit & { value: string })[];
    preSelectedUnitId?: string;
    showButtonsFilter?: boolean;
}

function SelectUnitDrawer(props: SelectUnitDrawerProps) {
    const {
        units,
        preSelectedUnitId,
        onNext,
        title = 'Select Unit',
        showButtonsFilter = false,
    } = props;
    const { trackEvent } = useContext(AnalyticsContext);
    const [selectedUnit, setSelectedUnit] = useState(preSelectedUnitId || '');
    const [searchTerm, setSearchTerm] = useState('');
    const [showUnitsWithNoButtons, setShowUnitsWithNoButtons] = useState(false);

    const filteredUnits = useMemo(() => {
        const unitsByDeviceCount = showUnitsWithNoButtons
            ? units?.filter((unit) => !unit.devices)
            : units;

        if (!!searchTerm) {
            return unitsByDeviceCount?.filter((unit) =>
                unit.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return unitsByDeviceCount;
        }
    }, [units, searchTerm, showUnitsWithNoButtons]);

    const handleUnitsFilter = () => {
        trackEvent('Toggle Clicked', {
            context: DrawerName,
            action: 'Filter units with no buttons',
            value: showUnitsWithNoButtons,
        });
        setShowUnitsWithNoButtons(!showUnitsWithNoButtons);
    };

    const handleSelectUnit = (unitId: string) => {
        trackEvent('Unit Selected', { unit: unitId, context: DrawerName });
        setSelectedUnit(unitId);
    };

    const handleContinue = () => {
        trackEvent('Continue Button Clicked', { context: DrawerName, unit: selectedUnit });
        onNext({ value: selectedUnit });
    };

    return (
        <>
            {/* Fixed Header */}
            <DrawerTitle title={title}>
                <SearchInput
                    analyticsPrefix={DrawerName}
                    searchValue={searchTerm}
                    onSearchChange={setSearchTerm}
                    placeholder="Search by Unit name"
                />

                {showButtonsFilter && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: 'grey.900',
                            }}
                        >
                            Only show units with no buttons assigned
                        </Typography>
                        <Switch
                            checked={showUnitsWithNoButtons}
                            value={showUnitsWithNoButtons}
                            onChange={handleUnitsFilter}
                            inputProps={{ 'aria-label': 'toggle units with no buttons' }}
                        />
                    </Box>
                )}
            </DrawerTitle>
            {/* Scrolling Area */}
            <DrawerBody>
                {filteredUnits?.length > 0 && (
                    <RadioGroupOptions
                        radioGroupProps={{ 'aria-label': 'unit' }}
                        variant={Variants.Outlined}
                        selectedOption={selectedUnit}
                        onSelectOption={handleSelectUnit}
                        options={filteredUnits?.map((unit) => ({
                            value: unit.value,
                            label: (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        pr: 2,
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: 'grey.900',
                                        }}
                                    >
                                        {unit.displayName}
                                    </Typography>
                                    {showButtonsFilter && unit.devices?.length && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'grey.900',
                                            }}
                                        >
                                            {pluralize(unit.devices.length, 'button', 'buttons')}
                                        </Typography>
                                    )}
                                </Box>
                            ),
                        }))}
                    />
                )}

                {filteredUnits?.length === 0 && (
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'grey.700',
                        }}
                    >
                        {!!searchTerm ? 'No units found' : 'No units'}
                    </Typography>
                )}
            </DrawerBody>
            {/* Bottom Sticky Buttons */}
            <DrawerFooter>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={!selectedUnit}
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </DrawerFooter>
        </>
    );
}

export default SelectUnitDrawer;
