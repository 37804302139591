import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SageSupportPhoneNumber } from 'src/constants/ExternalUrls';
import { Formats, formatPhoneNumber } from 'src/utils/formUtils';

import './styles/authFlow.scss';

// See https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#sign-up
export const Auth0Errors = {
    Login: {
        AccessDenied: 'access_denied',
        InvalidUserPassword: 'invalid_user_password',
        MfaInvalidCode: 'mfa_invalid_code',
        MfaRegistrationRequired: 'mfa_registration_required',
        MfaRequired: 'mfa_required',
        PasswordLeaked: 'password_leaked',
        PasswordHistoryError: 'PasswordHistoryError',
        PasswordStrengthError: 'PasswordStrengthError',
        TooManyAttempts: 'too_many_attempts',
        Unauthorized: 'unauthorized',
    },
    SignUp: {
        InvalidPasswrd: 'invalid_password',
        InvalidSignup: 'invalid_signup',
        PasswordDictionaryError: 'password_dictionary_error',
        PasswordNoUserInfoError: 'password_no_user_info_error',
        PasswordStrengthError: 'password_strength_error',
        Unauthorized: 'unauthorized',
        UserExists: 'user_exists',
        UsernameExists: 'username_exists',
    },
};

interface AuthErrorHeaderProps {
    title: string;
    caption: React.ReactNode;
}

export function AuthErrorHeader(props: AuthErrorHeaderProps) {
    const { caption, title } = props;
    return (
        <Stack
            className="authHeaderError"
            sx={{
                gap: 1,
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    fontWeight: 'bold',
                    color: 'white.main',
                }}
            >
                {title}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    color: 'white.main',
                }}
            >
                {caption}
            </Typography>
        </Stack>
    );
}

interface AuthHeaderProps {
    state?: string;
}

export default function AuthHeader(props: AuthHeaderProps) {
    const { palette } = useTheme();

    switch (props.state) {
        case Auth0Errors.Login.AccessDenied:
        case Auth0Errors.Login.MfaInvalidCode:
            return (
                <AuthErrorHeader
                    title="Invalid Authentication Code"
                    caption={
                        <span>
                            Re-enter the code that we sent to you. Still doesn&lsquo;t work? Call
                            Sage Support at{' '}
                            <a
                                href={`tel:${SageSupportPhoneNumber}`}
                                style={{ color: palette.common.white, fontWeight: 600 }}
                            >
                                {formatPhoneNumber(SageSupportPhoneNumber, Formats.National)}
                            </a>
                            {/**/}.
                        </span>
                    }
                />
            );
        case Auth0Errors.Login.TooManyAttempts:
            return (
                <AuthErrorHeader
                    title="Too many unsuccessful logins."
                    caption={
                        <span>
                            Your account has been locked to protect your data, please contact Sage
                            Support at{' '}
                            <a
                                href={`tel:${SageSupportPhoneNumber}`}
                                style={{ color: palette.common.white, fontWeight: 600 }}
                            >
                                {formatPhoneNumber(SageSupportPhoneNumber, Formats.National)}
                            </a>{' '}
                            to reactivate it.
                        </span>
                    }
                />
            );
        default:
            return null;
    }
}
