import mergeRefs from 'merge-refs';
import React, { Ref, forwardRef, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AppBar,
    AppBarProps,
    Box,
    Button,
    ButtonProps,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    IconButtonProps,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material';

import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import { selectBackButtonOverrideMap, setBackButtonOverride } from 'src/store/globalUISlice';

import './styles/DetailsPageHeader.styles.scss';

interface ActionIcon {
    label: string;
    icon: IconDefinition;
    iconProps?: object;
    iconButtonProps?: IconButtonProps;
}

interface ActionButton {
    label: string;
    buttonProps?: ButtonProps;
}

interface BackButtonProps {
    shouldConfirmAction?: boolean;
    confirmationDialogProps?: {
        title?: string;
        content?: React.ReactNode;
        confirmText?: string;
        cancelText?: string;
    };
}

interface DetailsPageHeaderShared {
    appBarStyles?: AppBarProps;
    analyticsPrefix?: string;
    backButton?: BackButtonProps;
    defaultBackRoute?: string;
    onAction?: Function;
    onBack?: Function;
    pageTitle?: string;
    hideTitle?: boolean;
    showBackButton?: boolean;
    children?: React.ReactNode;
}

interface DetailsPageHeaderWithActionIcon extends DetailsPageHeaderShared {
    actionIcon?: ActionIcon;
    actionButton?: never;
}

interface DetailsPageHeaderWithActionButton extends DetailsPageHeaderShared {
    actionIcon?: never;
    actionButton?: ActionButton;
}

type DetailsPageHeaderProps = DetailsPageHeaderWithActionIcon | DetailsPageHeaderWithActionButton;

const DefaultActionIconProps: ActionIcon = {
    label: 'Edit',
    icon: faPen,
    iconProps: {},
    iconButtonProps: {},
};

function DetailsPageHeader(props: DetailsPageHeaderProps, forwardedRef: Ref<HTMLElement>) {
    const {
        appBarStyles = {},
        actionIcon = DefaultActionIconProps,
        actionButton,
        analyticsPrefix,
        backButton,
        defaultBackRoute = '/',
        onAction,
        onBack,
        pageTitle,
        hideTitle = false,
        showBackButton = true,
        children,
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [appBarRef, { height: appBarHeight }] = useMeasure<HTMLDivElement>();

    const { trackEvent } = useContext(AnalyticsContext);
    const backButtonOverrideMap = useSelector(selectBackButtonOverrideMap);

    const actionIconObject = { ...DefaultActionIconProps, ...props.actionIcon };

    const shouldShowBackButton = showBackButton || !!onBack;

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const handleBackButtonAction = () => {
        if (!!onBack) {
            onBack();
        } else if (showBackButton) {
            if (backButtonOverrideMap[location.pathname]) {
                dispatch(setBackButtonOverride({ path: location.pathname, override: null }) as any);
                navigate(backButtonOverrideMap[location.pathname]);
            } else {
                // If the history is empty (e.g. user is coming from link), go to the default back page.
                // Otherwise, default to navigate(-1)
                if (window.history.length <= 2) {
                    navigate(defaultBackRoute);
                } else {
                    navigate(-1);
                }
            }
        }
    };

    const handleBackButtonClick = () => {
        trackEvent('Back Button Clicked', { pageTitle, context: analyticsPrefix });

        if (backButton?.shouldConfirmAction) {
            setShowConfirmationDialog(true);
        } else {
            handleBackButtonAction();
        }
    };

    const handleConfirmBackButtonAction = () => {
        trackEvent('Back Button Action Confirmed', { pageTitle, context: analyticsPrefix });
        handleBackButtonAction();
        setShowConfirmationDialog(false);
    };

    const handleCancelConfirmation = () => {
        trackEvent('Confirmation Cancelled', { pageTitle, context: analyticsPrefix });
        setShowConfirmationDialog(false);
    };

    const handleActionButtonClick = () => {
        const buttonLabel = actionButton ? actionButton.label : actionIcon.label;

        trackEvent('Action Button Clicked', {
            pageTitle,
            context: analyticsPrefix,
            buttonLabel,
            objectType: actionButton ? ObjectTypes.Button : ObjectTypes.Icon,
        });
        onAction?.();
    };

    const position = appBarStyles.position || 'relative';

    // Confirmation dialog defaults
    const {
        title: confirmationDialogTitle = 'Go Back?',
        content:
            confirmationDialogContent = 'There are some unsaved changes. Are you sure you want to go back?',
        confirmText: confirmationDialogConfirmText = 'Discard Changes',
        cancelText: confirmationDialogCancelText = 'Cancel',
    } = backButton?.confirmationDialogProps || {};

    return (
        <>
            <AppBar
                ref={mergeRefs(appBarRef, forwardedRef)}
                color="inherit"
                elevation={0}
                {...appBarStyles}
                position={position}
            >
                <Toolbar className="details-page-header__toolbar">
                    {shouldShowBackButton && (
                        <IconButton
                            aria-label="back"
                            onClick={handleBackButtonClick}
                            className="details-page-header__back-button"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </IconButton>
                    )}

                    {!hideTitle && (
                        <Typography variant="h1" className="details-page-header__page-title">
                            {pageTitle}
                        </Typography>
                    )}

                    {!!onAction &&
                        (actionButton ? (
                            <Button
                                // Defaults
                                variant="contained"
                                color="primary"
                                // Custom
                                {...actionButton.buttonProps}
                                onClick={handleActionButtonClick}
                                // Do not override
                                disableElevation
                                size="extraSmall"
                                className="details-page-header__action-button"
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {actionButton.label}
                                </Typography>
                            </Button>
                        ) : (
                            <IconButton
                                {...actionIconObject.iconButtonProps}
                                aria-label={actionIconObject.label}
                                onClick={handleActionButtonClick}
                                className="details-page-header__action-icon"
                            >
                                <FontAwesomeIcon
                                    {...actionIconObject.iconProps}
                                    icon={actionIconObject.icon}
                                />
                            </IconButton>
                        ))}
                </Toolbar>

                {children}
            </AppBar>
            {/* When fixed on the page, add a placeholder to prevent content from being hidden */}
            {position === 'fixed' && (
                <Box
                    sx={{
                        height: appBarHeight,
                    }}
                />
            )}
            <Dialog open={showConfirmationDialog} onClose={handleCancelConfirmation}>
                <DialogTitle>{confirmationDialogTitle}</DialogTitle>
                <DialogContent>
                    <Stack
                        sx={{
                            gap: 4,
                        }}
                    >
                        <Typography variant="body1">{confirmationDialogContent}</Typography>
                        <Stack
                            sx={{
                                gap: 2,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                disableElevation
                                onClick={handleConfirmBackButtonAction}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {confirmationDialogConfirmText}
                                </Typography>
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleCancelConfirmation}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {confirmationDialogCancelText}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default forwardRef<HTMLElement, DetailsPageHeaderProps>(DetailsPageHeader);
