import { sageBaseAdminApi as api } from './sageBaseAdminApi';

export const addTagTypes = [
    'residentActivities',
    'residents',
    'units',
    'checkIns',
    'staff',
    'enterprises',
    'facilities',
    'tasks',
    'groups',
    'devices',
    'media',
    'schedules',
    'companyPhones',
    'careZones',
    'staffPreferences',
    'doors',
    'doorAlarms',
    'features',
] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getCareEvent: build.query<GetCareEventApiResponse, GetCareEventApiArg>({
                query: (queryArg) => ({
                    url: `/careEvents/${queryArg.careEventId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityId: queryArg.facilityId },
                }),
                providesTags: ['residentActivities'],
            }),
            addCareEventNote: build.mutation<AddCareEventNoteApiResponse, AddCareEventNoteApiArg>({
                query: (queryArg) => ({
                    url: `/careEvents/${queryArg.careEventId}/notes`,
                    method: 'POST',
                    body: queryArg.createCareEventNoteRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityId: queryArg.facilityId },
                }),
                invalidatesTags: ['residentActivities'],
            }),
            createResident: build.mutation<CreateResidentApiResponse, CreateResidentApiArg>({
                query: (queryArg) => ({
                    url: `/residents`,
                    method: 'POST',
                    body: queryArg.createOrUpdateResidentRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            getResidents: build.query<GetResidentsApiResponse, GetResidentsApiArg>({
                query: (queryArg) => ({
                    url: `/residents`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        unitId: queryArg.unitId,
                        facilityId: queryArg.facilityId,
                        enterpriseId: queryArg.enterpriseId,
                        hasOpenEvent: queryArg.hasOpenEvent,
                        isArchived: queryArg.isArchived,
                    },
                }),
                providesTags: ['residents'],
            }),
            createResidents: build.mutation<CreateResidentsApiResponse, CreateResidentsApiArg>({
                query: (queryArg) => ({
                    url: `/residents/bulk`,
                    method: 'POST',
                    body: queryArg.bulkCreateResidentsRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['residents'],
            }),
            bulkSetRoutineCheckStatus: build.mutation<
                BulkSetRoutineCheckStatusApiResponse,
                BulkSetRoutineCheckStatusApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/bulk/routineChecks`,
                    method: 'PUT',
                    body: queryArg.bulkSetRoutineCheckRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            getUnits: build.query<GetUnitsApiResponse, GetUnitsApiArg>({
                query: (queryArg) => ({
                    url: `/units`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        residentId: queryArg.residentId,
                        facilityId: queryArg.facilityId,
                        enterpriseId: queryArg.enterpriseId,
                    },
                }),
                providesTags: ['units'],
            }),
            createUnit: build.mutation<CreateUnitApiResponse, CreateUnitApiArg>({
                query: (queryArg) => ({
                    url: `/units`,
                    method: 'POST',
                    body: queryArg.createOrUpdateUnitRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['units'],
            }),
            createUnits: build.mutation<CreateUnitsApiResponse, CreateUnitsApiArg>({
                query: (queryArg) => ({
                    url: `/units/bulk`,
                    method: 'POST',
                    body: queryArg.bulkCreateUnitRequestForFacility,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['units'],
            }),
            getCheckIns: build.query<GetCheckInsApiResponse, GetCheckInsApiArg>({
                query: (queryArg) => ({
                    url: `/checkIns`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        date: queryArg.date,
                        unitId: queryArg.unitId,
                        careZoneId: queryArg.careZoneId,
                        facilityId: queryArg.facilityId,
                        enterpriseId: queryArg.enterpriseId,
                        status: queryArg.status,
                        isOnHold: queryArg.isOnHold,
                    },
                }),
                providesTags: ['checkIns'],
            }),
            createCheckIn: build.mutation<CreateCheckInApiResponse, CreateCheckInApiArg>({
                query: (queryArg) => ({
                    url: `/checkIns`,
                    method: 'POST',
                    body: queryArg.createCheckInRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['checkIns'],
            }),
            updateCheckIn: build.mutation<UpdateCheckInApiResponse, UpdateCheckInApiArg>({
                query: (queryArg) => ({
                    url: `/checkIns/${queryArg.checkInId}`,
                    method: 'PUT',
                    body: queryArg.updateCheckInRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['checkIns'],
            }),
            getCheckIn: build.query<GetCheckInApiResponse, GetCheckInApiArg>({
                query: (queryArg) => ({
                    url: `/checkIns/${queryArg.checkInId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['checkIns'],
            }),
            createStaffMember: build.mutation<
                CreateStaffMemberApiResponse,
                CreateStaffMemberApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff`,
                    method: 'POST',
                    body: queryArg.createOrUpdateStaffMemberRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    timeout: 60000,
                }),
                invalidatesTags: ['staff'],
            }),
            getStaffMembers: build.query<GetStaffMembersApiResponse, GetStaffMembersApiArg>({
                query: (queryArg) => ({
                    url: `/staff`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        enterpriseId: queryArg.enterpriseId,
                        phoneNumber: queryArg.phoneNumber,
                        facilityId: queryArg.facilityId,
                        groupId: queryArg.groupId,
                        isArchived: queryArg.isArchived,
                    },
                }),
                providesTags: ['staff'],
            }),
            updateStaffMemberPermissions: build.mutation<
                UpdateStaffMemberPermissionsApiResponse,
                UpdateStaffMemberPermissionsApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/permissions`,
                    method: 'POST',
                    body: queryArg.updateStaffPermissionsRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staff'],
            }),
            createStaffMembers: build.mutation<
                CreateStaffMembersApiResponse,
                CreateStaffMembersApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/bulk`,
                    method: 'POST',
                    body: queryArg.bulkCreateStaffMembersRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
            }),
            getContactsOfResident: build.query<
                GetContactsOfResidentApiResponse,
                GetContactsOfResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/contacts`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['residents'],
            }),
            createContactForResident: build.mutation<
                CreateContactForResidentApiResponse,
                CreateContactForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/contacts`,
                    method: 'POST',
                    body: queryArg.createOrUpdateContactRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            getResidentActivities: build.query<
                GetResidentActivitiesApiResponse,
                GetResidentActivitiesApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/activities`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        careOutcomes: queryArg.careOutcomes,
                        pageNumber: queryArg.pageNumber,
                        pageSize: queryArg.pageSize,
                    },
                }),
                providesTags: ['residents', 'residentActivities'],
            }),
            getEnterprises: build.query<GetEnterprisesApiResponse, GetEnterprisesApiArg>({
                query: (queryArg) => ({
                    url: `/enterprises`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['enterprises'],
            }),
            createEnterprise: build.mutation<CreateEnterpriseApiResponse, CreateEnterpriseApiArg>({
                query: (queryArg) => ({
                    url: `/enterprises`,
                    method: 'POST',
                    body: queryArg.createEnterpriseRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['enterprises'],
            }),
            getFacilities: build.query<GetFacilitiesApiResponse, GetFacilitiesApiArg>({
                query: (queryArg) => ({
                    url: `/facilities`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        enterpriseId: queryArg.enterpriseId,
                        emergencyNotificationNumber: queryArg.emergencyNotificationNumber,
                    },
                }),
                providesTags: ['facilities'],
            }),
            createFacility: build.mutation<CreateFacilityApiResponse, CreateFacilityApiArg>({
                query: (queryArg) => ({
                    url: `/facilities`,
                    method: 'POST',
                    body: queryArg.createFacilityRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['facilities'],
            }),
            createTask: build.mutation<CreateTaskApiResponse, CreateTaskApiArg>({
                query: (queryArg) => ({
                    url: `/tasks`,
                    method: 'POST',
                    body: queryArg.createOrUpdateTaskRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            getGroups: build.query<GetGroupsApiResponse, GetGroupsApiArg>({
                query: (queryArg) => ({
                    url: `/groups`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        facilityId: queryArg.facilityId,
                        enterpriseId: queryArg.enterpriseId,
                        staffMemberId: queryArg.staffMemberId,
                    },
                }),
                providesTags: ['groups'],
            }),
            createGroup: build.mutation<CreateGroupApiResponse, CreateGroupApiArg>({
                query: (queryArg) => ({
                    url: `/groups`,
                    method: 'POST',
                    body: queryArg.createOrUpdateGroupRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['groups'],
            }),
            getEnterprise: build.query<GetEnterpriseApiResponse, GetEnterpriseApiArg>({
                query: (queryArg) => ({
                    url: `/enterprises/${queryArg.enterpriseId}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['enterprises'],
            }),
            updateEnterprise: build.mutation<UpdateEnterpriseApiResponse, UpdateEnterpriseApiArg>({
                query: (queryArg) => ({
                    url: `/enterprises/${queryArg.enterpriseId}`,
                    method: 'PUT',
                    body: queryArg.updateEnterpriseRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['enterprises'],
            }),
            deleteEnterprise: build.mutation<DeleteEnterpriseApiResponse, DeleteEnterpriseApiArg>({
                query: (queryArg) => ({
                    url: `/enterprises/${queryArg.enterpriseId}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['enterprises'],
            }),
            getFacility: build.query<GetFacilityApiResponse, GetFacilityApiArg>({
                query: (queryArg) => ({
                    url: `/facilities/${queryArg.facilityId}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['facilities'],
            }),
            updateFacility: build.mutation<UpdateFacilityApiResponse, UpdateFacilityApiArg>({
                query: (queryArg) => ({
                    url: `/facilities/${queryArg.facilityId}`,
                    method: 'PUT',
                    body: queryArg.updateFacilityRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['facilities'],
            }),
            deleteFacility: build.mutation<DeleteFacilityApiResponse, DeleteFacilityApiArg>({
                query: (queryArg) => ({
                    url: `/facilities/${queryArg.facilityId}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['facilities'],
            }),
            updateFacilityStatus: build.mutation<
                UpdateFacilityStatusApiResponse,
                UpdateFacilityStatusApiArg
            >({
                query: (queryArg) => ({
                    url: `/facilities/status/${queryArg.facilityRid}`,
                    method: 'PUT',
                    body: queryArg.updateFacilityStatusRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['facilities'],
            }),
            getGroup: build.query<GetGroupApiResponse, GetGroupApiArg>({
                query: (queryArg) => ({
                    url: `/groups/${queryArg.groupId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['groups'],
            }),
            updateGroup: build.mutation<UpdateGroupApiResponse, UpdateGroupApiArg>({
                query: (queryArg) => ({
                    url: `/groups/${queryArg.groupId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateGroupRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['groups'],
            }),
            deleteGroup: build.mutation<DeleteGroupApiResponse, DeleteGroupApiArg>({
                query: (queryArg) => ({
                    url: `/groups/${queryArg.groupId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['groups'],
            }),
            getResident: build.query<GetResidentApiResponse, GetResidentApiArg>({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['residents'],
            }),
            updateResident: build.mutation<UpdateResidentApiResponse, UpdateResidentApiArg>({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateResidentRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            deleteResident: build.mutation<DeleteResidentApiResponse, DeleteResidentApiArg>({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            getLatestCareActivity: build.query<
                GetLatestCareActivityApiResponse,
                GetLatestCareActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/careActivity/latest`,
                    method: 'POST',
                    body: queryArg.getLatestCareActivityRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['residents', 'tasks'],
            }),
            getContactOfResident: build.query<
                GetContactOfResidentApiResponse,
                GetContactOfResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/contacts/${queryArg.contactId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['residents'],
            }),
            updateContact: build.mutation<UpdateContactApiResponse, UpdateContactApiArg>({
                query: (queryArg) => ({
                    url: `/residents/contacts/${queryArg.contactId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateContactRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            deleteContact: build.mutation<DeleteContactApiResponse, DeleteContactApiArg>({
                query: (queryArg) => ({
                    url: `/residents/contacts/${queryArg.contactId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            getStaffMember: build.query<GetStaffMemberApiResponse, GetStaffMemberApiArg>({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['staff'],
            }),
            updateStaffMember: build.mutation<
                UpdateStaffMemberApiResponse,
                UpdateStaffMemberApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateStaffMemberRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    timeout: 60000,
                }),
                invalidatesTags: ['staff'],
            }),
            deleteStaffMember: build.mutation<
                DeleteStaffMemberApiResponse,
                DeleteStaffMemberApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staff'],
            }),
            getTask: build.query<GetTaskApiResponse, GetTaskApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/${queryArg.taskId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['tasks'],
            }),
            updateTask: build.mutation<UpdateTaskApiResponse, UpdateTaskApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/${queryArg.taskId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateTaskRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            deleteTask: build.mutation<DeleteTaskApiResponse, DeleteTaskApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/${queryArg.taskId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            resolveTask: build.mutation<ResolveTaskApiResponse, ResolveTaskApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/${queryArg.taskId}/resolve`,
                    method: 'PUT',
                    body: queryArg.resolveTaskRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            getCareConfirmationTracker: build.query<
                GetCareConfirmationTrackerApiResponse,
                GetCareConfirmationTrackerApiArg
            >({
                query: (queryArg) => ({
                    url: `/tasks/${queryArg.taskId}/care-confirmation-tracker`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['tasks'],
            }),
            resolveAllAlerts: build.mutation<ResolveAllAlertsApiResponse, ResolveAllAlertsApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/resolve/${queryArg.facilityRid}`,
                    method: 'POST',
                    body: queryArg.resolveAlertsRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['tasks'],
            }),
            getUnit: build.query<GetUnitApiResponse, GetUnitApiArg>({
                query: (queryArg) => ({
                    url: `/units/${queryArg.unitId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['units'],
            }),
            updateUnit: build.mutation<UpdateUnitApiResponse, UpdateUnitApiArg>({
                query: (queryArg) => ({
                    url: `/units/${queryArg.unitId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateUnitRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['units'],
            }),
            deleteUnit: build.mutation<DeleteUnitApiResponse, DeleteUnitApiArg>({
                query: (queryArg) => ({
                    url: `/units/${queryArg.unitId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['units'],
            }),
            createUpdateForTask: build.mutation<
                CreateUpdateForTaskApiResponse,
                CreateUpdateForTaskApiArg
            >({
                query: (queryArg) => ({
                    url: `/tasks/updates`,
                    method: 'POST',
                    body: queryArg.createTaskUpdateRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            deleteUpdate: build.mutation<DeleteUpdateApiResponse, DeleteUpdateApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/updates/${queryArg.updateId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            getAllocatedDevices: build.query<
                GetAllocatedDevicesApiResponse,
                GetAllocatedDevicesApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/allocated`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        unitRid: queryArg.unitRid,
                        doorRid: queryArg.doorRid,
                        facilityRid: queryArg.facilityRid,
                        enterpriseRid: queryArg.enterpriseRid,
                        type: queryArg['type'],
                        residentRid: queryArg.residentRid,
                        deviceHardwareId: queryArg.deviceHardwareId,
                        isCheckIn: queryArg.isCheckIn,
                        isSilent: queryArg.isSilent,
                    },
                }),
                providesTags: ['devices'],
            }),
            allocateDevice: build.mutation<AllocateDeviceApiResponse, AllocateDeviceApiArg>({
                query: (queryArg) => ({
                    url: `/devices/allocated`,
                    method: 'POST',
                    body: queryArg.createOrUpdateDeviceAllocationRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            searchLatestAllocatedDeviceActivity: build.mutation<
                SearchLatestAllocatedDeviceActivityApiResponse,
                SearchLatestAllocatedDeviceActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/activity/allocated/latest`,
                    method: 'POST',
                    body: queryArg.searchLatestAllocatedDeviceActivityRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            getDeviceAllocation: build.query<
                GetDeviceAllocationApiResponse,
                GetDeviceAllocationApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/allocated/${queryArg.deviceAllocationId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['devices'],
            }),
            updateDeviceAllocation: build.mutation<
                UpdateDeviceAllocationApiResponse,
                UpdateDeviceAllocationApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/allocated/${queryArg.deviceAllocationId}`,
                    method: 'PUT',
                    body: queryArg.schema,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            deallocateDevice: build.mutation<DeallocateDeviceApiResponse, DeallocateDeviceApiArg>({
                query: (queryArg) => ({
                    url: `/devices/allocated/${queryArg.deviceAllocationId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            getDevices: build.query<GetDevicesApiResponse, GetDevicesApiArg>({
                query: (queryArg) => ({
                    url: `/devices`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        createdBefore: queryArg.createdBefore,
                        createdAfter: queryArg.createdAfter,
                        type: queryArg['type'],
                        hardwareId: queryArg.hardwareId,
                        isAllocated: queryArg.isAllocated,
                    },
                }),
                providesTags: ['devices'],
            }),
            createDevice: build.mutation<CreateDeviceApiResponse, CreateDeviceApiArg>({
                query: (queryArg) => ({
                    url: `/devices`,
                    method: 'POST',
                    body: queryArg.createDeviceRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            getDevice: build.query<GetDeviceApiResponse, GetDeviceApiArg>({
                query: (queryArg) => ({
                    url: `/devices/${queryArg.deviceId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['devices'],
            }),
            deleteDevice: build.mutation<DeleteDeviceApiResponse, DeleteDeviceApiArg>({
                query: (queryArg) => ({
                    url: `/devices/${queryArg.deviceId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            getOnCallState: build.query<GetOnCallStateApiResponse, GetOnCallStateApiArg>({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}/on-call/${queryArg.facilityId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['staff'],
            }),
            updateOnCallState: build.mutation<
                UpdateOnCallStateApiResponse,
                UpdateOnCallStateApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}/on-call/${queryArg.facilityId}`,
                    method: 'POST',
                    body: queryArg.updateOnCallStateRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staff'],
            }),
            getMembershipDetails: build.query<
                GetMembershipDetailsApiResponse,
                GetMembershipDetailsApiArg
            >({
                query: (queryArg) => ({
                    url: `/groups/${queryArg.groupId}/membership`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['groups'],
            }),
            updateGroupMembership: build.mutation<
                UpdateGroupMembershipApiResponse,
                UpdateGroupMembershipApiArg
            >({
                query: (queryArg) => ({
                    url: `/groups/${queryArg.groupId}/membership`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateGroupMembershipRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    timeout: 60000,
                }),
                invalidatesTags: ['groups'],
            }),
            removeMemberFromGroup: build.mutation<
                RemoveMemberFromGroupApiResponse,
                RemoveMemberFromGroupApiArg
            >({
                query: (queryArg) => ({
                    url: `/groups/${queryArg.groupId}/membership`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { staffMemberId: queryArg.staffMemberId },
                }),
                invalidatesTags: ['groups'],
            }),
            getApplicationConfiguration: build.query<
                GetApplicationConfigurationApiResponse,
                GetApplicationConfigurationApiArg
            >({
                query: (queryArg) => ({
                    url: `/configuration`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
            }),
            resolveNumbers: build.query<ResolveNumbersApiResponse, ResolveNumbersApiArg>({
                query: (queryArg) => ({
                    url: `/notificationCallback/resolve-numbers`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        senderNumber: queryArg.senderNumber,
                        recipientNumber: queryArg.recipientNumber,
                    },
                }),
            }),
            createNoteForTask: build.mutation<
                CreateNoteForTaskApiResponse,
                CreateNoteForTaskApiArg
            >({
                query: (queryArg) => ({
                    url: `/tasks/${queryArg.taskId}/notes`,
                    method: 'POST',
                    body: queryArg.createOrUpdateTaskNoteRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            deleteTaskNote: build.mutation<DeleteTaskNoteApiResponse, DeleteTaskNoteApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/notes/${queryArg.noteId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            updateTaskNote: build.mutation<UpdateTaskNoteApiResponse, UpdateTaskNoteApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/notes/${queryArg.noteId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateTaskNoteRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            unassignTask: build.mutation<UnassignTaskApiResponse, UnassignTaskApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/${queryArg.taskId}/unassign`,
                    method: 'POST',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            updateResidentArchiveStatus: build.mutation<
                UpdateResidentArchiveStatusApiResponse,
                UpdateResidentArchiveStatusApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/archive`,
                    method: 'POST',
                    body: queryArg.updateResidentArchiveStatusRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            updateStaffMemberArchiveStatus: build.mutation<
                UpdateStaffMemberArchiveStatusApiResponse,
                UpdateStaffMemberArchiveStatusApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}/archive`,
                    method: 'POST',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { archive: queryArg.archive },
                }),
                invalidatesTags: ['staff'],
            }),
            getStaffMemberFacilityMetadata: build.query<
                GetStaffMemberFacilityMetadataApiResponse,
                GetStaffMemberFacilityMetadataApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}/facilities`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityId: queryArg.facilityId },
                }),
                providesTags: ['staff'],
            }),
            deleteStaffMemberFromFacilities: build.mutation<
                DeleteStaffMemberFromFacilitiesApiResponse,
                DeleteStaffMemberFromFacilitiesApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/${queryArg.staffMemberId}/facilities`,
                    method: 'DELETE',
                    body: queryArg.ridArray,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    timeout: 60000,
                }),
                invalidatesTags: ['staff'],
            }),
            getImageForResource: build.query<
                GetImageForResourceApiResponse,
                GetImageForResourceApiArg
            >({
                query: (queryArg) => ({
                    url: `/media/images/${queryArg.resourceId}`,
                    responseHandler: 'text',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['media'],
            }),
            uploadImageForResource: build.mutation<
                UploadImageForResourceApiResponse,
                UploadImageForResourceApiArg
            >({
                query: (queryArg) => ({
                    url: `/media/images/${queryArg.resourceId}`,
                    method: 'PUT',
                    body: queryArg.body,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['media'],
            }),
            deleteImageForResource: build.mutation<
                DeleteImageForResourceApiResponse,
                DeleteImageForResourceApiArg
            >({
                query: (queryArg) => ({
                    url: `/media/images/${queryArg.resourceId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['media'],
            }),
            setPcpForResident: build.mutation<
                SetPcpForResidentApiResponse,
                SetPcpForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/pcp`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateProfessionalContactRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            deletePcpForResident: build.mutation<
                DeletePcpForResidentApiResponse,
                DeletePcpForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/pcp`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            getPcpForResident: build.query<GetPcpForResidentApiResponse, GetPcpForResidentApiArg>({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/pcp`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['residents'],
            }),
            getSpecialistsForResident: build.query<
                GetSpecialistsForResidentApiResponse,
                GetSpecialistsForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/specialists`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['residents'],
            }),
            addSpecialistForResident: build.mutation<
                AddSpecialistForResidentApiResponse,
                AddSpecialistForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/specialists`,
                    method: 'POST',
                    body: queryArg.createOrUpdateProfessionalContactRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            updateSpecialistForResident: build.mutation<
                UpdateSpecialistForResidentApiResponse,
                UpdateSpecialistForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/specialists/${queryArg.specialistId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateProfessionalContactRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            deleteSpecialistForResident: build.mutation<
                DeleteSpecialistForResidentApiResponse,
                DeleteSpecialistForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/specialists/${queryArg.specialistId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            unallocateDevice: build.mutation<UnallocateDeviceApiResponse, UnallocateDeviceApiArg>({
                query: (queryArg) => ({
                    url: `/devices/allocated/${queryArg.deviceAllocationId}/unallocate`,
                    method: 'POST',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['devices'],
            }),
            bulkUpdateDeviceAllocations: build.mutation<
                BulkUpdateDeviceAllocationsApiResponse,
                BulkUpdateDeviceAllocationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/allocated/bulk`,
                    method: 'POST',
                    body: queryArg.bulkUpdateDeviceAllocations,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            taskSearch: build.query<TaskSearchApiResponse, TaskSearchApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/search`,
                    method: 'POST',
                    body: queryArg.taskSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['tasks'],
            }),
            setResidentHealthInfo: build.mutation<
                SetResidentHealthInfoApiResponse,
                SetResidentHealthInfoApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/health`,
                    method: 'PUT',
                    body: queryArg.healthInfo,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            deleteResidentHealthInfo: build.mutation<
                DeleteResidentHealthInfoApiResponse,
                DeleteResidentHealthInfoApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/health`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            createTaskSchedule: build.mutation<
                CreateTaskScheduleApiResponse,
                CreateTaskScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/schedules/tasks`,
                    method: 'POST',
                    body: queryArg.createOrUpdateTaskScheduleRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['schedules'],
            }),
            getTaskSchedule: build.query<GetTaskScheduleApiResponse, GetTaskScheduleApiArg>({
                query: (queryArg) => ({
                    url: `/schedules/tasks/${queryArg.scheduleId}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['schedules'],
            }),
            updateTaskSchedule: build.mutation<
                UpdateTaskScheduleApiResponse,
                UpdateTaskScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/schedules/tasks/${queryArg.scheduleId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateTaskScheduleRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['schedules'],
            }),
            deleteTaskSchedule: build.mutation<
                DeleteTaskScheduleApiResponse,
                DeleteTaskScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/schedules/tasks/${queryArg.scheduleId}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['schedules'],
            }),
            taskScheduleSearch: build.query<
                TaskScheduleSearchApiResponse,
                TaskScheduleSearchApiArg
            >({
                query: (queryArg) => ({
                    url: `/schedules/tasks/search`,
                    method: 'POST',
                    body: queryArg.taskScheduleSearchRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['schedules'],
            }),
            getMyTasks: build.mutation<GetMyTasksApiResponse, GetMyTasksApiArg>({
                query: (queryArg) => ({
                    url: `/tasks/mine`,
                    method: 'POST',
                    body: queryArg.userTaskSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['tasks'],
            }),
            unassignTaskSchedule: build.mutation<
                UnassignTaskScheduleApiResponse,
                UnassignTaskScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/schedules/tasks/${queryArg.scheduleId}/unassign`,
                    method: 'POST',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['schedules'],
            }),
            addDeviceActivity: build.mutation<
                AddDeviceActivityApiResponse,
                AddDeviceActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/activity`,
                    method: 'POST',
                    body: queryArg.addDeviceActivityRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['devices'],
            }),
            getLatestDeviceActivity: build.query<
                GetLatestDeviceActivityApiResponse,
                GetLatestDeviceActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/activity/latest`,
                    method: 'POST',
                    body: queryArg.getLatestDeviceActivityRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['devices'],
            }),
            createResidentActivity: build.mutation<
                CreateResidentActivityApiResponse,
                CreateResidentActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/tasks/resident-activity`,
                    method: 'POST',
                    body: queryArg.createResidentActivityRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residentActivities', 'tasks'],
            }),
            unsetTaskScheduleDuration: build.mutation<
                UnsetTaskScheduleDurationApiResponse,
                UnsetTaskScheduleDurationApiArg
            >({
                query: (queryArg) => ({
                    url: `/schedules/tasks/${queryArg.scheduleId}/unset-duration`,
                    method: 'POST',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['schedules'],
            }),
            getCompanyPhones: build.query<GetCompanyPhonesApiResponse, GetCompanyPhonesApiArg>({
                query: (queryArg) => ({
                    url: `/companyPhones`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        enterpriseId: queryArg.enterpriseId,
                        facilityId: queryArg.facilityId,
                        availablePhonesOnly: queryArg.availablePhonesOnly,
                    },
                }),
                providesTags: ['companyPhones'],
            }),
            createCompanyPhone: build.mutation<
                CreateCompanyPhoneApiResponse,
                CreateCompanyPhoneApiArg
            >({
                query: (queryArg) => ({
                    url: `/companyPhones`,
                    method: 'POST',
                    body: queryArg.createOrUpdateCompanyPhoneRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['companyPhones'],
            }),
            getCompanyPhone: build.query<GetCompanyPhoneApiResponse, GetCompanyPhoneApiArg>({
                query: (queryArg) => ({
                    url: `/companyPhones/${queryArg.companyPhoneId}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['companyPhones'],
            }),
            updateCompanyPhone: build.mutation<
                UpdateCompanyPhoneApiResponse,
                UpdateCompanyPhoneApiArg
            >({
                query: (queryArg) => ({
                    url: `/companyPhones/${queryArg.companyPhoneId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateCompanyPhoneRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['companyPhones'],
            }),
            deleteCompanyPhone: build.mutation<
                DeleteCompanyPhoneApiResponse,
                DeleteCompanyPhoneApiArg
            >({
                query: (queryArg) => ({
                    url: `/companyPhones/${queryArg.companyPhoneId}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['companyPhones'],
            }),
            createCompanyPhones: build.mutation<
                CreateCompanyPhonesApiResponse,
                CreateCompanyPhonesApiArg
            >({
                query: (queryArg) => ({
                    url: `/companyPhones/bulk`,
                    method: 'POST',
                    body: queryArg.bulkCreateCompanyPhonesRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['companyPhones'],
            }),
            getHospitalForResident: build.query<
                GetHospitalForResidentApiResponse,
                GetHospitalForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/hospital`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['residents'],
            }),
            setHospitalForResident: build.mutation<
                SetHospitalForResidentApiResponse,
                SetHospitalForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/hospital`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateHospitalRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            deleteHospitalForResident: build.mutation<
                DeleteHospitalForResidentApiResponse,
                DeleteHospitalForResidentApiArg
            >({
                query: (queryArg) => ({
                    url: `/residents/${queryArg.residentId}/hospital`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            getCareZone: build.query<GetCareZoneApiResponse, GetCareZoneApiArg>({
                query: (queryArg) => ({
                    url: `/careZones/${queryArg.careZoneId}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['careZones'],
            }),
            updateCareZone: build.mutation<UpdateCareZoneApiResponse, UpdateCareZoneApiArg>({
                query: (queryArg) => ({
                    url: `/careZones/${queryArg.careZoneId}`,
                    method: 'POST',
                    body: queryArg.createOrUpdateCareZoneRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['careZones'],
            }),
            deleteCareZone: build.mutation<DeleteCareZoneApiResponse, DeleteCareZoneApiArg>({
                query: (queryArg) => ({
                    url: `/careZones/${queryArg.careZoneId}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['careZones'],
            }),
            getAllCareZones: build.query<GetAllCareZonesApiResponse, GetAllCareZonesApiArg>({
                query: (queryArg) => ({
                    url: `/careZones`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        enterpriseRid: queryArg.enterpriseRid,
                        facilityRid: queryArg.facilityRid,
                    },
                }),
                providesTags: ['careZones'],
            }),
            createCareZone: build.mutation<CreateCareZoneApiResponse, CreateCareZoneApiArg>({
                query: (queryArg) => ({
                    url: `/careZones`,
                    method: 'POST',
                    body: queryArg.createOrUpdateCareZoneRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['careZones'],
            }),
            getOnCallStaffMembers: build.query<
                GetOnCallStaffMembersApiResponse,
                GetOnCallStaffMembersApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/oncall`,
                    method: 'POST',
                    body: queryArg.staffOnCallRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['staff'],
            }),
            unitsSearch: build.query<UnitsSearchApiResponse, UnitsSearchApiArg>({
                query: (queryArg) => ({
                    url: `/units/search`,
                    method: 'POST',
                    body: queryArg.unitSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['units'],
            }),
            checkInSearch: build.query<CheckInSearchApiResponse, CheckInSearchApiArg>({
                query: (queryArg) => ({
                    url: `/checkIns/search`,
                    method: 'POST',
                    body: queryArg.checkInSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['checkIns'],
            }),
            createStaffFacilityPreferences: build.mutation<
                CreateStaffFacilityPreferencesApiResponse,
                CreateStaffFacilityPreferencesApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffFacilityPreferences`,
                    method: 'POST',
                    body: queryArg.createStaffFacilityPreferencesRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staffPreferences'],
            }),
            getStaffFacilityPreferences: build.query<
                GetStaffFacilityPreferencesApiResponse,
                GetStaffFacilityPreferencesApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffFacilityPreferences/staff/${queryArg.staffMemberId}/facility/${queryArg.facilityId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['staffPreferences'],
            }),
            updateStaffFacilityPreferences: build.mutation<
                UpdateStaffFacilityPreferencesApiResponse,
                UpdateStaffFacilityPreferencesApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffFacilityPreferences/staff/${queryArg.staffMemberId}/facility/${queryArg.facilityId}`,
                    method: 'PUT',
                    body: queryArg.updateStaffFacilityPreferencesRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staffPreferences'],
            }),
            deleteStaffFacilityPreferences: build.mutation<
                DeleteStaffFacilityPreferencesApiResponse,
                DeleteStaffFacilityPreferencesApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffFacilityPreferences/staff/${queryArg.staffMemberId}/facility/${queryArg.facilityId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staffPreferences'],
            }),
            searchStaffFacilityPreferences: build.mutation<
                SearchStaffFacilityPreferencesApiResponse,
                SearchStaffFacilityPreferencesApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffFacilityPreferences/search`,
                    method: 'POST',
                    body: queryArg.staffFacilityPreferenceSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staffPreferences'],
            }),
            createStaffMemberFacilityConfig: build.mutation<
                CreateStaffMemberFacilityConfigApiResponse,
                CreateStaffMemberFacilityConfigApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffMemberFacilityConfig`,
                    method: 'POST',
                    body: queryArg.createOrUpdateStaffMemberFacilityConfigRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staff'],
            }),
            getStaffMemberFacilityConfig: build.query<
                GetStaffMemberFacilityConfigApiResponse,
                GetStaffMemberFacilityConfigApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffMemberFacilityConfig/${queryArg.staffMemberFacilityConfigId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['staff'],
            }),
            updateStaffMemberFacilityConfig: build.mutation<
                UpdateStaffMemberFacilityConfigApiResponse,
                UpdateStaffMemberFacilityConfigApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffMemberFacilityConfig/${queryArg.staffMemberFacilityConfigId}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateStaffMemberFacilityConfigRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staff'],
            }),
            deleteStaffMemberFacilityConfig: build.mutation<
                DeleteStaffMemberFacilityConfigApiResponse,
                DeleteStaffMemberFacilityConfigApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffMemberFacilityConfig/${queryArg.staffMemberFacilityConfigId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staff'],
            }),
            searchStaffMemberFacilityConfigs: build.mutation<
                SearchStaffMemberFacilityConfigsApiResponse,
                SearchStaffMemberFacilityConfigsApiArg
            >({
                query: (queryArg) => ({
                    url: `/staffMemberFacilityConfig/search`,
                    method: 'POST',
                    body: queryArg.staffMemberFacilityConfigSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['staff'],
            }),
            staffProfileSearch: build.query<
                StaffProfileSearchApiResponse,
                StaffProfileSearchApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/profile/search`,
                    method: 'POST',
                    body: queryArg.staffProfileSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['staff'],
            }),
            getUserStaffMemberProfiles: build.query<
                GetUserStaffMemberProfilesApiResponse,
                GetUserStaffMemberProfilesApiArg
            >({
                query: (queryArg) => ({
                    url: `/staff/profiles`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['staff'],
            }),
            createDoor: build.mutation<CreateDoorApiResponse, CreateDoorApiArg>({
                query: (queryArg) => ({
                    url: `/doors`,
                    method: 'POST',
                    body: queryArg.items,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['doors'],
            }),
            createDoors: build.mutation<CreateDoorsApiResponse, CreateDoorsApiArg>({
                query: (queryArg) => ({
                    url: `/doors/bulk`,
                    method: 'POST',
                    body: queryArg.bulkCreateDoorsRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['doors'],
            }),
            getDoor: build.query<GetDoorApiResponse, GetDoorApiArg>({
                query: (queryArg) => ({
                    url: `/doors/${queryArg.doorRid}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['doors'],
            }),
            updateDoor: build.mutation<UpdateDoorApiResponse, UpdateDoorApiArg>({
                query: (queryArg) => ({
                    url: `/doors/${queryArg.doorRid}`,
                    method: 'POST',
                    body: queryArg.items,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['doors'],
            }),
            deleteDoor: build.mutation<DeleteDoorApiResponse, DeleteDoorApiArg>({
                query: (queryArg) => ({
                    url: `/doors/${queryArg.doorRid}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['doors'],
            }),
            createOrUpdateDoorAlarmSchedule: build.mutation<
                CreateOrUpdateDoorAlarmScheduleApiResponse,
                CreateOrUpdateDoorAlarmScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/doors/doorAlarmSchedules`,
                    method: 'POST',
                    body: queryArg.createOrUpdateDoorAlarmScheduleRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['doorAlarms'],
            }),
            getDoorAlarmSchedule: build.query<
                GetDoorAlarmScheduleApiResponse,
                GetDoorAlarmScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/doors/doorAlarmSchedules/${queryArg.doorAlarmScheduleRid}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['doorAlarms'],
            }),
            deleteDoorAlarmSchedule: build.mutation<
                DeleteDoorAlarmScheduleApiResponse,
                DeleteDoorAlarmScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/doors/doorAlarmSchedules/${queryArg.doorAlarmScheduleRid}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['doorAlarms'],
            }),
            createOrUpdateFacilityDefaultDoorAlarmSchedule: build.mutation<
                CreateOrUpdateFacilityDefaultDoorAlarmScheduleApiResponse,
                CreateOrUpdateFacilityDefaultDoorAlarmScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/doors/doorAlarmSchedules/defaults`,
                    method: 'POST',
                    body: queryArg.createOrUpdateDoorAlarmScheduleRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['doorAlarms'],
            }),
            getFacilityDefaultDoorAlarmSchedule: build.query<
                GetFacilityDefaultDoorAlarmScheduleApiResponse,
                GetFacilityDefaultDoorAlarmScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/doors/doorAlarmSchedules/defaults`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityRid: queryArg.facilityRid },
                }),
                providesTags: ['doorAlarms'],
            }),
            deleteFacilityDefaultDoorAlarmSchedule: build.mutation<
                DeleteFacilityDefaultDoorAlarmScheduleApiResponse,
                DeleteFacilityDefaultDoorAlarmScheduleApiArg
            >({
                query: (queryArg) => ({
                    url: `/doors/doorAlarmSchedules/defaults`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityRid: queryArg.facilityRid },
                }),
                invalidatesTags: ['doorAlarms'],
            }),
            searchDoors: build.query<SearchDoorsApiResponse, SearchDoorsApiArg>({
                query: (queryArg) => ({
                    url: `/doors/search`,
                    method: 'POST',
                    body: queryArg.doorSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['doors'],
            }),
            searchDoorAlarmSchedules: build.query<
                SearchDoorAlarmSchedulesApiResponse,
                SearchDoorAlarmSchedulesApiArg
            >({
                query: (queryArg) => ({
                    url: `/doors/doorAlarmsSchedules/search`,
                    method: 'POST',
                    body: queryArg.doorAlarmScheduleSearchRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['doorAlarms'],
            }),
            getAllFeatures: build.query<GetAllFeaturesApiResponse, GetAllFeaturesApiArg>({
                query: (queryArg) => ({
                    url: `/features`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityId: queryArg.facilityId },
                }),
                providesTags: ['features'],
            }),
            createFeature: build.mutation<CreateFeatureApiResponse, CreateFeatureApiArg>({
                query: (queryArg) => ({
                    url: `/features`,
                    method: 'POST',
                    body: queryArg.createFeatureRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['features'],
            }),
            getFeature: build.query<GetFeatureApiResponse, GetFeatureApiArg>({
                query: (queryArg) => ({
                    url: `/features/${queryArg.featureId}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                providesTags: ['features'],
            }),
            updateFeature: build.mutation<UpdateFeatureApiResponse, UpdateFeatureApiArg>({
                query: (queryArg) => ({
                    url: `/features/${queryArg.featureId}`,
                    method: 'PUT',
                    body: queryArg.updateFeatureRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['features'],
            }),
            deleteFeature: build.mutation<DeleteFeatureApiResponse, DeleteFeatureApiArg>({
                query: (queryArg) => ({
                    url: `/features/${queryArg.featureId}`,
                    method: 'DELETE',
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['features'],
            }),
            getNotificationContext: build.mutation<
                GetNotificationContextApiResponse,
                GetNotificationContextApiArg
            >({
                query: (queryArg) => ({
                    url: `/notificationContexts`,
                    method: 'POST',
                    body: queryArg.getNotificationContextRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
            }),
            simulateDeviceEvent: build.mutation<
                SimulateDeviceEventApiResponse,
                SimulateDeviceEventApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/${queryArg.deviceType}/simulate`,
                    method: 'POST',
                    body: queryArg.simulateDeviceEventRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageAdminApi };
export type GetCareEventApiResponse = /** status 200 OK */ CareEventResponse;
export type GetCareEventApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    careEventId: string;
    /** The facility RID. Required for the time being for authorization checks since care event
    is not a resource on the resource graph. Will check for the [resident:read-phi] scope.
     */
    facilityId: string;
};
export type AddCareEventNoteApiResponse = /** status 200 OK */ RidResponse;
export type AddCareEventNoteApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    careEventId: string;
    /** The facility RID. Required for the time being for authorization checks since care event
    is not a resource on the resource graph. Will check for the [resident:read-phi] scope.
     */
    facilityId: string;
    createCareEventNoteRequest: {
        /** This enum is add only. If you delete or update the values below, the api will break. */
        noteType: 'CREATION_NOTE' | 'CAREGIVER_NOTE' | 'NURSE_ACKNOWLEDGEMENT';
        content: string;
        creatorRid: string;
    };
};
export type CreateResidentApiResponse = /** status 200 OK */ RidResponse;
export type CreateResidentApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateResidentRequest: CreateOrUpdateResidentRequest;
};
export type GetResidentsApiResponse = /** status 200 OK */ Resident[];
export type GetResidentsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** unit id of the resident */
    unitId?: string;
    /** facility id of the resident */
    facilityId?: string;
    /** enterprise id of the resident */
    enterpriseId?: string;
    /** whether or not a resident has an open event associated with them */
    hasOpenEvent?: boolean;
    /** whether or not a resident is archived */
    isArchived?: boolean;
};
export type CreateResidentsApiResponse = /** status 200 OK */ RidArray;
export type CreateResidentsApiArg = {
    authorization?: string;
    bulkCreateResidentsRequest: BulkCreateResidentsRequest;
};
export type BulkSetRoutineCheckStatusApiResponse = unknown;
export type BulkSetRoutineCheckStatusApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** List of resident rids to update */
    bulkSetRoutineCheckRequest: ToggleRoutineCheckRequest;
};
export type GetUnitsApiResponse = /** status 200 OK */ Unit[];
export type GetUnitsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** id of the resident occupying a unit */
    residentId?: string;
    /** facility id */
    facilityId?: string;
    /** enterpriseId */
    enterpriseId?: string;
};
export type CreateUnitApiResponse = /** status 200 OK */ {
    rid?: string;
};
export type CreateUnitApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateUnitRequest: CreateOrUpdateUnitRequest;
};
export type CreateUnitsApiResponse = /** status 200 OK */ RidArray;
export type CreateUnitsApiArg = {
    authorization?: string;
    bulkCreateUnitRequestForFacility: BulkCreateUnitRequestForFacility;
};
export type GetCheckInsApiResponse = /** status 200 OK */ CheckIn[];
export type GetCheckInsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** date of check in */
    date?: string;
    /** unit id */
    unitId?: string;
    /** care zone id */
    careZoneId?: string;
    /** facility id */
    facilityId?: string;
    /** enterprise id */
    enterpriseId?: string;
    /** check in status */
    status?: string;
    /** whether or not all occupants of the unit are on hold */
    isOnHold?: boolean;
};
export type CreateCheckInApiResponse = /** status 200 OK */ RidResponse;
export type CreateCheckInApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createCheckInRequest: CreateCheckInRequest;
};
export type UpdateCheckInApiResponse = /** status 200 OK */ RidResponse;
export type UpdateCheckInApiArg = {
    checkInId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    updateCheckInRequest: UpdateCheckInRequest;
};
export type GetCheckInApiResponse = /** status 200 OK */ CheckIn;
export type GetCheckInApiArg = {
    checkInId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type CreateStaffMemberApiResponse = /** status 200 OK */ RidResponse;
export type CreateStaffMemberApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateStaffMemberRequest: CreateOrUpdateStaffMemberRequest;
};
export type GetStaffMembersApiResponse = /** status 200 OK */ StaffMember[];
export type GetStaffMembersApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** id of the enterprise the staff member is associated with */
    enterpriseId?: string;
    /** phone number of the staff member */
    phoneNumber?: string;
    /** facility the staff member belongs to */
    facilityId?: string;
    /** a group the staff member belongs to */
    groupId?: string;
    /** whether or not a staff member is archived */
    isArchived?: boolean;
};
export type UpdateStaffMemberPermissionsApiResponse =
    /** status 200 OK */ UpdateStaffPermissionsResponse;
export type UpdateStaffMemberPermissionsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    updateStaffPermissionsRequest: UpdateStaffPermissionsRequest;
};
export type CreateStaffMembersApiResponse = /** status 200 OK */ CreateStaffMembersResponse;
export type CreateStaffMembersApiArg = {
    authorization?: string;
    bulkCreateStaffMembersRequest: BulkCreateStaffMembersRequest;
};
export type GetContactsOfResidentApiResponse = /** status 200 OK */ Contact[];
export type GetContactsOfResidentApiArg = {
    /** id of the resident */
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type CreateContactForResidentApiResponse = /** status 200 OK */ RidResponse;
export type CreateContactForResidentApiArg = {
    /** id of the resident */
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateContactRequest: CreateOrUpdateContactRequest;
};
export type GetResidentActivitiesApiResponse = /** status 200 OK */ GetResidentActivitiesResponse;
export type GetResidentActivitiesApiArg = {
    /** id of the resident */
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    careOutcomes?: ('COMPLETED' | 'NOT_COMPLETED' | 'REFUSED')[];
    pageNumber?: number;
    pageSize?: number;
};
export type GetEnterprisesApiResponse = /** status 200 OK */ Enterprise[];
export type GetEnterprisesApiArg = {
    authorization?: string;
};
export type CreateEnterpriseApiResponse = /** status 200 OK */ RidResponse;
export type CreateEnterpriseApiArg = {
    authorization?: string;
    createEnterpriseRequest: CreateEnterpriseRequest;
};
export type GetFacilitiesApiResponse = /** status 200 OK */ Facility[];
export type GetFacilitiesApiArg = {
    /** id of enterprise */
    enterpriseId?: string;
    authorization?: string;
    /** phone number for the emergency notification sender */
    emergencyNotificationNumber?: string;
};
export type CreateFacilityApiResponse = /** status 200 OK */ RidResponse;
export type CreateFacilityApiArg = {
    authorization?: string;
    createFacilityRequest: CreateFacilityRequest;
};
export type CreateTaskApiResponse = /** status 200 OK */ RidResponse;
export type CreateTaskApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateTaskRequest: CreateOrUpdateTaskRequest;
};
export type GetGroupsApiResponse = /** status 200 OK */ Group[];
export type GetGroupsApiArg = {
    /** facility id */
    facilityId?: string;
    /** enterprise id */
    enterpriseId?: string;
    authorization?: string;
    /** staff member id */
    staffMemberId?: string;
};
export type CreateGroupApiResponse = /** status 200 OK */ RidResponse;
export type CreateGroupApiArg = {
    authorization?: string;
    createOrUpdateGroupRequest: CreateOrUpdateGroupRequest;
};
export type GetEnterpriseApiResponse = /** status 200 OK */ Enterprise;
export type GetEnterpriseApiArg = {
    enterpriseId: string;
    authorization?: string;
};
export type UpdateEnterpriseApiResponse = /** status 200 OK */ RidResponse;
export type UpdateEnterpriseApiArg = {
    enterpriseId: string;
    authorization?: string;
    updateEnterpriseRequest: UpdateEnterpriseRequest;
};
export type DeleteEnterpriseApiResponse = /** status 200 OK */ Enterprise;
export type DeleteEnterpriseApiArg = {
    enterpriseId: string;
    authorization?: string;
};
export type GetFacilityApiResponse = /** status 200 OK */ Facility;
export type GetFacilityApiArg = {
    facilityId: string;
    authorization?: string;
};
export type UpdateFacilityApiResponse = /** status 200 OK */ RidResponse;
export type UpdateFacilityApiArg = {
    facilityId: string;
    authorization?: string;
    updateFacilityRequest: UpdateFacilityRequest;
};
export type DeleteFacilityApiResponse = /** status 200 OK */ Facility;
export type DeleteFacilityApiArg = {
    facilityId: string;
    authorization?: string;
};
export type UpdateFacilityStatusApiResponse = /** status 200 OK */ RidResponse;
export type UpdateFacilityStatusApiArg = {
    facilityRid: string;
    authorization?: string;
    updateFacilityStatusRequest: {
        /** Facility status */
        status: 'QA' | 'PRE_LIVE' | 'LIVE';
    };
};
export type GetGroupApiResponse = /** status 200 OK */ Group;
export type GetGroupApiArg = {
    groupId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateGroupApiResponse = /** status 200 OK */ RidResponse;
export type UpdateGroupApiArg = {
    groupId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateGroupRequest: CreateOrUpdateGroupRequest;
};
export type DeleteGroupApiResponse = /** status 200 OK */ Group;
export type DeleteGroupApiArg = {
    groupId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetResidentApiResponse = /** status 200 OK */ Resident;
export type GetResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateResidentApiResponse = /** status 200 OK */ RidResponse;
export type UpdateResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateResidentRequest: CreateOrUpdateResidentRequest;
};
export type DeleteResidentApiResponse = /** status 200 OK */ Resident;
export type DeleteResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetLatestCareActivityApiResponse = /** status 200 OK */ LatestCareActivityMap;
export type GetLatestCareActivityApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    getLatestCareActivityRequest: GetLatestCareActivityRequest;
};
export type GetContactOfResidentApiResponse = /** status 200 OK */ {
    id?: string;
    firstName?: string;
    lastName?: string;
    primaryPhone?: string;
    secondaryPhone?: string;
    email?: string;
    relationship?: string;
    isLocal?: boolean;
    preferredContactMethod?: string;
    hasPowerOfAttorney?: boolean;
    address?: Address;
    salutation?: string;
    primaryLanguage?: string;
};
export type GetContactOfResidentApiArg = {
    contactId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateContactApiResponse = /** status 200 OK */ RidResponse;
export type UpdateContactApiArg = {
    contactId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateContactRequest: CreateOrUpdateContactRequest;
};
export type DeleteContactApiResponse = /** status 200 OK */ Contact;
export type DeleteContactApiArg = {
    contactId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetStaffMemberApiResponse = /** status 200 OK */ StaffMember;
export type GetStaffMemberApiArg = {
    staffMemberId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateStaffMemberApiResponse = /** status 200 OK */ RidResponse;
export type UpdateStaffMemberApiArg = {
    staffMemberId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateStaffMemberRequest: CreateOrUpdateStaffMemberRequest;
};
export type DeleteStaffMemberApiResponse = /** status 200 OK */ StaffMember;
export type DeleteStaffMemberApiArg = {
    staffMemberId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetTaskApiResponse = /** status 200 OK */ Task;
export type GetTaskApiArg = {
    taskId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateTaskApiResponse = /** status 200 OK */ RidResponse;
export type UpdateTaskApiArg = {
    taskId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateTaskRequest: CreateOrUpdateTaskRequest;
};
export type DeleteTaskApiResponse = /** status 200 OK */ Task;
export type DeleteTaskApiArg = {
    taskId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type ResolveTaskApiResponse = /** status 200 OK */ RidResponse;
export type ResolveTaskApiArg = {
    taskId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    resolveTaskRequest: ResolveTaskRequest;
};
export type GetCareConfirmationTrackerApiResponse = /** status 200 OK */ CareConfirmationTracker;
export type GetCareConfirmationTrackerApiArg = {
    taskId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type ResolveAllAlertsApiResponse = /** status 200 OK */ number;
export type ResolveAllAlertsApiArg = {
    facilityRid: string;
    authorization?: string;
    resolveAlertsRequest: {
        staffMemberRid: string;
    };
};
export type GetUnitApiResponse = /** status 200 OK */ Unit;
export type GetUnitApiArg = {
    unitId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateUnitApiResponse = /** status 200 OK */ RidResponse;
export type UpdateUnitApiArg = {
    unitId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateUnitRequest: CreateOrUpdateUnitRequest;
};
export type DeleteUnitApiResponse = /** status 200 OK */ Unit;
export type DeleteUnitApiArg = {
    unitId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type CreateUpdateForTaskApiResponse = /** status 200 OK */ RidResponse;
export type CreateUpdateForTaskApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createTaskUpdateRequest: CreateTaskUpdateRequest;
};
export type DeleteUpdateApiResponse = /** status 200 OK */ TaskUpdate;
export type DeleteUpdateApiArg = {
    updateId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetAllocatedDevicesApiResponse = /** status 200 OK */ DeviceAllocation[];
export type GetAllocatedDevicesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    unitRid?: string;
    doorRid?: string;
    facilityRid?: string;
    enterpriseRid?: string;
    type?: string;
    residentRid?: string;
    deviceHardwareId?: string;
    isCheckIn?: boolean;
    isSilent?: boolean;
};
export type AllocateDeviceApiResponse = /** status 200 OK */ RidResponse;
export type AllocateDeviceApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateDeviceAllocationRequest: {
        deviceRid?: string;
        unitRid?: string;
        room?: string;
        facilityId?: string;
        enterpriseId?: string;
        displayName?: string;
        residentId?: string;
        isCheckIn?: boolean;
        doorRid?: string;
        isSilent?: boolean;
    };
};
export type SearchLatestAllocatedDeviceActivityApiResponse = /** status 200 OK */ {
    deviceRid: string;
    deviceAllocationRid?: string;
    deviceDisplayName?: string;
    createDateTimestampInEpochMs: number;
    latestUpdateTimestampInEpochMs?: number;
    batteryLevel?: number;
    facilityRid: string;
}[];
export type SearchLatestAllocatedDeviceActivityApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    searchLatestAllocatedDeviceActivityRequest: {
        facilityRid: string;
        toTimestampInEpochMs?: number;
        fromTimestampInEpochMs?: number;
        getOnlyUnassigned?: boolean;
        getOnlyAssigned?: boolean;
        /** Device types must be any of Sage Button or Door Alarm */
        deviceTypes?: string[];
    };
};
export type GetDeviceAllocationApiResponse = /** status 200 OK */ DeviceAllocation;
export type GetDeviceAllocationApiArg = {
    deviceAllocationId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateDeviceAllocationApiResponse = /** status 200 OK */ RidResponse;
export type UpdateDeviceAllocationApiArg = {
    deviceAllocationId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    schema: CreateOrUpdateDeviceAllocationRequest;
};
export type DeallocateDeviceApiResponse = /** status 200 OK */ DeviceAllocation;
export type DeallocateDeviceApiArg = {
    deviceAllocationId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetDevicesApiResponse = /** status 200 OK */ Device[];
export type GetDevicesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createdBefore?: string;
    createdAfter?: string;
    type?: string;
    hardwareId?: string;
    isAllocated?: boolean;
};
export type CreateDeviceApiResponse = /** status 200 OK */ RidResponse;
export type CreateDeviceApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createDeviceRequest: CreateDeviceRequest;
};
export type GetDeviceApiResponse = /** status 200 OK */ Device;
export type GetDeviceApiArg = {
    deviceId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type DeleteDeviceApiResponse = /** status 200 OK */ Device;
export type DeleteDeviceApiArg = {
    deviceId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetOnCallStateApiResponse = /** status 200 OK */ OnCallState;
export type GetOnCallStateApiArg = {
    staffMemberId: string;
    facilityId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateOnCallStateApiResponse = /** status 200 OK */ OnCallState;
export type UpdateOnCallStateApiArg = {
    staffMemberId: string;
    facilityId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    updateOnCallStateRequest: UpdateOnCallStateRequest;
};
export type GetMembershipDetailsApiResponse = /** status 200 OK */ GroupMembershipEntry[];
export type GetMembershipDetailsApiArg = {
    groupId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateGroupMembershipApiResponse = /** status 200 OK */ GroupMembershipEntry;
export type UpdateGroupMembershipApiArg = {
    groupId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateGroupMembershipRequest: CreateOrUpdateGroupMembershipRequest;
};
export type RemoveMemberFromGroupApiResponse = /** status 200 OK */ GroupMembershipEntry[];
export type RemoveMemberFromGroupApiArg = {
    groupId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    staffMemberId: string;
};
export type GetApplicationConfigurationApiResponse = /** status 200 OK */ ServiceConfiguration;
export type GetApplicationConfigurationApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type ResolveNumbersApiResponse = /** status 200 OK */ ResolveNumbersResponse;
export type ResolveNumbersApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** Phone number of the sender */
    senderNumber?: string;
    /** Phone number of the recipient */
    recipientNumber?: string;
};
export type CreateNoteForTaskApiResponse = /** status 200 OK */ RidResponse;
export type CreateNoteForTaskApiArg = {
    taskId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateTaskNoteRequest: CreateOrUpdateTaskNoteRequest;
};
export type DeleteTaskNoteApiResponse = /** status 200 OK */ Note;
export type DeleteTaskNoteApiArg = {
    noteId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateTaskNoteApiResponse = /** status 200 OK */ Note;
export type UpdateTaskNoteApiArg = {
    noteId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateTaskNoteRequest: CreateOrUpdateTaskNoteRequest;
};
export type UnassignTaskApiResponse = /** status 200 OK */ Task;
export type UnassignTaskApiArg = {
    taskId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateResidentArchiveStatusApiResponse = unknown;
export type UpdateResidentArchiveStatusApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    updateResidentArchiveStatusRequest: UpdateResidentArchiveStatusRequest;
};
export type UpdateStaffMemberArchiveStatusApiResponse = unknown;
export type UpdateStaffMemberArchiveStatusApiArg = {
    staffMemberId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    archive?: boolean;
};
export type GetStaffMemberFacilityMetadataApiResponse =
    /** status 200 OK */ StaffMemberFacilityMetadata[];
export type GetStaffMemberFacilityMetadataApiArg = {
    staffMemberId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** facility id to get metadata for */
    facilityId?: string;
};
export type DeleteStaffMemberFromFacilitiesApiResponse =
    /** status 200 OK */ StaffMemberFacilityMetadata[];
export type DeleteStaffMemberFromFacilitiesApiArg = {
    staffMemberId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    ridArray: RidArray;
};
export type GetImageForResourceApiResponse = string;
export type GetImageForResourceApiArg = {
    resourceId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UploadImageForResourceApiResponse = unknown;
export type UploadImageForResourceApiArg = {
    resourceId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    body: string;
};
export type DeleteImageForResourceApiResponse = unknown;
export type DeleteImageForResourceApiArg = {
    resourceId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type SetPcpForResidentApiResponse = /** status 200 OK */ RidResponse;
export type SetPcpForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateProfessionalContactRequest: CreateOrUpdateProfessionalContactRequest;
};
export type DeletePcpForResidentApiResponse = /** status 200 OK */ ProfessionalContact;
export type DeletePcpForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetPcpForResidentApiResponse = /** status 200 OK */ {
    id?: string;
    companyName?: string;
    companyDescription?: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    officePhoneNumber?: string;
    personalPhoneNumber?: string;
    email?: string;
    officeFax?: string;
};
export type GetPcpForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetSpecialistsForResidentApiResponse = /** status 200 OK */ ProfessionalContact[];
export type GetSpecialistsForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type AddSpecialistForResidentApiResponse = /** status 200 OK */ RidResponse;
export type AddSpecialistForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateProfessionalContactRequest: CreateOrUpdateProfessionalContactRequest;
};
export type UpdateSpecialistForResidentApiResponse = /** status 200 OK */ RidResponse;
export type UpdateSpecialistForResidentApiArg = {
    residentId: string;
    specialistId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateProfessionalContactRequest: CreateOrUpdateProfessionalContactRequest;
};
export type DeleteSpecialistForResidentApiResponse = /** status 200 OK */ ProfessionalContact;
export type DeleteSpecialistForResidentApiArg = {
    residentId: string;
    specialistId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UnallocateDeviceApiResponse = /** status 200 OK */ DeviceAllocation;
export type UnallocateDeviceApiArg = {
    deviceAllocationId: string;
    authorization?: string;
};
export type BulkUpdateDeviceAllocationsApiResponse = /** status 200 OK */ RidArray;
export type BulkUpdateDeviceAllocationsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    bulkUpdateDeviceAllocations: {
        requests?: {
            deviceAllocationRid: string;
            unitRid?: string;
            room?: string;
            facilityId?: string;
            enterpriseId?: string;
            displayName?: string;
            residentRid?: string;
            isCheckIn?: boolean;
            doorRid?: string;
            isSilent?: boolean;
        }[];
    };
};
export type TaskSearchApiResponse = /** status 200 OK */ TaskSearchResult;
export type TaskSearchApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    taskSearchRequest: TaskSearchRequest;
};
export type SetResidentHealthInfoApiResponse = /** status 200 OK */ RidResponse;
export type SetResidentHealthInfoApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    healthInfo: HealthInfo;
};
export type DeleteResidentHealthInfoApiResponse = /** status 200 OK */ HealthInfo;
export type DeleteResidentHealthInfoApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type CreateTaskScheduleApiResponse = /** status 200 OK */ RidResponse;
export type CreateTaskScheduleApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateTaskScheduleRequest: CreateOrUpdateTaskScheduleRequest;
};
export type GetTaskScheduleApiResponse = /** status 200 OK */ TaskSchedule;
export type GetTaskScheduleApiArg = {
    scheduleId: string;
    authorization?: string;
};
export type UpdateTaskScheduleApiResponse = /** status 200 OK */ RidResponse;
export type UpdateTaskScheduleApiArg = {
    scheduleId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateTaskScheduleRequest: CreateOrUpdateTaskScheduleRequest;
};
export type DeleteTaskScheduleApiResponse = /** status 200 OK */ TaskSchedule;
export type DeleteTaskScheduleApiArg = {
    scheduleId: string;
    authorization?: string;
};
export type TaskScheduleSearchApiResponse = /** status 200 OK */ TaskScheduleSearchResult;
export type TaskScheduleSearchApiArg = {
    authorization?: string;
    taskScheduleSearchRequest: TaskScheduleSearchRequest;
};
export type GetMyTasksApiResponse = /** status 200 OK */ TaskSearchResult;
export type GetMyTasksApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    userTaskSearchRequest: UserTaskSearchRequest;
};
export type UnassignTaskScheduleApiResponse = /** status 200 OK */ RidResponse;
export type UnassignTaskScheduleApiArg = {
    scheduleId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type AddDeviceActivityApiResponse = unknown;
export type AddDeviceActivityApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    addDeviceActivityRequest: AddDeviceActivityRequest;
};
export type GetLatestDeviceActivityApiResponse = /** status 200 OK */ LatestDeviceActivities;
export type GetLatestDeviceActivityApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    getLatestDeviceActivityRequest: GetLatestDeviceActivityRequest;
};
export type CreateResidentActivityApiResponse = /** status 200 OK */ RidResponse;
export type CreateResidentActivityApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createResidentActivityRequest: CreateResidentActivityRequest;
};
export type UnsetTaskScheduleDurationApiResponse = /** status 200 OK */ RidResponse;
export type UnsetTaskScheduleDurationApiArg = {
    scheduleId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetCompanyPhonesApiResponse = /** status 200 OK */ CompanyPhone[];
export type GetCompanyPhonesApiArg = {
    authorization?: string;
    enterpriseId?: string;
    facilityId?: string;
    availablePhonesOnly?: boolean;
};
export type CreateCompanyPhoneApiResponse = /** status 200 OK */ RidResponse;
export type CreateCompanyPhoneApiArg = {
    authorization?: string;
    createOrUpdateCompanyPhoneRequest: CreateOrUpdateCompanyPhoneRequest;
};
export type GetCompanyPhoneApiResponse = /** status 200 OK */ {
    id?: string;
    phoneNumber?: string;
    displayName?: string;
    facility?: Facility;
    enterprise?: Enterprise;
};
export type GetCompanyPhoneApiArg = {
    companyPhoneId: string;
    authorization?: string;
};
export type UpdateCompanyPhoneApiResponse = /** status 200 OK */ RidResponse;
export type UpdateCompanyPhoneApiArg = {
    companyPhoneId: string;
    authorization?: string;
    createOrUpdateCompanyPhoneRequest: CreateOrUpdateCompanyPhoneRequest;
};
export type DeleteCompanyPhoneApiResponse = /** status 200 OK */ CompanyPhone;
export type DeleteCompanyPhoneApiArg = {
    companyPhoneId: string;
    authorization?: string;
};
export type CreateCompanyPhonesApiResponse = /** status 200 OK */ RidArray;
export type CreateCompanyPhonesApiArg = {
    authorization?: string;
    bulkCreateCompanyPhonesRequest: BulkCreateCompanyPhonesRequest;
};
export type GetHospitalForResidentApiResponse = /** status 200 OK */ {
    id?: string;
    name?: string;
    phoneNumber?: string;
    faxNumber?: string;
    address?: Address;
};
export type GetHospitalForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type SetHospitalForResidentApiResponse = /** status 200 OK */ RidResponse;
export type SetHospitalForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateHospitalRequest: CreateOrUpdateHospitalRequest;
};
export type DeleteHospitalForResidentApiResponse = /** status 200 OK */ Hospital;
export type DeleteHospitalForResidentApiArg = {
    residentId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetCareZoneApiResponse = /** status 200 OK */ {
    id?: string;
    name?: string;
    facilityId?: string;
    unitTags?: UnitTags;
};
export type GetCareZoneApiArg = {
    careZoneId: string;
    authorization?: string;
};
export type UpdateCareZoneApiResponse = /** status 200 OK */ RidResponse;
export type UpdateCareZoneApiArg = {
    careZoneId: string;
    authorization?: string;
    createOrUpdateCareZoneRequest: CreateOrUpdateCareZoneRequest;
};
export type DeleteCareZoneApiResponse = /** status 200 OK */ CareZone;
export type DeleteCareZoneApiArg = {
    careZoneId: string;
    authorization?: string;
};
export type GetAllCareZonesApiResponse = /** status 200 OK */ CareZone[];
export type GetAllCareZonesApiArg = {
    authorization?: string;
    enterpriseRid?: string;
    facilityRid?: string;
};
export type CreateCareZoneApiResponse = /** status 200 OK */ RidResponse;
export type CreateCareZoneApiArg = {
    authorization?: string;
    createOrUpdateCareZoneRequest: CreateOrUpdateCareZoneRequest;
};
export type GetOnCallStaffMembersApiResponse = /** status 200 OK */ StaffMember[];
export type GetOnCallStaffMembersApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    staffOnCallRequest: StaffOnCallRequest;
};
export type UnitsSearchApiResponse = /** status 200 OK */ UnitSearchResult;
export type UnitsSearchApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    unitSearchRequest: UnitSearchRequest;
};
export type CheckInSearchApiResponse = /** status 200 OK */ CheckInSearchResult;
export type CheckInSearchApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    checkInSearchRequest: CheckInSearchRequest;
};
export type CreateStaffFacilityPreferencesApiResponse = /** status 200 OK */ RidResponse;
export type CreateStaffFacilityPreferencesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createStaffFacilityPreferencesRequest: CreateStaffFacilityPreferencesRequest;
};
export type GetStaffFacilityPreferencesApiResponse = /** status 200 OK */ {
    id?: string;
    staffMemberId?: string;
    facilityId?: string;
    pinnedResidentIds?: RidArray;
};
export type GetStaffFacilityPreferencesApiArg = {
    staffMemberId: string;
    facilityId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateStaffFacilityPreferencesApiResponse = /** status 200 OK */ RidResponse;
export type UpdateStaffFacilityPreferencesApiArg = {
    staffMemberId: string;
    facilityId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    updateStaffFacilityPreferencesRequest: UpdateStaffFacilityPreferencesRequest;
};
export type DeleteStaffFacilityPreferencesApiResponse =
    /** status 200 OK */ StaffFacilityPreferences;
export type DeleteStaffFacilityPreferencesApiArg = {
    staffMemberId: string;
    facilityId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type SearchStaffFacilityPreferencesApiResponse =
    /** status 200 OK */ StaffFacilityPreferences[];
export type SearchStaffFacilityPreferencesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    staffFacilityPreferenceSearchRequest: StaffFacilityPreferenceSearchRequest;
};
export type CreateStaffMemberFacilityConfigApiResponse = /** status 200 OK */ RidResponse;
export type CreateStaffMemberFacilityConfigApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateStaffMemberFacilityConfigRequest: CreateOrUpdateStaffMemberFacilityConfigRequest;
};
export type GetStaffMemberFacilityConfigApiResponse = /** status 200 OK */ {
    id?: string;
    facilityId?: string;
    staffMemberId?: string;
    careZones?: CareZone[];
    jobTitle?: string;
    isAgencyStaff?: boolean;
    isCareAdmin?: boolean;
    shouldReceiveAllCareMessages?: boolean;
};
export type GetStaffMemberFacilityConfigApiArg = {
    staffMemberFacilityConfigId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateStaffMemberFacilityConfigApiResponse = /** status 200 OK */ RidResponse;
export type UpdateStaffMemberFacilityConfigApiArg = {
    staffMemberFacilityConfigId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateStaffMemberFacilityConfigRequest: CreateOrUpdateStaffMemberFacilityConfigRequest;
};
export type DeleteStaffMemberFacilityConfigApiResponse =
    /** status 200 OK */ StaffMemberFacilityConfig;
export type DeleteStaffMemberFacilityConfigApiArg = {
    staffMemberFacilityConfigId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type SearchStaffMemberFacilityConfigsApiResponse =
    /** status 200 OK */ StaffMemberFacilityConfig[];
export type SearchStaffMemberFacilityConfigsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    staffMemberFacilityConfigSearchRequest: StaffMemberFacilityConfigSearchRequest;
};
export type StaffProfileSearchApiResponse = /** status 200 OK */ StaffMember;
export type StaffProfileSearchApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    staffProfileSearchRequest: StaffProfileSearchRequest;
};
export type GetUserStaffMemberProfilesApiResponse = /** status 200 OK */ StaffMember[];
export type GetUserStaffMemberProfilesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type CreateDoorApiResponse = /** status 200 OK */ RidResponse;
export type CreateDoorApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    items: CreateOrUpdateDoorRequest;
};
export type CreateDoorsApiResponse = /** status 200 OK */ RidArray;
export type CreateDoorsApiArg = {
    authorization?: string;
    bulkCreateDoorsRequest: BulkCreateDoorsRequest;
};
export type GetDoorApiResponse = /** status 200 OK */ Door;
export type GetDoorApiArg = {
    doorRid: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateDoorApiResponse = /** status 200 OK */ RidResponse;
export type UpdateDoorApiArg = {
    doorRid: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    items: CreateOrUpdateDoorRequest;
};
export type DeleteDoorApiResponse = /** status 200 OK */ Door;
export type DeleteDoorApiArg = {
    doorRid: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type CreateOrUpdateDoorAlarmScheduleApiResponse = /** status 200 OK */ RidResponse;
export type CreateOrUpdateDoorAlarmScheduleApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateDoorAlarmScheduleRequest: {
        doorRid: string;
        alwaysAlert: boolean;
        neverAlert: boolean;
        /** A map where the key is a day of the week, and the value is a list of time ranges. It accepts All as a wildcard. */
        dayOfWeekTimeRangeMap?: {
            [key: string]: TimeRangeArray;
        };
        dayOfMonthTimeRangeMap?: {
            [key: string]: TimeRangeArray;
        };
        holidayOverrides?: {
            [key: string]: {
                startTime?: string;
                endTime?: string;
            }[];
        };
    };
};
export type GetDoorAlarmScheduleApiResponse = /** status 200 OK */ DoorAlarmSchedule;
export type GetDoorAlarmScheduleApiArg = {
    doorAlarmScheduleRid: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type DeleteDoorAlarmScheduleApiResponse = /** status 200 OK */ DoorAlarmSchedule;
export type DeleteDoorAlarmScheduleApiArg = {
    doorAlarmScheduleRid: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type CreateOrUpdateFacilityDefaultDoorAlarmScheduleApiResponse =
    /** status 200 OK */ RidResponse;
export type CreateOrUpdateFacilityDefaultDoorAlarmScheduleApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdateDoorAlarmScheduleRequest: {
        facilityRid: string;
        alwaysAlert: boolean;
        neverAlert: boolean;
        dayOfWeekTimeRangeMap?: DayOfWeekTimeRangeMap;
        dayOfMonthTimeRangeMap?: TimeRangeMap;
        holidayOverrides?: {
            [key: string]: TimeRangeArray;
        };
    };
};
export type GetFacilityDefaultDoorAlarmScheduleApiResponse = /** status 200 OK */ {
    rid?: string;
    /** If left blank, it is assumed that this a default, facility wide schedule. */
    doorRid?: string;
    facilityRid: string;
    alwaysAlert: boolean;
    neverAlert: boolean;
    /** The object represents a union set of excluded time ranges. Because it is a union set, it is possible to have overlapping time ranges, which will not affect the evaluation of the schedule. In other words, if the current time matches any of the given time ranges, then the schedule is inside it's exclusion window. */
    dailySchedules?: {
        dayOfWeekTimeRangeMap?: DayOfWeekTimeRangeMap;
        dayOfMonthTimeRangeMap?: TimeRangeMap;
    };
    /** An inverted representation of the current schedule for the day. It represents the times that a door alarm will fire on a given day. */
    currentDayDisplaySchedules?: string;
    holidaySchedules?: {
        [key: string]: TimeRangeArray;
    };
};
export type GetFacilityDefaultDoorAlarmScheduleApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    facilityRid: string;
};
export type DeleteFacilityDefaultDoorAlarmScheduleApiResponse =
    /** status 200 OK */ DoorAlarmSchedule;
export type DeleteFacilityDefaultDoorAlarmScheduleApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    facilityRid: string;
};
export type SearchDoorsApiResponse = /** status 200 OK */ Door[];
export type SearchDoorsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    doorSearchRequest: {
        facilityRids?: RidArray;
        unitRids?: RidArray;
        doorRids?: RidArray;
    };
};
export type SearchDoorAlarmSchedulesApiResponse = /** status 200 OK */ DoorAlarmSchedule[];
export type SearchDoorAlarmSchedulesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    doorAlarmScheduleSearchRequest: {
        doorAlarmScheduleRids?: RidArray;
        facilityRids: RidArray;
        doorRids?: RidArray;
    };
};
export type GetAllFeaturesApiResponse = /** status 200 OK */ FeaturesResponse;
export type GetAllFeaturesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    facilityId?: string;
};
export type CreateFeatureApiResponse = /** status 201 OK */ RidResponse;
export type CreateFeatureApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createFeatureRequest: CreateFeatureRequest;
};
export type GetFeatureApiResponse = /** status 200 OK */ Feature;
export type GetFeatureApiArg = {
    featureId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type UpdateFeatureApiResponse = /** status 200 OK */ RidResponse;
export type UpdateFeatureApiArg = {
    featureId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
    updateFeatureRequest: UpdateFeatureRequest;
};
export type DeleteFeatureApiResponse = /** status 200 OK */ Feature;
export type DeleteFeatureApiArg = {
    featureId: string;
    authorization?: string;
    'x-sage-profile-rid'?: string;
};
export type GetNotificationContextApiResponse = /** status 200 OK */ NotificationContext;
export type GetNotificationContextApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    getNotificationContextRequest: GetNotificationContextRequest;
};
export type SimulateDeviceEventApiResponse = unknown;
export type SimulateDeviceEventApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    /** The type of device to simulate */
    deviceType: 'BUTTON' | 'DOOR' | 'FALL_DETECTION_DEVICE';
    simulateDeviceEventRequest: SimulateDeviceEventRequest;
};
export type OutcomeType = 'COMPLETED' | 'NOT_COMPLETED' | 'REFUSED';
export type CareOutcome = {
    /** Supported options for care provided */
    careProvided:
        | 'EMERGENCY_FALL'
        | 'EMERGENCY_OTHER'
        | 'ADL_TOILETING'
        | 'ADL_ROUTINE_CHECK'
        | 'ADL_ESCORT'
        | 'ADL_DRESSING'
        | 'ADL_TRANSFER'
        | 'ADL_EATING'
        | 'ADL_BATHING'
        | 'ADL_GROOMING'
        | 'REQUEST_MEDTECH'
        | 'REQUEST_OXYGEN'
        | 'REQUEST_REPAIR'
        | 'REQUEST_OTHER'
        | 'HOUSEKEEPING_TRASH'
        | 'HOUSEKEEPING_BED'
        | 'HOUSEKEEPING_LAUNDRY'
        | 'HOUSEKEEPING_BATHROOM'
        | 'HOUSEKEEPING_OTHER'
        | 'NON_EMERGENCY_ACCIDENTAL_PRESS'
        | 'NON_EMERGENCY_QUESTION'
        | 'NON_EMERGENCY_ILLNESS'
        | 'NON_EMERGENCY_FOUND_DEVICE'
        | 'NON_EMERGENCY_NO_CARE_GIVEN';
    outcome: OutcomeType;
    creatorEntityRid?: string;
    alertRid?: string;
};
export type Observation = {
    /** Supported options for observation types */
    observationType:
        | 'ABNORMAL_BOWEL_MOVEMENT'
        | 'ABNORMAL_URINE'
        | 'MOOD_BEHAVIOR_ISSUE'
        | 'PAIN'
        | 'SKIN_ISSUE';
    observationValue?: string;
};
export type CreateCareEventNoteRequest = {
    /** This enum is add only. If you delete or update the values below, the api will break. */
    noteType: 'CREATION_NOTE' | 'CAREGIVER_NOTE' | 'NURSE_ACKNOWLEDGEMENT';
    content: string;
    creatorRid: string;
};
export type Address = {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
};
export type Enterprise = {
    id?: string;
    name?: string;
    address?: Address;
    phoneNumber?: string;
    administrator?: StaffMember;
    facilities?: Facility[];
    logoUrl?: string;
    notificationApplicationId?: string;
};
export type RidArray = string[];
export type UnitTags = {
    floors?: string[];
    wings?: string[];
    careTypes?: string[];
    buildings?: string[];
    wards?: string[];
    unitRids?: RidArray;
};
export type CareZone = {
    id?: string;
    name?: string;
    facilityId?: string;
    unitTags?: UnitTags;
};
export type MessageDeliveryMethods = string[];
export type FacilityStatus = 'QA' | 'PRE_LIVE' | 'LIVE';
export type FacilityConfig = {
    isAlertLocationEnabled?: boolean;
    routineCheckIntervalInMin?: number;
};
export type Facility = {
    id?: string;
    name?: string;
    address?: Address;
    phoneNumber?: string;
    administrator?: StaffMember;
    enterprise?: Enterprise;
    logoUrl?: string;
    emergencyNotificationNumber?: string;
    requestNotificationNumber?: string;
    taskNotificationNumber?: string;
    timezone?: string;
    careZones?: CareZone[];
    messageDeliveryMethods?: MessageDeliveryMethods;
    status?: FacilityStatus;
    facilityConfig?: FacilityConfig;
};
export type OnCallState = {
    isOnCall?: boolean;
    lastModified?: string;
    facilityId?: string;
    companyPhoneId?: string;
};
export type StaffMemberFacilityConfig = {
    id?: string;
    facilityId?: string;
    staffMemberId?: string;
    careZones?: CareZone[];
    jobTitle?: string;
    isAgencyStaff?: boolean;
    isCareAdmin?: boolean;
    shouldReceiveAllCareMessages?: boolean;
};
export type StaffMember = {
    id?: string;
    firstName?: string;
    middleName?: string;
    middleInitial?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    address?: Address;
    facilities?: Facility[];
    enterprise?: Enterprise;
    jobTitle?: string;
    onCallStates?: OnCallState[];
    shouldReceiveAllCareMessages?: boolean;
    isCareAdmin?: boolean;
    isArchived?: boolean;
    isAgencyStaff?: boolean;
    staffMemberFacilityConfigs?: StaffMemberFacilityConfig[];
};
export type CareEventWithStaffDetails = {
    rid?: string;
    residentRid?: string;
    facilityRid?: string;
    alertRid?: string;
    plannedCareRid?: string;
    /** Rid of the "logged care" task that was migrated to a care event. This field is only used for migration purposes
    and will be deprecated after the care event migration is complete.
     */
    loggedCareRid?: string;
    /** Rid of the staff that documented the care event */
    creatorRid?: string;
    /** Rid of the staff that updated the care event */
    lastUpdatedByRid?: string;
    careOutcomes?: CareOutcome[];
    observations?: Observation[];
    notes?: (CreateCareEventNoteRequest & {
        /** The timestamp in milliseconds when the note was created. */
        createTimestampMs?: number;
        /** Rid of the staff that updated the note */
        lastUpdatedByRid?: string;
        /** The timestamp in milliseconds when the note was last updated. */
        updateTimestampMs?: number;
    })[];
    /** The timestamp in milliseconds when the care event was created */
    createTimestampMs?: number;
    /** The timestamp in milliseconds when the care event was last updated. */
    updateTimestampMs?: number;
    /** A list of all staff rids that responded to the care event. */
    respondingStaffRids?: string[];
} & {
    /** A map of staff member RIDs to staff member details */
    staffMembers?: {
        [key: string]: StaffMember;
    };
};
export type Contact = {
    id?: string;
    firstName?: string;
    lastName?: string;
    primaryPhone?: string;
    secondaryPhone?: string;
    email?: string;
    relationship?: string;
    isLocal?: boolean;
    preferredContactMethod?: string;
    hasPowerOfAttorney?: boolean;
    address?: Address;
    salutation?: string;
    primaryLanguage?: string;
};
export type Note = {
    id?: string;
    creationTimestamp?: string;
    lastUpdatedTimestamp?: string;
    content?: string;
    subject?: string;
    creator?: StaffMember;
    lastUpdatedBy?: StaffMember;
};
export type Device = {
    id?: string;
    type?: string;
    connectivityStatus?: string;
    activationStatus?: string;
    hardwareId?: string;
    creationTimestamp?: string;
    notes?: Note[];
    lastPressTimestamp?: string;
};
export type TimeRangeArray = {
    startTime?: string;
    endTime?: string;
}[];
export type DayOfWeekTimeRangeMap = {
    [key: string]: TimeRangeArray;
};
export type TimeRangeMap = {
    [key: string]: TimeRangeArray;
};
export type DoorAlarmSchedule = {
    rid?: string;
    /** If left blank, it is assumed that this a default, facility wide schedule. */
    doorRid?: string;
    facilityRid: string;
    alwaysAlert: boolean;
    neverAlert: boolean;
    /** The object represents a union set of excluded time ranges. Because it is a union set, it is possible to have overlapping time ranges, which will not affect the evaluation of the schedule. In other words, if the current time matches any of the given time ranges, then the schedule is inside it's exclusion window. */
    dailySchedules?: {
        dayOfWeekTimeRangeMap?: DayOfWeekTimeRangeMap;
        dayOfMonthTimeRangeMap?: TimeRangeMap;
    };
    /** An inverted representation of the current schedule for the day. It represents the times that a door alarm will fire on a given day. */
    currentDayDisplaySchedules?: string;
    holidaySchedules?: {
        [key: string]: TimeRangeArray;
    };
};
export type Door = {
    rid: string;
    facilityRid: string;
    name?: string;
    doorAlarmSchedule: DoorAlarmSchedule;
    unit: Unit;
    deviceAllocation?: DeviceAllocation;
};
export type DeviceAllocation = {
    room?: string;
    device?: Device;
    id?: string;
    door?: Door;
    unit?: Unit;
    facility?: Facility;
    enterprise?: Enterprise;
    displayName?: string;
    resident?: Resident;
    isCheckIn?: boolean;
    isSilent?: boolean;
};
export type Unit = {
    id?: string;
    currentOccupants?: Resident[];
    devices?: DeviceAllocation[];
    phoneNumber?: string;
    displayName?: string;
    facility?: Facility;
    enterprise?: Enterprise;
    floor?: string;
    wing?: string;
    careType?: string;
    building?: string;
    ward?: string;
};
export type ProfessionalContact = {
    id?: string;
    companyName?: string;
    companyDescription?: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    officePhoneNumber?: string;
    personalPhoneNumber?: string;
    email?: string;
    officeFax?: string;
};
export type HealthInfo = {
    /** Resident memory health information */
    memoryHealthInfo?: {
        memoryLevel?: string;
    };
    /** Resident continence health information */
    continenceHealthInfo?: {
        continenceLevel?: string;
        productProvider?: string;
        wearsPads?: boolean;
        wearsPullups?: boolean;
        wearsBriefs?: boolean;
    };
    /** Resident's activities of daily living information */
    adlHealthInfo?: {
        safetyLevel?: string;
        feedingLevel?: string;
        bathingLevel?: string;
        toiletingLevel?: string;
        transferringLevel?: string;
        mobilityLevel?: string;
        dressingLevel?: string;
        groomingLevel?: string;
    };
    resuscitationStrategy?: string;
    /** Resident hospice health information */
    hospiceHealthInfo?: {
        hospiceStatus: string;
    };
};
export type Hospital = {
    id?: string;
    name?: string;
    phoneNumber?: string;
    faxNumber?: string;
    address?: Address;
};
export type Resident = {
    id?: string;
    salutation?: string;
    firstName?: string;
    middleName?: string;
    middleInitial?: string;
    lastName?: string;
    dateOfBirth?: string;
    age?: number;
    personalPhone?: string;
    createTimestampMs?: number;
    updateTimestampMs?: number;
    email?: string;
    emergencyContact?: Contact;
    contacts?: Contact[];
    unit?: Unit;
    primaryCarePhysician?: ProfessionalContact;
    specialists?: ProfessionalContact[];
    notes?: Note[];
    facility?: Facility;
    enterprise?: Enterprise;
    isRoutineCheckEnabled?: boolean;
    isOnHold?: boolean;
    isArchived?: boolean;
    archiveReason?: string;
    healthInfo?: HealthInfo;
    preferredHospital?: Hospital;
};
export type Group = {
    id?: string;
    name?: string;
    members?: StaffMember[];
    numberOfMembers?: number;
    iconUrl?: string;
    facility?: Facility;
    enterprise?: Enterprise;
};
export type TaskTemplate = {
    title?: string;
    description?: string;
    severity?: string;
    assignee?: StaffMember;
    group?: Group;
    resident?: Resident;
    unit?: Unit;
};
export type ScheduleRecurrenceDefinition = {
    cronScheduleExpression?: string;
    futureExecutionTimeInMs?: number;
    durationInMinutes?: number;
};
export type TaskSchedule = {
    id?: string;
    taskTemplate?: TaskTemplate;
    scheduleRecurrenceDefinition?: ScheduleRecurrenceDefinition;
    creator?: StaffMember;
    lastUpdatedBy?: StaffMember;
    facility?: Facility;
    enterprise?: Enterprise;
    creationTimestamp?: string;
    lastUpdateTimestamp?: string;
    isRecurring?: boolean;
    hasRunOnce?: boolean;
    lastSuccessfulExecutionTimeInMs?: number;
};
export type Author = {
    resident?: Resident;
    staffMember?: StaffMember;
    device?: {
        id?: string;
        type?: string;
        connectivityStatus?: string;
        activationStatus?: string;
        hardwareId?: string;
        creationTimestamp?: string;
        notes?: Note[];
        lastPressTimestamp?: string;
    };
    schedule?: TaskSchedule;
};
export type AlertOutcomeType = 'RESIDENT_INTERACTION' | 'TEST_ALERT' | 'FALSE_ALARM';
export type AlertOutcome = {
    rid?: string;
    type?: AlertOutcomeType;
    taskUpdateRid?: string;
    taskRid?: string;
};
export type TaskUpdate = {
    id?: string;
    type?: string;
    creationTimestamp?: string;
    creationTimestampInMs?: string;
    description?: string;
    author?: Author;
    careProvided?: string[];
    alertOutcome?: AlertOutcome;
};
export type Task = {
    id?: string;
    title?: string;
    description?: string;
    status?: string;
    severity?: string;
    updates?: TaskUpdate[];
    notes?: {
        id?: string;
        creationTimestamp?: string;
        lastUpdatedTimestamp?: string;
        content?: string;
        subject?: string;
        creator?: StaffMember;
        lastUpdatedBy?: StaffMember;
    }[];
    assignee?: {
        id?: string;
        firstName?: string;
        middleName?: string;
        middleInitial?: string;
        lastName?: string;
        phoneNumber?: string;
        email?: string;
        address?: Address;
        facilities?: Facility[];
        enterprise?: Enterprise;
        jobTitle?: string;
        onCallStates?: OnCallState[];
        shouldReceiveAllCareMessages?: boolean;
        isCareAdmin?: boolean;
        isArchived?: boolean;
        isAgencyStaff?: boolean;
        staffMemberFacilityConfigs?: StaffMemberFacilityConfig[];
    };
    facility?: {
        id?: string;
        name?: string;
        address?: Address;
        phoneNumber?: string;
        administrator?: StaffMember;
        enterprise?: Enterprise;
        logoUrl?: string;
        emergencyNotificationNumber?: string;
        requestNotificationNumber?: string;
        taskNotificationNumber?: string;
        timezone?: string;
        careZones?: CareZone[];
        messageDeliveryMethods?: MessageDeliveryMethods;
        status?: FacilityStatus;
        facilityConfig?: FacilityConfig;
    };
    enterprise?: {
        id?: string;
        name?: string;
        address?: Address;
        phoneNumber?: string;
        administrator?: StaffMember;
        facilities?: Facility[];
        logoUrl?: string;
        notificationApplicationId?: string;
    };
    resident?: {
        id?: string;
        salutation?: string;
        firstName?: string;
        middleName?: string;
        middleInitial?: string;
        lastName?: string;
        dateOfBirth?: string;
        age?: number;
        personalPhone?: string;
        createTimestampMs?: number;
        updateTimestampMs?: number;
        email?: string;
        emergencyContact?: Contact;
        contacts?: Contact[];
        unit?: Unit;
        primaryCarePhysician?: ProfessionalContact;
        specialists?: ProfessionalContact[];
        notes?: Note[];
        facility?: Facility;
        enterprise?: Enterprise;
        isRoutineCheckEnabled?: boolean;
        isOnHold?: boolean;
        isArchived?: boolean;
        archiveReason?: string;
        healthInfo?: HealthInfo;
        preferredHospital?: Hospital;
    };
    /** A residential unit */
    unit?: {
        id?: string;
        currentOccupants?: Resident[];
        devices?: DeviceAllocation[];
        phoneNumber?: string;
        displayName?: string;
        facility?: Facility;
        enterprise?: Enterprise;
        floor?: string;
        wing?: string;
        careType?: string;
        building?: string;
        ward?: string;
    };
    door?: {
        rid: string;
        facilityRid: string;
        name?: string;
        doorAlarmSchedule: DoorAlarmSchedule;
        unit: Unit;
        deviceAllocation?: DeviceAllocation;
    };
    creator?: Author;
    group?: Group;
    schedule?: TaskSchedule;
    dueDateAndTime?: string;
    isRecurring?: boolean;
    creationTimestamp?: string;
    lastUpdateTimestamp?: string;
    careProvided?: string[];
    alertOutcome?: AlertOutcome;
    careEvent?: CareEventWithStaffDetails;
};
export type ResidentActivity = {
    careEvent?: CareEventWithStaffDetails;
    alert?: Task;
    task?: Task;
};
export type CareEventResponse = ResidentActivity & {
    resident?: Resident;
};
export type RidResponse = {
    rid?: string;
};
export type CreateOrUpdateResidentRequest = {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: string;
    personalPhoneNumber?: string;
    email?: string;
    salutation?: string;
    emergencyContactId?: string;
    unitId?: string;
    facilityId?: string;
    enterpriseId?: string;
    isRoutineCheckEnabled?: boolean;
    /** Indicates if the resident is on hold at the facility */
    isOnHold?: boolean;
};
export type CreateOrUpdateContactRequest = {
    firstName?: string;
    lastName?: string;
    salutation?: string;
    email?: string;
    primaryPhoneNumber?: string;
    secondaryPhoneNumber?: string;
    address?: Address;
    hasPowerOfAttorney?: boolean;
    primaryLanguage?: string;
    preferredContactMethod?: string;
    isLocal?: boolean;
    relationship?: string;
};
export type CreateOrUpdateProfessionalContactRequest = {
    companyName?: string;
    companyDescription?: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    officePhoneNumber?: string;
    personalPhoneNumber?: string;
    email?: string;
    officeFax?: string;
};
export type CreateEntireResidentRequest = {
    resident: CreateOrUpdateResidentRequest;
    primaryEmergencyContact?: CreateOrUpdateContactRequest;
    otherContacts?: CreateOrUpdateContactRequest[];
    healthInfo?: HealthInfo;
    primaryCarePhysician?: CreateOrUpdateProfessionalContactRequest;
};
export type BulkCreateResidentsRequest = {
    facilityRid?: string;
    enterpriseRid?: string;
    requests: CreateEntireResidentRequest[];
};
export type ToggleRoutineCheckRequest = {
    residentRids: RidArray;
    shouldEnableRoutineCheck: boolean;
};
export type CreateOrUpdateUnitRequest = {
    phoneNumber?: string;
    displayName?: string;
    facilityId?: string;
    enterpriseId?: string;
    floor?: string;
    wing?: string;
    careType?: string;
    building?: string;
    ward?: string;
};
export type CreateUnitRequestForFacility = {
    phoneNumber?: string;
    displayName?: string;
    floor?: string;
    wing?: string;
    careType?: string;
    building?: string;
    ward?: string;
    unitRid?: string;
};
export type BulkCreateUnitRequestForFacility = {
    facilityRid?: string;
    enterpriseRid?: string;
    requests?: CreateUnitRequestForFacility[];
};
export type CheckIn = {
    id?: string;
    date?: string;
    unit?: Unit;
    status?: string;
    checkInReason?: string;
    checkInTimestamp?: string;
};
export type CreateCheckInRequest = {
    date?: string;
    unitId?: string;
    facilityId?: string;
    enterpriseId?: string;
};
export type UpdateCheckInRequest = {
    status?: string;
    checkInReason?: string;
};
export type CreateOrUpdateStaffMemberFacilityConfigRequest = {
    /** Optional only for a creation request. The API will throw an error if this is not provided to an update. */
    id?: string;
    facilityId?: string;
    staffMemberId?: string;
    careZoneIds?: RidArray;
    jobTitle?: string;
    isAgencyStaff?: boolean;
    isCareAdmin?: boolean;
    shouldReceiveAllCareMessages?: boolean;
    shouldReceiveAllCareAlerts?: boolean;
};
export type CreateOrUpdateStaffMemberRequest = {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    enterpriseId?: string;
    /** Only used when adding a staff member. A list of group RIDs to grant membership to. May not have duplicate groups or groups from different facilities. */
    groupsToGrantMembership?: string[];
    /** Only used when adding a staff member. Used to create a new staffMemberFacilityConfig or update an existing one. */
    isAgencyStaff?: boolean;
    /** Only used when adding a staff member. Used to create a new staffMemberFacilityConfig or update an existing one. */
    isCareAdmin?: boolean;
    /** Only used when adding a staff member. Used to create a new staffMemberFacilityConfig or update an existing one. */
    jobTitle?: string;
    /** Only used when adding a staff member. Used to create a new staffMemberFacilityConfig or update an existing one. */
    shouldReceiveAllCareMessages?: boolean;
    /** Used only when adding a staff member. The careZoneIds to associate the staff member with. It is *strongly encouraged* that this field be provided.
    If not, the staff member will be associated with all care zones in the facility if there is only 1, or none if there are multiple.
     */
    careZoneIds?: string[];
    /** Used only when updating a staff member. */
    staffMemberConfigs?: CreateOrUpdateStaffMemberFacilityConfigRequest[];
};
export type UpdateStaffPermissionsResponse = {
    numProfilesFound?: number;
};
export type UpdateStaffPermissionsRequest = {
    facilityIds?: RidArray;
    enterpriseIds?: RidArray;
};
export type CreateStaffMembersResponse = {
    data?: {
        draftRid?: string;
        publishedRid?: string;
        status?: number;
        message?: string;
    }[];
};
export type GroupAndAdminMembershipRequest = {
    groupRid?: string;
    isGroupAdmin?: boolean;
};
export type CreateEntireStaffMemberRequest = {
    staff: CreateOrUpdateStaffMemberRequest;
    groups: GroupAndAdminMembershipRequest[];
    draftRid?: string;
};
export type BulkCreateStaffMembersRequest = {
    facilityRid?: string;
    enterpriseRid?: string;
    requests?: CreateEntireStaffMemberRequest[];
};
export type GetResidentActivitiesResponse = {
    hasNextPage: boolean;
    pageSize: number;
    pageNumber: number;
} & {
    activities?: ResidentActivity[];
};
export type CreateEnterpriseRequest = {
    name?: string;
    address?: Address;
    phoneNumber?: string;
    logoUrl?: string;
    notificationApplicationId?: string;
    enterpriseRid?: string;
};
export type CreateFacilityRequest = {
    name?: string;
    /** Address */
    address?: {
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        country?: string;
    };
    phoneNumber?: string;
    enterpriseId?: string;
    logoUrl?: string;
    emergencyNotificationNumber?: string;
    requestNotificationNumber?: string;
    taskNotificationNumber?: string;
    timezone?: string;
    facilityRid?: string;
    /** Delivery methods in order of preference.  Will attempt to send all messages using the first delivery methods, falling back to second delivery method and so on. */
    messageDeliveryMethods?: string[];
    facilityConfig?: {
        isAlertLocationEnabled?: boolean;
        routineCheckIntervalInMin?: number;
    };
};
export type CreateOrUpdateTaskRequest = {
    title?: string;
    description?: string;
    status?: string;
    severity?: string;
    durationInMinutes?: number;
    assigneeRid?: string;
    residentRid?: string;
    unitRid?: string;
    doorRid?: string;
    creatorRid?: string;
    groupRid?: string;
    facilityRid?: string;
    enterpriseRid?: string;
    /** Only applicable on updateTask.
    If true, will bypass any care confirmation related status checks.
     */
    bypassCareConfirmationChecks?: boolean;
};
export type CreateOrUpdateGroupRequest = {
    name?: string;
    facilityId?: string;
    enterpriseId?: string;
    iconUrl?: string;
};
export type UpdateEnterpriseRequest = {
    name?: string;
    address?: Address;
    phoneNumber?: string;
    administratorId?: string;
    logoUrl?: string;
    notificationApplicationId?: string;
};
export type UpdateFacilityRequest = {
    name?: string;
    address?: Address;
    phoneNumber?: string;
    administratorId?: string;
    enterpriseId?: string;
    logoUrl?: string;
    emergencyNotificationNumber?: string;
    requestNotificationNumber?: string;
    taskNotificationNumber?: string;
    timezone?: string;
    messageDeliveryMethods?: MessageDeliveryMethods;
    facilityConfig?: FacilityConfig;
};
export type CareEvent = {
    rid?: string;
    residentRid?: string;
    facilityRid?: string;
    alertRid?: string;
    plannedCareRid?: string;
    /** Rid of the "logged care" task that was migrated to a care event. This field is only used for migration purposes
    and will be deprecated after the care event migration is complete.
     */
    loggedCareRid?: string;
    /** Rid of the staff that documented the care event */
    creatorRid?: string;
    /** Rid of the staff that updated the care event */
    lastUpdatedByRid?: string;
    careOutcomes?: CareOutcome[];
    observations?: Observation[];
    notes?: (CreateCareEventNoteRequest & {
        /** The timestamp in milliseconds when the note was created. */
        createTimestampMs?: number;
        /** Rid of the staff that updated the note */
        lastUpdatedByRid?: string;
        /** The timestamp in milliseconds when the note was last updated. */
        updateTimestampMs?: number;
    })[];
    /** The timestamp in milliseconds when the care event was created */
    createTimestampMs?: number;
    /** The timestamp in milliseconds when the care event was last updated. */
    updateTimestampMs?: number;
    /** A list of all staff rids that responded to the care event. */
    respondingStaffRids?: string[];
};
export type LatestCareActivity = {
    task?: Task;
    careEvent?: CareEvent;
    timeSinceLastCareActivityInMin: number;
};
export type LatestCareActivityMap = {
    [key: string]: LatestCareActivity;
};
export type GetLatestCareActivityRequest = {
    residentRids?: RidArray;
};
export type ResolveTaskRequest = {
    /** Deprecated. Use careOutcomes instead.
    A list of resolution types (i.e. care provided) to resolve the task with.
     */
    resolutionTypes?: string[];
    alertOutcomeType?: AlertOutcomeType;
    resolutionNote?: string;
    residentId?: string;
    /** If true, will bypass any care confirmation related status checks. */
    bypassCareConfirmationChecks?: boolean;
    careOutcomes?: {
        /** Supported options for care provided */
        careProvided:
            | 'EMERGENCY_FALL'
            | 'EMERGENCY_OTHER'
            | 'ADL_TOILETING'
            | 'ADL_ROUTINE_CHECK'
            | 'ADL_ESCORT'
            | 'ADL_DRESSING'
            | 'ADL_TRANSFER'
            | 'ADL_EATING'
            | 'ADL_BATHING'
            | 'ADL_GROOMING'
            | 'REQUEST_MEDTECH'
            | 'REQUEST_OXYGEN'
            | 'REQUEST_REPAIR'
            | 'REQUEST_OTHER'
            | 'HOUSEKEEPING_TRASH'
            | 'HOUSEKEEPING_BED'
            | 'HOUSEKEEPING_LAUNDRY'
            | 'HOUSEKEEPING_BATHROOM'
            | 'HOUSEKEEPING_OTHER'
            | 'NON_EMERGENCY_ACCIDENTAL_PRESS'
            | 'NON_EMERGENCY_QUESTION'
            | 'NON_EMERGENCY_ILLNESS'
            | 'NON_EMERGENCY_FOUND_DEVICE'
            | 'NON_EMERGENCY_NO_CARE_GIVEN';
        outcome: OutcomeType;
        creatorEntityRid?: string;
        alertRid?: string;
    }[];
    observations?: {
        /** Supported options for observation types */
        observationType:
            | 'ABNORMAL_BOWEL_MOVEMENT'
            | 'ABNORMAL_URINE'
            | 'MOOD_BEHAVIOR_ISSUE'
            | 'PAIN'
            | 'SKIN_ISSUE';
        observationValue?: string;
    }[];
};
export type CareConfirmationTracker = {
    task?: Task;
    /** A stringified Java Duration represented in milliseconds. Normally should be less than approximately 30s. */
    timeSinceLastUpdate?: string;
};
export type CreateTaskUpdateRequest = {
    type?: string;
    description?: string;
    authorRid?: string;
    taskRid?: string;
};
export type CreateOrUpdateDeviceAllocationRequest = {
    deviceRid?: string;
    unitRid?: string;
    room?: string;
    facilityId?: string;
    enterpriseId?: string;
    displayName?: string;
    residentId?: string;
    isCheckIn?: boolean;
    doorRid?: string;
    isSilent?: boolean;
};
export type CreateDeviceRequest = {
    hardwareId?: string;
    type?: string;
};
export type UpdateOnCallStateRequest = {
    isOnCall: boolean;
    companyPhoneId?: string;
};
export type GroupMembershipEntry = {
    staffMember?: StaffMember;
    isAdmin?: boolean;
};
export type CreateOrUpdateGroupMembershipRequest = {
    staffMemberId?: string;
    isAdmin?: boolean;
};
export type CountryCode = {
    name?: string;
    abbreviation?: string;
    code?: string;
};
export type TaskSeverity = {
    id?: string;
    displayName?: string;
    severity?: number;
};
export type NamedEnum = {
    id?: string;
    displayName?: string;
};
export type ResidentHealthEnum = {
    id?: string;
    displayName?: string;
    severity?: number;
};
export type MemoryConfiguration = {
    memoryLossLevels?: ResidentHealthEnum[];
};
export type ContinenceConfiguration = {
    continenceLevels?: ResidentHealthEnum[];
    productProviders?: ResidentHealthEnum[];
};
export type AdlConfiguration = {
    safetyLevels?: ResidentHealthEnum[];
    feedingLevels?: ResidentHealthEnum[];
    bathingLevels?: ResidentHealthEnum[];
    toiletingLevels?: ResidentHealthEnum[];
    transferringLevels?: ResidentHealthEnum[];
    mobilityLevels?: ResidentHealthEnum[];
    dressingLevels?: ResidentHealthEnum[];
    groomingLevels?: ResidentHealthEnum[];
};
export type ServiceConfiguration = {
    countryCodes?: CountryCode[];
    taskSeverityLevels?: TaskSeverity[];
    taskStatuses?: NamedEnum[];
    taskUpdateTypes?: string[];
    contactRelationships?: NamedEnum[];
    contactMethods?: NamedEnum[];
    salutations?: NamedEnum[];
    defaultLanguages?: NamedEnum[];
    defaultRooms?: NamedEnum[];
    defaultGroups?: NamedEnum[];
    alertResolutionTypes?: NamedEnum[];
    memoryConfiguration?: MemoryConfiguration;
    continenceConfiguration?: ContinenceConfiguration;
    adlConfiguration?: AdlConfiguration;
    resuscitationStrategies?: NamedEnum[];
    residentArchiveReasons?: NamedEnum[];
};
export type ResolveNumbersResponse = {
    applicationId?: string;
    facility?: Facility;
    sendingStaffMember?: StaffMember;
    sendingStaffMemberGroups?: Group[];
    recipientStaffMembers?: StaffMember[];
};
export type CreateOrUpdateTaskNoteRequest = {
    content?: string;
    subject?: string;
};
export type UpdateResidentArchiveStatusRequest = {
    isArchived?: boolean;
    archiveReason?: string;
};
export type StaffMemberGroupMembership = {
    groupId?: string;
    groupName?: string;
    isAdmin?: boolean;
};
export type StaffMemberFacilityMetadata = {
    facilityId?: string;
    onCallState?: {
        isOnCall?: boolean;
        lastModified?: string;
        facilityId?: string;
        companyPhoneId?: string;
    };
    groups?: StaffMemberGroupMembership[];
};
export type TaskSearchResult = {
    tasks?: Task[];
    numResults?: number;
    numPages?: number;
    pageSize?: number;
    hasNextPage?: boolean;
};
export type TaskSearchRequest = {
    facilityId?: string;
    enterpriseId?: string;
    assigneeId?: string;
    creatorId?: string;
    residentId?: string;
    unitId?: string;
    doorId?: string;
    scheduleId?: string;
    deviceType?: string;
    groupIds?: string[];
    statuses?: string[];
    severities?: string[];
    careZoneIds?: string[];
    pageSize?: number;
    pageNumber?: number;
};
export type TaskTemplateDefinition = {
    title?: string;
    description?: string;
    severity?: string;
    assigneeId?: string;
    groupId?: string;
    residentId?: string;
    unitId?: string;
};
export type CreateOrUpdateTaskScheduleRequest = {
    taskTemplateDefinition?: TaskTemplateDefinition;
    recurrenceDefinition?: ScheduleRecurrenceDefinition;
    facilityId?: string;
    enterpriseId?: string;
};
export type TaskScheduleSearchResult = {
    taskSchedules?: TaskSchedule[];
    numResults?: number;
    numPages?: number;
    pageSize?: number;
};
export type TaskScheduleSearchRequest = {
    facilityId?: string;
    enterpriseId?: string;
    creatorId?: string;
    residentId?: string;
    unitId?: string;
    groupIds?: string[];
    severities?: string[];
    isRecurring?: boolean;
    hasRunOnce?: boolean;
    pageSize?: number;
    pageNumber?: number;
};
export type UserTaskSearchRequest = {
    facilityId?: string;
    statuses?: string[];
    severities?: string[];
    pageSize?: number;
    pageNumber?: number;
};
export type AddDeviceActivityRequest = {
    hardwareID?: string;
    batteryLevel?: number;
    interactionType?: string;
    hubId?: string;
};
export type LatestDeviceActivities = {
    [key: string]: number;
};
export type GetLatestDeviceActivityRequest = {
    facilityRid?: string;
    deviceRids?: RidArray;
};
export type CreateResidentActivityRequest = {
    residentId: string;
    unitId?: string;
    creatorId?: string;
    groupId?: string;
    facilityId: string;
    enterpriseId?: string;
    /** Deprecated. Use careOutcomes instead. */
    resolutionType?: string;
    /** Deprecated. Use careOutcomes instead. */
    resolutionTypes?: string[];
    careOutcomes: CareOutcome[];
    observations?: Observation[];
    resolutionNote?: string;
};
export type CompanyPhone = {
    id?: string;
    phoneNumber?: string;
    displayName?: string;
    facility?: Facility;
    enterprise?: Enterprise;
};
export type CreateOrUpdateCompanyPhoneRequest = {
    phoneNumber?: string;
    displayName?: string;
    facilityId?: string;
    enterpriseId?: string;
    companyPhoneRid?: string;
};
export type BulkCreateCompanyPhonesRequest = {
    facilityRid?: string;
    enterpriseRid?: string;
    requests?: CreateOrUpdateCompanyPhoneRequest[];
};
export type CreateOrUpdateHospitalRequest = {
    name?: string;
    phoneNumber?: string;
    faxNumber?: string;
    address?: Address;
};
export type CreateOrUpdateCareZoneRequest = {
    facilityId?: string;
    displayName?: string;
    /** A collection of tags used to describe a Unit. These tags will all be collected and used as search parameters, to find matching Units which may have a matching tag. */
    unitTags?: {
        floors?: string[];
        wings?: string[];
        careTypes?: string[];
        buildings?: string[];
        wards?: string[];
        unitRids?: RidArray;
    };
};
export type StaffOnCallRequest = {
    facilityRid?: string;
    groupRids?: string[];
};
export type UnitSearchResult = {
    units?: Unit[];
    numResults?: number;
    pageSize?: number;
    pageNumber?: number;
};
export type UnitSearchRequest = {
    unitIds?: string[];
    enterpriseIds?: string[];
    facilityIds?: string[];
    careZoneIds?: string[];
    displayNames?: string[];
    phoneNumbers?: string[];
    floors?: string[];
    wings?: string[];
    careTypes?: string[];
    buildings?: string[];
    wards?: string[];
    pageSize?: number;
    pageNumber?: number;
};
export type CheckInSearchResult = {
    checkIns?: CheckIn[];
    numResults?: number;
    pageSize?: number;
    pageNumber?: number;
};
export type CheckInSearchRequest = {
    fromDate?: string;
    toDate?: string;
    unitIds?: string[];
    careZoneIds?: string[];
    facilityIds?: string[];
    enterpriseIds?: string[];
    statuses?: string[];
    checkInReasons?: string[];
    isOnHold?: boolean;
    pageSize?: number;
    pageNumber?: number;
};
export type CreateStaffFacilityPreferencesRequest = {
    facilityId: string;
    staffMemberId: string;
    pinnedResidentIds?: RidArray;
};
export type UpdateStaffFacilityPreferencesRequest = {
    pinnedResidentIds: RidArray;
};
export type StaffFacilityPreferences = {
    id?: string;
    staffMemberId?: string;
    facilityId?: string;
    pinnedResidentIds?: RidArray;
};
export type StaffFacilityPreferenceSearchRequest = {
    facilityId: string;
    staffMemberIds?: RidArray;
};
export type StaffMemberFacilityConfigSearchRequest = {
    staffMemberIds?: RidArray;
    facilityIds?: RidArray;
    careZoneIds?: RidArray;
};
export type StaffProfileSearchRequest = {
    phoneNumber: string;
    lastName: string;
    enterpriseId: string;
    facilityId: string;
};
export type CreateOrUpdateDoorRequest = {
    facilityRid?: string;
    unitRid?: string;
    doorAlarmScheduleRid?: string;
    doorName?: string;
};
export type BulkCreateDoorsRequest = {
    facilityRid?: string;
    requests?: {
        facilityRid?: string;
        unitRid?: string;
        doorAlarmScheduleRid?: string;
        doorName?: string;
    }[];
};
export type Feature = {
    id?: string;
    featureName?: string;
    enabledFacilityIds?: RidArray;
};
export type FeaturesResponse = {
    features?: Feature[];
};
export type CreateFeatureRequest = {
    /** A Title Cased user-friendly name for the feature (e.g., "New Feature"). This is used to generate the feature ID. The feature name cannot be updated after it is created. */
    featureName: string;
    /** The list of facility RIDs that the feature should be enabled for. These RIDs will be added to the enabledFacilityIds list. */
    enabledFacilityIds?: RidArray;
    /** The list of facility RIDs that the feature should be enabled for. These RIDs will be removed from the enabledFacilityIds list. */
    disabledFacilityIds?: RidArray;
};
export type UpdateFeatureRequest = {
    /** The list of facility RIDs that the feature should be enabled for. These RIDs will be added to the enabledFacilityIds list. */
    enabledFacilityIds?: RidArray;
    /** The list of facility RIDs that the feature should be enabled for. These RIDs will be removed from the enabledFacilityIds list. */
    disabledFacilityIds?: RidArray;
};
export type FacilityNotificationContext = {
    facilityRid?: string;
    emergencyNotificationNumber?: string;
    taskNotificationNumber?: string;
    messageDeliveryMethods?: MessageDeliveryMethods;
};
export type NotificationContext = {
    eligibleRecipients?: {
        phoneNumber?: string;
        recipientId?: string;
        subscriptions?: {
            recipientId: string;
            keys?: {
                /** The public key used for encryption. */
                p256dh?: string;
                /** The authentication secret used for encryption. */
                auth?: string;
            };
            /** The URL identifying the push endpoint. */
            endpoint: string;
            expirationTime?: string;
            rid?: string;
        }[];
    }[];
    ineligibleRecipients?: {
        recipientId: string;
        reason: string;
    }[];
    facilityNotificationContext?: FacilityNotificationContext;
};
export type GetNotificationContextRequest = {
    recipientIds: string[];
    recipientType: string;
    textDeliveryStrategy?: string;
    facilityRid: string;
};
export type SimulateDeviceEventRequest =
    | {
          buttonEvent: {
              nodeBroadcast?: {
                  bdaddr?: string;
                  nodeId?: string;
                  rssi?: number;
                  requestEpochTimeInMs?: number;
                  broadcastReceivedEpochTimeInMs?: number;
                  facilityRid?: string;
                  enterpriseRid?: string;
              };
              buttonSerialNumber?: string;
              eventType?: string;
          };
          doorAlarmEvent?: {
              devEui?: string;
              gatewayRid?: string;
              facilityRid?: string;
              enterpriseRid?: string;
              gatewayName?: string;
              gatewayLocation?: string;
              gatewayDescription?: string;
              sqsApproximateReceiveCount?: number;
              sqsSentEpochTimeInMs?: number;
              sqsFirstReceiveEpochTimeInMs?: number;
              gatewayReceivedEpochTimeInSec?: number;
              serviceReceivedEpochTimeInMs?: number;
              rssi?: number;
              loraSnr?: number;
              frameCount?: number;
              framePort?: number;
              frequency?: number;
              adaptiveDataRate?: boolean;
              altitude?: number;
              latitude?: number;
              longitude?: number;
              data?: string;
              deviceType?: string;
              ridType?: string;
              batteryLevel?: number;
          } & {
              isDoorOpen?: boolean;
              doorEventCount?: number;
          };
          fallDetectionEvent?: {
              hardwareId?: string;
              /** Device type as defined by the manufacturer, NOT a Sage device entity type */
              deviceType?: string;
              enterpriseRid?: string;
              facilityRid?: string;
              unitRid?: string;
              ridType?: string;
          };
      }
    | {
          buttonEvent?: {
              nodeBroadcast?: {
                  bdaddr?: string;
                  nodeId?: string;
                  rssi?: number;
                  requestEpochTimeInMs?: number;
                  broadcastReceivedEpochTimeInMs?: number;
                  facilityRid?: string;
                  enterpriseRid?: string;
              };
              buttonSerialNumber?: string;
              eventType?: string;
          };
          doorAlarmEvent: {
              devEui?: string;
              gatewayRid?: string;
              facilityRid?: string;
              enterpriseRid?: string;
              gatewayName?: string;
              gatewayLocation?: string;
              gatewayDescription?: string;
              sqsApproximateReceiveCount?: number;
              sqsSentEpochTimeInMs?: number;
              sqsFirstReceiveEpochTimeInMs?: number;
              gatewayReceivedEpochTimeInSec?: number;
              serviceReceivedEpochTimeInMs?: number;
              rssi?: number;
              loraSnr?: number;
              frameCount?: number;
              framePort?: number;
              frequency?: number;
              adaptiveDataRate?: boolean;
              altitude?: number;
              latitude?: number;
              longitude?: number;
              data?: string;
              deviceType?: string;
              ridType?: string;
              batteryLevel?: number;
          } & {
              isDoorOpen?: boolean;
              doorEventCount?: number;
          };
          fallDetectionEvent?: {
              hardwareId?: string;
              /** Device type as defined by the manufacturer, NOT a Sage device entity type */
              deviceType?: string;
              enterpriseRid?: string;
              facilityRid?: string;
              unitRid?: string;
              ridType?: string;
          };
      }
    | {
          buttonEvent?: {
              nodeBroadcast?: {
                  bdaddr?: string;
                  nodeId?: string;
                  rssi?: number;
                  requestEpochTimeInMs?: number;
                  broadcastReceivedEpochTimeInMs?: number;
                  facilityRid?: string;
                  enterpriseRid?: string;
              };
              buttonSerialNumber?: string;
              eventType?: string;
          };
          doorAlarmEvent?: {
              devEui?: string;
              gatewayRid?: string;
              facilityRid?: string;
              enterpriseRid?: string;
              gatewayName?: string;
              gatewayLocation?: string;
              gatewayDescription?: string;
              sqsApproximateReceiveCount?: number;
              sqsSentEpochTimeInMs?: number;
              sqsFirstReceiveEpochTimeInMs?: number;
              gatewayReceivedEpochTimeInSec?: number;
              serviceReceivedEpochTimeInMs?: number;
              rssi?: number;
              loraSnr?: number;
              frameCount?: number;
              framePort?: number;
              frequency?: number;
              adaptiveDataRate?: boolean;
              altitude?: number;
              latitude?: number;
              longitude?: number;
              data?: string;
              deviceType?: string;
              ridType?: string;
              batteryLevel?: number;
          } & {
              isDoorOpen?: boolean;
              doorEventCount?: number;
          };
          fallDetectionEvent: {
              hardwareId?: string;
              /** Device type as defined by the manufacturer, NOT a Sage device entity type */
              deviceType?: string;
              enterpriseRid?: string;
              facilityRid?: string;
              unitRid?: string;
              ridType?: string;
          };
      };
export const {
    useGetCareEventQuery,
    useLazyGetCareEventQuery,
    useAddCareEventNoteMutation,
    useCreateResidentMutation,
    useGetResidentsQuery,
    useLazyGetResidentsQuery,
    useCreateResidentsMutation,
    useBulkSetRoutineCheckStatusMutation,
    useGetUnitsQuery,
    useLazyGetUnitsQuery,
    useCreateUnitMutation,
    useCreateUnitsMutation,
    useGetCheckInsQuery,
    useLazyGetCheckInsQuery,
    useCreateCheckInMutation,
    useUpdateCheckInMutation,
    useGetCheckInQuery,
    useLazyGetCheckInQuery,
    useCreateStaffMemberMutation,
    useGetStaffMembersQuery,
    useLazyGetStaffMembersQuery,
    useUpdateStaffMemberPermissionsMutation,
    useCreateStaffMembersMutation,
    useGetContactsOfResidentQuery,
    useLazyGetContactsOfResidentQuery,
    useCreateContactForResidentMutation,
    useGetResidentActivitiesQuery,
    useLazyGetResidentActivitiesQuery,
    useGetEnterprisesQuery,
    useLazyGetEnterprisesQuery,
    useCreateEnterpriseMutation,
    useGetFacilitiesQuery,
    useLazyGetFacilitiesQuery,
    useCreateFacilityMutation,
    useCreateTaskMutation,
    useGetGroupsQuery,
    useLazyGetGroupsQuery,
    useCreateGroupMutation,
    useGetEnterpriseQuery,
    useLazyGetEnterpriseQuery,
    useUpdateEnterpriseMutation,
    useDeleteEnterpriseMutation,
    useGetFacilityQuery,
    useLazyGetFacilityQuery,
    useUpdateFacilityMutation,
    useDeleteFacilityMutation,
    useUpdateFacilityStatusMutation,
    useGetGroupQuery,
    useLazyGetGroupQuery,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useGetResidentQuery,
    useLazyGetResidentQuery,
    useUpdateResidentMutation,
    useDeleteResidentMutation,
    useGetLatestCareActivityQuery,
    useLazyGetLatestCareActivityQuery,
    useGetContactOfResidentQuery,
    useLazyGetContactOfResidentQuery,
    useUpdateContactMutation,
    useDeleteContactMutation,
    useGetStaffMemberQuery,
    useLazyGetStaffMemberQuery,
    useUpdateStaffMemberMutation,
    useDeleteStaffMemberMutation,
    useGetTaskQuery,
    useLazyGetTaskQuery,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
    useResolveTaskMutation,
    useGetCareConfirmationTrackerQuery,
    useLazyGetCareConfirmationTrackerQuery,
    useResolveAllAlertsMutation,
    useGetUnitQuery,
    useLazyGetUnitQuery,
    useUpdateUnitMutation,
    useDeleteUnitMutation,
    useCreateUpdateForTaskMutation,
    useDeleteUpdateMutation,
    useGetAllocatedDevicesQuery,
    useLazyGetAllocatedDevicesQuery,
    useAllocateDeviceMutation,
    useSearchLatestAllocatedDeviceActivityMutation,
    useGetDeviceAllocationQuery,
    useLazyGetDeviceAllocationQuery,
    useUpdateDeviceAllocationMutation,
    useDeallocateDeviceMutation,
    useGetDevicesQuery,
    useLazyGetDevicesQuery,
    useCreateDeviceMutation,
    useGetDeviceQuery,
    useLazyGetDeviceQuery,
    useDeleteDeviceMutation,
    useGetOnCallStateQuery,
    useLazyGetOnCallStateQuery,
    useUpdateOnCallStateMutation,
    useGetMembershipDetailsQuery,
    useLazyGetMembershipDetailsQuery,
    useUpdateGroupMembershipMutation,
    useRemoveMemberFromGroupMutation,
    useGetApplicationConfigurationQuery,
    useLazyGetApplicationConfigurationQuery,
    useResolveNumbersQuery,
    useLazyResolveNumbersQuery,
    useCreateNoteForTaskMutation,
    useDeleteTaskNoteMutation,
    useUpdateTaskNoteMutation,
    useUnassignTaskMutation,
    useUpdateResidentArchiveStatusMutation,
    useUpdateStaffMemberArchiveStatusMutation,
    useGetStaffMemberFacilityMetadataQuery,
    useLazyGetStaffMemberFacilityMetadataQuery,
    useDeleteStaffMemberFromFacilitiesMutation,
    useGetImageForResourceQuery,
    useLazyGetImageForResourceQuery,
    useUploadImageForResourceMutation,
    useDeleteImageForResourceMutation,
    useSetPcpForResidentMutation,
    useDeletePcpForResidentMutation,
    useGetPcpForResidentQuery,
    useLazyGetPcpForResidentQuery,
    useGetSpecialistsForResidentQuery,
    useLazyGetSpecialistsForResidentQuery,
    useAddSpecialistForResidentMutation,
    useUpdateSpecialistForResidentMutation,
    useDeleteSpecialistForResidentMutation,
    useUnallocateDeviceMutation,
    useBulkUpdateDeviceAllocationsMutation,
    useTaskSearchQuery,
    useLazyTaskSearchQuery,
    useSetResidentHealthInfoMutation,
    useDeleteResidentHealthInfoMutation,
    useCreateTaskScheduleMutation,
    useGetTaskScheduleQuery,
    useLazyGetTaskScheduleQuery,
    useUpdateTaskScheduleMutation,
    useDeleteTaskScheduleMutation,
    useTaskScheduleSearchQuery,
    useLazyTaskScheduleSearchQuery,
    useGetMyTasksMutation,
    useUnassignTaskScheduleMutation,
    useAddDeviceActivityMutation,
    useGetLatestDeviceActivityQuery,
    useLazyGetLatestDeviceActivityQuery,
    useCreateResidentActivityMutation,
    useUnsetTaskScheduleDurationMutation,
    useGetCompanyPhonesQuery,
    useLazyGetCompanyPhonesQuery,
    useCreateCompanyPhoneMutation,
    useGetCompanyPhoneQuery,
    useLazyGetCompanyPhoneQuery,
    useUpdateCompanyPhoneMutation,
    useDeleteCompanyPhoneMutation,
    useCreateCompanyPhonesMutation,
    useGetHospitalForResidentQuery,
    useLazyGetHospitalForResidentQuery,
    useSetHospitalForResidentMutation,
    useDeleteHospitalForResidentMutation,
    useGetCareZoneQuery,
    useLazyGetCareZoneQuery,
    useUpdateCareZoneMutation,
    useDeleteCareZoneMutation,
    useGetAllCareZonesQuery,
    useLazyGetAllCareZonesQuery,
    useCreateCareZoneMutation,
    useGetOnCallStaffMembersQuery,
    useLazyGetOnCallStaffMembersQuery,
    useUnitsSearchQuery,
    useLazyUnitsSearchQuery,
    useCheckInSearchQuery,
    useLazyCheckInSearchQuery,
    useCreateStaffFacilityPreferencesMutation,
    useGetStaffFacilityPreferencesQuery,
    useLazyGetStaffFacilityPreferencesQuery,
    useUpdateStaffFacilityPreferencesMutation,
    useDeleteStaffFacilityPreferencesMutation,
    useSearchStaffFacilityPreferencesMutation,
    useCreateStaffMemberFacilityConfigMutation,
    useGetStaffMemberFacilityConfigQuery,
    useLazyGetStaffMemberFacilityConfigQuery,
    useUpdateStaffMemberFacilityConfigMutation,
    useDeleteStaffMemberFacilityConfigMutation,
    useSearchStaffMemberFacilityConfigsMutation,
    useStaffProfileSearchQuery,
    useLazyStaffProfileSearchQuery,
    useGetUserStaffMemberProfilesQuery,
    useLazyGetUserStaffMemberProfilesQuery,
    useCreateDoorMutation,
    useCreateDoorsMutation,
    useGetDoorQuery,
    useLazyGetDoorQuery,
    useUpdateDoorMutation,
    useDeleteDoorMutation,
    useCreateOrUpdateDoorAlarmScheduleMutation,
    useGetDoorAlarmScheduleQuery,
    useLazyGetDoorAlarmScheduleQuery,
    useDeleteDoorAlarmScheduleMutation,
    useCreateOrUpdateFacilityDefaultDoorAlarmScheduleMutation,
    useGetFacilityDefaultDoorAlarmScheduleQuery,
    useLazyGetFacilityDefaultDoorAlarmScheduleQuery,
    useDeleteFacilityDefaultDoorAlarmScheduleMutation,
    useSearchDoorsQuery,
    useLazySearchDoorsQuery,
    useSearchDoorAlarmSchedulesQuery,
    useLazySearchDoorAlarmSchedulesQuery,
    useGetAllFeaturesQuery,
    useLazyGetAllFeaturesQuery,
    useCreateFeatureMutation,
    useGetFeatureQuery,
    useLazyGetFeatureQuery,
    useUpdateFeatureMutation,
    useDeleteFeatureMutation,
    useGetNotificationContextMutation,
    useSimulateDeviceEventMutation,
} = injectedRtkApi;
