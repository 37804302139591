import { Auth0Error } from 'auth0-js';
import { useContext, useEffect, useState } from 'react';
import OtpInput from 'react18-input-otp';

import { datadogRum } from '@datadog/browser-rum';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DetailsPageHeader from '../fragments/DetailsPageHeader';
import webAuth from './AuthConfigManager';
import AuthHeader from './AuthHeader';
import { AnalyticsContext } from 'src/AnalyticsContext';

import '../../assets/styles/containerStyles.scss';

const DefaultCountdown = 30;
const PageName = 'Code Verification Page';

interface ConfirmLoginCodeProps {
    phoneNumber: string;
    onBack: () => void;
}

export default function ConfirmLoginCode(props: ConfirmLoginCodeProps) {
    const { phoneNumber, onBack } = props;
    const { trackEvent, trackPageView } = useContext(AnalyticsContext);
    const theme = useTheme();

    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(DefaultCountdown);
    const [isVerifyingCode, setIsVerifyingCode] = useState(false);
    const [requiresNewCode, setRequiresNewCode] = useState(false);
    const [authError, setAuthError] = useState<Auth0Error>();

    const handleOnOtpChange = (newOtp: string) => {
        if (requiresNewCode) {
            setRequiresNewCode(false);
        }

        setOtp(newOtp);
    };

    const isSubmitButtonDisabled = otp?.length < 6 || requiresNewCode || isVerifyingCode;
    const showErrorStyles = !!(authError && requiresNewCode);

    // countdown effect
    useEffect(() => {
        const countdownTimer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);
        return () => {
            clearInterval(countdownTimer);
        };
    }, [seconds]);

    useEffect(() => {
        // make sure we are scrolled to the top with mobile Safari's collapsing URL bar
        window.scrollTo(0, 0);

        trackPageView(PageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResendCode = () => {
        setAuthError(undefined);
        setOtp('');
        setSeconds(DefaultCountdown);

        trackEvent('Resend Code Clicked');
        webAuth.passwordlessStart({ connection: 'sms', send: 'code', phoneNumber }, (error, _) => {
            if (error) {
                setAuthError(error);
                datadogRum.addError(error, { message: 'Error resending code' });
            } else {
                setOtp('');
                setSeconds(DefaultCountdown);
            }
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        trackEvent('Continue Button Clicked', { context: PageName });
        setIsVerifyingCode(true);

        /**
         * On success, this will redirect to the /auth_confirmed page
         */
        webAuth.passwordlessLogin(
            { connection: 'sms', phoneNumber, verificationCode: otp },
            (err, _) => {
                if (err) {
                    setAuthError(err);
                    setRequiresNewCode(true);
                    setOtp('');
                    window.scrollTo({ top: 0 });
                } else {
                    setRequiresNewCode(false);
                    setAuthError(undefined);
                }
                setIsVerifyingCode(false);
            }
        );
    };

    return (
        <>
            <div className="authHeader">
                <DetailsPageHeader
                    analyticsPrefix={PageName}
                    pageTitle="Verify your identity"
                    onBack={onBack}
                />
                <AuthHeader state={authError?.code} />
            </div>
            <div className="contentContainer">
                <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Enter Verification Code
                </Typography>
                <Typography variant="body2" sx={{ mb: 3 }}>
                    Enter the 6 digit verification code sent to
                    <br />
                    (•••) ••• &nbsp;
                    {phoneNumber.slice(8, 12)}
                </Typography>

                <Typography variant="body2">
                    Not your number? Tap the back arrow above to provide a different one.
                </Typography>

                <form onSubmit={handleSubmit}>
                    <OtpInput
                        ariaLabelOverride="Enter verification code"
                        autoComplete="one-time-code"
                        className="authOTPEntry"
                        errorStyle={{ borderColor: theme.palette.error.main }}
                        hasErrored={showErrorStyles}
                        inputStyle="formOTPInput"
                        isInputNum
                        numInputs={6}
                        onChange={handleOnOtpChange}
                        placeholder="000000"
                        shouldAutoFocus
                        value={otp}
                    />
                    <Box sx={{ mt: 3, fontWeight: '400' }}>
                        <Button
                            disableElevation
                            disabled={isSubmitButtonDisabled}
                            fullWidth
                            loading={isVerifyingCode}
                            type="submit"
                            variant="contained"
                        >
                            Continue
                        </Button>
                    </Box>
                </form>

                <Box sx={{ mt: 4 }}>
                    <Typography variant="body1">Didn&apos;t receive a code?&nbsp;</Typography>
                    {seconds === 0 ? (
                        <Typography
                            variant="body1"
                            onClick={handleResendCode}
                            sx={{ fontWeight: 'bold', color: 'primary.main' }}
                        >
                            Resend the code
                        </Typography>
                    ) : (
                        <Typography>
                            You can resend it in{' '}
                            <strong>00:{seconds < 10 ? `0${seconds}` : seconds}</strong>
                        </Typography>
                    )}
                </Box>
            </div>
        </>
    );
}
