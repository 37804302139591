import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Chip, ChipProps, Skeleton } from '@mui/material';

import { selectRoutineCheckInterval } from 'src/store/configSlice';
import { formatTimeDurationFromMinutes } from 'src/utils/timeUtils';

interface RoutineCheckChipProps {
    isLoading?: boolean;
    minSinceLastActivity: number;
    showPrefix?: boolean;
    warningThresholdMin?: number;
}

function RoutineCheckChip(props: RoutineCheckChipProps) {
    const { isLoading, minSinceLastActivity, showPrefix = false, warningThresholdMin = 25 } = props;

    const routineCheckInterval = useSelector(selectRoutineCheckInterval);

    const {
        label,
        color,
        variant,
    }: {
        label: string;
        color: ChipProps['color'];
        variant: ChipProps['variant'];
    } = useMemo(() => {
        if (minSinceLastActivity >= routineCheckInterval) {
            return {
                label: `${
                    showPrefix ? 'Routine check overdue' : 'Overdue'
                } by ${formatTimeDurationFromMinutes(minSinceLastActivity - routineCheckInterval)}`,
                color: 'error',
                variant: 'filled',
            };
        } else {
            const timeUntilDue = routineCheckInterval - minSinceLastActivity;
            return {
                label: `${
                    showPrefix ? 'Routine check due' : 'Due'
                } in ${formatTimeDurationFromMinutes(timeUntilDue)}`,
                color: timeUntilDue <= warningThresholdMin ? 'warning' : 'info',
                variant: timeUntilDue <= warningThresholdMin ? 'filled' : 'outlined',
            };
        }
    }, [minSinceLastActivity, routineCheckInterval, showPrefix, warningThresholdMin]);

    return isLoading ? (
        <Skeleton
            variant="rounded"
            animation="wave"
            width={100}
            height={24}
            sx={{ borderRadius: 3 }}
        />
    ) : (
        <Chip
            label={label}
            color={color}
            variant={variant}
            // Overwrite the info + outlined font color to black for better contrast
            sx={{ color: color === 'info' && variant === 'outlined' ? 'black' : undefined }}
        />
    );
}

export default RoutineCheckChip;
