import { createSlice } from '@reduxjs/toolkit';

import { Task } from './sageAdminApi';
import { TaskStatuses } from 'src/constants/TaskStatuses';

type TrainingState = {
    trainingAlert: Task | undefined;
};

export const initialState: TrainingState = {
    trainingAlert: undefined,
};

export const TrainingSlice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        setTrainingAlert: (state, { payload }) => {
            state.trainingAlert = payload;
        },
        cancelConfirmation: (state) => {
            if (state.trainingAlert) {
                state.trainingAlert.status = TaskStatuses.OpenClaimed.id;
            }
        },
    },
    selectors: {
        selectTrainingAlert: (sliceState: TrainingState) => sliceState.trainingAlert,
    },
});

// Action creators are generated for each case reducer function
export const { setTrainingAlert, cancelConfirmation } = TrainingSlice.actions;

export const { selectTrainingAlert } = TrainingSlice.selectors;

export default TrainingSlice.reducer;
