export const TaskUpdateTypes = {
    Created: 'CREATED',
    Closed: 'CLOSED',
    Reopened: 'REOPENED',
    Assignment: 'ASSIGNMENT',
    Reassignment: 'REASSIGNMENT',
    Unassignment: 'UNASSIGNMENT',
    StatusChange: 'STATUS_CHANGE',
    SeverityChange: 'SEVERITY_CHANGE',
    DeviceInteraction: 'DEVICE_INTERACTION',
    DeviceInteractionDoorOpen: 'DEVICE_INTERACTION_DOOR_OPEN',
    DeviceInteractionDoorClosed: 'DEVICE_INTERACTION_DOOR_CLOSED',
    DeviceInteractionFallDetected: 'DEVICE_INTERACTION_FALL_DETECTED',
    ManualEntry: 'MANUAL_ENTRY',
    Claim: 'CLAIM',
    CareConfirmed: 'CARE_CONFIRMED',
    CareConfirmationFailed: 'CARE_CONFIRMATION_FAILED',
};
