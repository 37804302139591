import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography } from '@mui/material';

import { AnalyticsContext } from 'src/AnalyticsContext';

import './styles/Snackbar.styles.scss';

const EmergencyAlert = (props) => {
    const { id, message, taskId } = props;
    const { trackEvent } = useContext(AnalyticsContext);

    const { closeSnackbar } = useSnackbar();
    const handleClickAlert = () => {
        trackEvent('emergency-alert-notification.tap');
        closeSnackbar(id);
    };

    return (
        <Link
            to={`/alerts/alert/${taskId}`}
            onClick={handleClickAlert}
            className="snackbar snackbar--alert"
        >
            <Stack
                direction="row"
                sx={{
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        color: 'white.main',
                        flexGrow: 1,
                    }}
                >
                    {message}
                </Typography>
                <FontAwesomeIcon icon={faArrowRight} />
            </Stack>
        </Link>
    );
};

export default EmergencyAlert;
