import clsx from 'clsx';
import { isValidElement } from 'react';

import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
    Box,
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormGroupProps,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import './styles/GroupOptions.styles.scss';

export const AllOptions = 'All Options';

export const Variants = {
    Default: 'default',
    Card: 'card',
    Outlined: 'outlined',
};

export type VariantTypes = (typeof Variants)[keyof typeof Variants];

export interface Option {
    label: string | import('react').ReactNode | ((props?: unknown) => JSX.Element);
    value: string;
    required?: boolean;
    disabled?: boolean;
}

interface CheckboxGroupOptionsProps {
    options: Option[];
    onSelectOption: (optionId: string, isChecked: boolean, index?: number) => void;
    selectedOptions?: Record<string, boolean>;
    variant?: VariantTypes;
    formGroupProps?: FormGroupProps;
    checkboxProps?: CheckboxProps;
    showBulkSelectOption?: boolean;
}

const CheckboxSizeToFontAwesomeSize: Record<
    NonNullable<CheckboxProps['size']>,
    FontAwesomeIconProps['size']
> = {
    small: 'lg',
    medium: 'xl',
    large: '2x',
};

/**
 * This component is used to render a group of checkboxes with options. It does not
 * manage the state of the selected options, but rather provides a callback to the
 * parent component to handle the state.
 *
 * Example:
 * ```tsx
 * const options = [
 *     { value: 'option-1', label: 'Option 1' },
 *     { value: 'option-2', label: 'Option 2' },
 *     { value: 'option-3', label: 'Option 3' },
 * ];
 *
 * const [selectedOptions, setSelectedOptions] = useState({});
 * const onSelectOption = (optionId, isChecked) => {
 *     setSelectedOptions((prevSelectedOptions) => ({
 *        ...prevSelectedOptions,
 *        [optionId]: isChecked,
 *      }));
 * };
 *
 * return (
 *     <CheckboxGroupOptions
 *         options={options}
 *         onSelectOption={onSelectOption}
 *         selectedOptions={selectedOptions}
 *         variant={Variants.Outlined}
 *         formGroupProps={{
 *             'aria-label': 'Checkbox Group Options',
 *         }}
 *     />
 * );
 * ```
 */

function CheckboxGroupOptions(props: CheckboxGroupOptionsProps) {
    const {
        options = [],
        onSelectOption = () => {},
        selectedOptions = {},
        variant = Variants.Default,
        formGroupProps = {},
        checkboxProps = {},
        showBulkSelectOption = false,
    } = props;

    const { palette } = useTheme();

    const checkboxColor =
        checkboxProps.color && checkboxProps.color !== 'default'
            ? palette[checkboxProps.color]?.main
            : palette.primary.main;

    const areAllOptionsSelected = options.every((option) => selectedOptions[option.value]);

    return (
        <FormControl fullWidth>
            <FormGroup {...formGroupProps} className="group-container">
                {showBulkSelectOption && (
                    <Box
                        className={clsx(
                            'group-option-item',
                            `group-option-item__${variant}`,
                            areAllOptionsSelected && 'selected'
                        )}
                    >
                        <FormControlLabel
                            value={AllOptions}
                            control={
                                <Checkbox
                                    color="primary"
                                    checkedIcon={
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size={
                                                CheckboxSizeToFontAwesomeSize[
                                                    checkboxProps.size || 'medium'
                                                ]
                                            }
                                            color={checkboxColor}
                                        />
                                    }
                                    {...checkboxProps}
                                    checked={areAllOptionsSelected}
                                    onChange={(event) =>
                                        onSelectOption(AllOptions, event.target.checked)
                                    }
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faSquare}
                                            size={
                                                CheckboxSizeToFontAwesomeSize[
                                                    checkboxProps.size || 'medium'
                                                ]
                                            }
                                            color={checkboxColor}
                                        />
                                    }
                                />
                            }
                            label={
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {`${areAllOptionsSelected ? 'Deselect' : 'Select'} All (${
                                        options.length
                                    })`}
                                </Typography>
                            }
                            sx={{
                                width: '100%',
                                mx: 0,
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                gap: 2,
                            }}
                        />
                    </Box>
                )}
                {options.map((option, index) => (
                    <Box
                        key={option.value}
                        className={clsx(
                            'group-option-item',
                            `group-option-item__${variant}`,
                            !!selectedOptions[option.value] && !option.disabled && 'selected',
                            option.disabled && 'disabled'
                        )}
                    >
                        <FormControlLabel
                            disabled={option.disabled}
                            required={option.required}
                            value={option.value}
                            control={
                                <Checkbox
                                    color="primary"
                                    checkedIcon={
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size={
                                                CheckboxSizeToFontAwesomeSize[
                                                    checkboxProps.size || 'medium'
                                                ]
                                            }
                                            color={option.disabled ? 'purple.100' : checkboxColor}
                                        />
                                    }
                                    {...checkboxProps}
                                    checked={!!selectedOptions[option.value]}
                                    onChange={(event) =>
                                        onSelectOption(option.value, event.target.checked, index)
                                    }
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faSquare}
                                            size={
                                                CheckboxSizeToFontAwesomeSize[
                                                    checkboxProps.size || 'medium'
                                                ]
                                            }
                                            color={option.disabled ? 'purple.100' : checkboxColor}
                                        />
                                    }
                                />
                            }
                            label={
                                isValidElement(option.label)
                                    ? option.label
                                    : typeof option.label === 'function'
                                    ? option.label(option)
                                    : option.label
                            }
                            sx={{
                                width: '100%',
                                mx: 0,
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                gap: 2,
                            }}
                        />
                    </Box>
                ))}
            </FormGroup>
        </FormControl>
    );
}

export default CheckboxGroupOptions;
