import { useContext, useMemo, useState } from 'react';

import { Box, Button, Typography, Dialog, DialogContent, Stack } from '@mui/material';
import { Switch } from '@sage/ui-components';

import RadioGroupOptions from '../fragments/RadioGroupOptions';
import Resident from '../fragments/Resident';
import SearchInput from '../fragments/SearchInput';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import PendantButtonIcon from 'src/assets/images/device-icons/pendant-button-icon.svg?react';

import '../../assets/styles/buttonStyles.scss';

const DrawerName = 'Select Resident With Buttons Drawer';

interface SelectResidentsWithButtonsDrawerProps extends DrawerComponentProps {
    residents: any[];
    preSelectedResidentId?: string;
}

function SelectResidentsWithButtonsDrawer(props: SelectResidentsWithButtonsDrawerProps) {
    const { residents, preSelectedResidentId, onNext, title = 'Select Resident' } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const [selectedResidentId, setSelectedResidentId] = useState(preSelectedResidentId || '');
    const [searchTerm, setSearchTerm] = useState('');
    const [showResidentsWithNoButtons, setShowResidentsWithNoButtons] = useState(false);
    const [residentHasPendantDialogOpen, setResidentHasPendantDialogOpen] = useState(false);

    const filteredResidents = useMemo(() => {
        const residentsByDeviceCount = showResidentsWithNoButtons
            ? residents?.filter((resident) => !resident.hasPendant)
            : residents;

        if (!!searchTerm) {
            return residentsByDeviceCount?.filter((resident) =>
                `${resident.firstName} ${resident.lastName}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            );
        } else {
            return residentsByDeviceCount;
        }
    }, [residents, searchTerm, showResidentsWithNoButtons]);

    const selectedResident = useMemo(
        () => residents?.find((resident) => resident.id === selectedResidentId),
        [residents, selectedResidentId]
    );

    const handleResidentsFilter = () => {
        trackEvent('Toggle Clicked', {
            context: DrawerName,
            action: 'Filter residents with no buttons',
            value: showResidentsWithNoButtons,
        });
        setShowResidentsWithNoButtons(!showResidentsWithNoButtons);
    };

    const handleSelectResident = (residentId: string) => {
        trackEvent('Resident Selected', { context: DrawerName, residentId });
        setSelectedResidentId(residentId);
    };

    const handleContinue = () => {
        trackEvent('Select Resident Confirmed', {
            context: DrawerName,
            residentId: selectedResidentId,
        });
        onNext({ value: selectedResidentId });
    };

    const handleConfirmSelection = () => {
        const residentHasButton = selectedResident?.hasPendant;
        if (residentHasButton) {
            setResidentHasPendantDialogOpen(true);
        } else {
            handleContinue();
        }
    };

    return (
        <>
            {/* Fixed Header */}
            <DrawerTitle title={title}>
                <SearchInput
                    analyticsPrefix={DrawerName}
                    searchValue={searchTerm}
                    onSearchChange={setSearchTerm}
                    placeholder="Search by Resident name"
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'grey.900',
                        }}
                    >
                        Only show residents with no buttons assigned
                    </Typography>
                    <Switch
                        checked={showResidentsWithNoButtons}
                        value={showResidentsWithNoButtons}
                        onChange={handleResidentsFilter}
                        inputProps={{ 'aria-label': 'toggle residents with no buttons' }}
                    />
                </Box>
            </DrawerTitle>
            {/* Scrolling Area */}
            <DrawerBody>
                {filteredResidents?.length > 0 && (
                    <RadioGroupOptions
                        radioGroupProps={{ 'aria-label': 'residents' }}
                        selectedOption={selectedResidentId}
                        onSelectOption={handleSelectResident}
                        options={filteredResidents?.map((resident) => ({
                            value: resident.id,
                            label: (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        pr: 1,
                                    }}
                                >
                                    <Resident
                                        resident={resident}
                                        id={resident.id}
                                        image={resident.profilePictureUrl}
                                        unit={resident.unit}
                                    />

                                    {resident.hasPendant && <PendantButtonIcon width="32px" />}
                                </Box>
                            ),
                        }))}
                    />
                )}

                {filteredResidents?.length === 0 && (
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'grey.700',
                        }}
                    >
                        {!!searchTerm ? 'No residents found' : 'No residents'}
                    </Typography>
                )}
            </DrawerBody>
            {/* Bottom Sticky Buttons */}
            <DrawerFooter>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={!selectedResidentId}
                    onClick={handleConfirmSelection}
                >
                    Continue
                </Button>
            </DrawerFooter>
            <Dialog
                open={residentHasPendantDialogOpen}
                onClose={() => setResidentHasPendantDialogOpen(false)}
            >
                <DialogContent className="dialogContentContainer">
                    <Stack
                        sx={{
                            alignItems: 'center',
                            textAlign: 'center',
                            gap: 1.5,
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant="h4"
                            component="p"
                            sx={{
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                            Pendant already assigned
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="p"
                            sx={{
                                color: 'grey.900',
                                maxWidth: '250px',
                            }}
                        >
                            If the button pendant currently assigned to this resident is broken,
                            unassign the broken button before assigning a new one.
                        </Typography>
                    </Stack>

                    <Stack
                        sx={{
                            gap: 2,
                        }}
                    >
                        <Button
                            onClick={handleContinue}
                            disableElevation
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            Continue
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            fullWidth
                            onClick={() => {
                                trackEvent('Confirmation Dialog Cancelled', {
                                    context: DrawerName,
                                    action: 'Cancel',
                                });
                                setResidentHasPendantDialogOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SelectResidentsWithButtonsDrawer;
