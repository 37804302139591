import { Box, Stack, SwitchProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Switch } from '@sage/ui-components';

const StyledToggleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme?.palette?.grey?.[100],
}));

interface ToggleContainerProps {
    title:
        | string
        | {
              content: string;
              typographyProps?: TypographyProps;
          };
    subtitle?:
        | string
        | {
              content: string;
              typographyProps?: TypographyProps;
          };
    switchProps: SwitchProps;
}

function ToggleContainer(props: ToggleContainerProps) {
    const { title, subtitle, switchProps } = props;

    const titleText = typeof title === 'string' ? title : title.content;
    const subtitleText = subtitle && typeof subtitle === 'object' ? subtitle?.content : subtitle;

    const titleProps = typeof title === 'object' ? title.typographyProps : {};
    const subtitleProps = subtitle && typeof subtitle === 'object' ? subtitle.typographyProps : {};

    return (
        <StyledToggleContainer>
            <Stack
                sx={{
                    gap: 0.5,
                    flexGrow: 1,
                }}
            >
                <Typography
                    {...titleProps}
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        ...(titleProps?.sx || {}),
                    }}
                >
                    {titleText}
                </Typography>

                {subtitle && (
                    <Typography
                        {...subtitleProps}
                        variant="body2"
                        sx={{
                            color: 'grey.900',
                            ...(subtitleProps?.sx || {}),
                        }}
                    >
                        {subtitleText}
                    </Typography>
                )}
            </Stack>
            <Switch {...switchProps} />
        </StyledToggleContainer>
    );
}

export default ToggleContainer;
