export const TaskSeverityLevels = {
    Emergency: {
        id: 'EMERGENCY',
        displayName: 'Emergency',
        severity: 0,
    },
    High: {
        id: 'HIGH_PRIORITY',
        displayName: 'High priority',
        severity: 1,
    },
    Medium: {
        id: 'MEDIUM_PRIORITY',
        displayName: 'Medium priority',
        severity: 2,
    },
    Low: {
        id: 'LOW_PRIORITY',
        displayName: 'Low priority',
        severity: 3,
    },
    NoPriority: {
        id: 'NO_PRIORITY',
        displayName: 'None',
        severity: 100,
    },
};

export const TaskSeverityLevelsValues = Object.values(TaskSeverityLevels).map(({ id }) => id);
