import { sageBaseCareServiceApi as api } from './sageBaseCareServiceApi';

export const addTagTypes = ['residents', 'careEvents'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            createLoggedCare: build.mutation<CreateLoggedCareApiResponse, CreateLoggedCareApiArg>({
                query: (queryArg) => ({
                    url: `/loggedCare`,
                    method: 'POST',
                    body: queryArg.createLoggedCareRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            createCareOutcomes: build.mutation<
                CreateCareOutcomesApiResponse,
                CreateCareOutcomesApiArg
            >({
                query: (queryArg) => ({
                    url: `/careOutcomes`,
                    method: 'POST',
                    body: queryArg.createCareOutcomesRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
            }),
            searchCareOutcomes: build.mutation<
                SearchCareOutcomesApiResponse,
                SearchCareOutcomesApiArg
            >({
                query: (queryArg) => ({
                    url: `/careOutcomes/search`,
                    method: 'POST',
                    body: queryArg.searchCareOutcomesRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
            }),
            getCareEvent: build.query<GetCareEventApiResponse, GetCareEventApiArg>({
                query: (queryArg) => ({
                    url: `/careEvents/${queryArg.careEventRid}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
            }),
            updateCareEvent: build.mutation<UpdateCareEventApiResponse, UpdateCareEventApiArg>({
                query: (queryArg) => ({
                    url: `/careEvents/${queryArg.careEventRid}`,
                    method: 'PUT',
                    body: queryArg.updateCareEventRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
            }),
            addCareEventNote: build.mutation<AddCareEventNoteApiResponse, AddCareEventNoteApiArg>({
                query: (queryArg) => ({
                    url: `/careEvents/${queryArg.careEventRid}/notes`,
                    method: 'POST',
                    body: queryArg.createCareEventNoteRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['careEvents'],
            }),
            getCareEvents: build.query<GetCareEventsApiResponse, GetCareEventsApiArg>({
                query: (queryArg) => ({
                    url: `/careEvents`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        facilityRid: queryArg.facilityRid,
                        residentRids: queryArg.residentRids,
                        creatorEntityRids: queryArg.creatorEntityRids,
                        careOutcomes: queryArg.careOutcomes,
                        pageNumber: queryArg.pageNumber,
                        pageSize: queryArg.pageSize,
                    },
                }),
            }),
            createCareEvent: build.mutation<CreateCareEventApiResponse, CreateCareEventApiArg>({
                query: (queryArg) => ({
                    url: `/careEvents`,
                    method: 'POST',
                    body: queryArg.createCareEventRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
            }),
            getLatestCareEvents: build.query<
                GetLatestCareEventsApiResponse,
                GetLatestCareEventsApiArg
            >({
                query: (queryArg) => ({
                    url: `/careEvents/latest`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        residentRids: queryArg.residentRids,
                        staffMemberRids: queryArg.staffMemberRids,
                    },
                }),
            }),
            migrateTaskToCareEvent: build.mutation<
                MigrateTaskToCareEventApiResponse,
                MigrateTaskToCareEventApiArg
            >({
                query: (queryArg) => ({
                    url: `/careEvents/migrate`,
                    method: 'POST',
                    body: queryArg.body,
                    headers: { Authorization: queryArg?.authorization },
                }),
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageCareServiceApi };
export type CreateLoggedCareApiResponse = /** status 200 OK */ {
    rid?: string;
};
export type CreateLoggedCareApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createLoggedCareRequest: CreateLoggedCareRequest;
};
export type CreateCareOutcomesApiResponse = /** status 200 OK */ {
    rids?: RidResponse[];
};
export type CreateCareOutcomesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createCareOutcomesRequest: CreateCareOutcomesRequest;
};
export type SearchCareOutcomesApiResponse = /** status 200 OK */ SearchCareOutcomesResponse;
export type SearchCareOutcomesApiArg = {
    authorization?: string;
    searchCareOutcomesRequest: SearchCareOutcomesRequest;
};
export type GetCareEventApiResponse = /** status 200 OK */ GetCareEventResponse;
export type GetCareEventApiArg = {
    authorization?: string;
    careEventRid: string;
};
export type UpdateCareEventApiResponse = /** status 200 OK */ CareEvent;
export type UpdateCareEventApiArg = {
    authorization?: string;
    careEventRid: string;
    updateCareEventRequest: UpdateCareEventRequest;
};
export type AddCareEventNoteApiResponse = /** status 200 OK */ CareEventNote;
export type AddCareEventNoteApiArg = {
    authorization?: string;
    careEventRid: string;
    createCareEventNoteRequest: CreateCareEventNoteRequest;
};
export type GetCareEventsApiResponse = /** status 200 OK */ GetCareEventsResponse;
export type GetCareEventsApiArg = {
    authorization?: string;
    facilityRid?: string;
    residentRids?: string[];
    /** The rid of the creator entity that initiated the documentation of the care event.
    This includes both alertRid and plannedCareRid values.
     */
    creatorEntityRids?: string[];
    /** Care outcomes to filter by. If absent, all care outcomes will be returned. */
    careOutcomes?: OutcomeType[];
    pageNumber?: number;
    pageSize?: number;
};
export type CreateCareEventApiResponse = /** status 200 OK */ RidResponse;
export type CreateCareEventApiArg = {
    authorization?: string;
    createCareEventRequest: CreateCareEventRequest;
};
export type GetLatestCareEventsApiResponse = /** status 200 OK */ CareEvent[];
export type GetLatestCareEventsApiArg = {
    authorization?: string;
    residentRids?: string[];
    /** The rid of a staff member who responded to a care event.
    This does not have to be the creator and will use the respondingStaffRids field on the care event.
     */
    staffMemberRids?: string[];
};
export type MigrateTaskToCareEventApiResponse = /** status 200 OK */ CareEventMigrationResponse;
export type MigrateTaskToCareEventApiArg = {
    authorization?: string;
    body: {
        id?: string;
        title?: string;
        description?: string;
        status?: string;
        severity?: string;
        updates?: {
            id?: string;
            type?: string;
            creationTimestamp?: string;
            creationTimestampInMs?: string;
            description?: string;
            author?: Author;
            careProvided?: string[];
            alertOutcome?: AlertOutcome;
        }[];
        notes?: {
            id?: string;
            creationTimestamp?: string;
            lastUpdatedTimestamp?: string;
            content?: string;
            subject?: string;
            creator?: StaffMember;
            lastUpdatedBy?: StaffMember;
        }[];
        assignee?: {
            id?: string;
            firstName?: string;
            middleName?: string;
            middleInitial?: string;
            lastName?: string;
            phoneNumber?: string;
            email?: string;
            /** Address */
            address?: {
                addressLine1?: string;
                addressLine2?: string;
                city?: string;
                state?: string;
                zipcode?: string;
                country?: string;
            };
            facilities?: Facility[];
            enterprise?: Enterprise;
            jobTitle?: string;
            onCallStates?: {
                isOnCall?: boolean;
                lastModified?: string;
                facilityId?: string;
                companyPhoneId?: string;
            }[];
            shouldReceiveAllCareMessages?: boolean;
            isCareAdmin?: boolean;
            isArchived?: boolean;
            isAgencyStaff?: boolean;
            staffMemberFacilityConfigs?: {
                id?: string;
                facilityId?: string;
                staffMemberId?: string;
                careZones?: CareZone[];
                jobTitle?: string;
                isAgencyStaff?: boolean;
                isCareAdmin?: boolean;
                shouldReceiveAllCareMessages?: boolean;
            }[];
        };
        facility?: {
            id?: string;
            name?: string;
            address?: Address;
            phoneNumber?: string;
            administrator?: StaffMember;
            enterprise?: Enterprise;
            logoUrl?: string;
            emergencyNotificationNumber?: string;
            requestNotificationNumber?: string;
            taskNotificationNumber?: string;
            timezone?: string;
            careZones?: {
                id?: string;
                name?: string;
                facilityId?: string;
                /** A collection of tags used to describe a Unit. These tags will all be collected and used as search parameters, to find matching Units which may have a matching tag. */
                unitTags?: {
                    floors?: string[];
                    wings?: string[];
                    careTypes?: string[];
                    buildings?: string[];
                    wards?: string[];
                    /** Array of strings representing Rids. These will be converted to RIDs on the backend. */
                    unitRids?: string[];
                };
            }[];
            /** Delivery methods in order of preference.  Will attempt to send all messages using the first delivery methods, falling back to second delivery method and so on. */
            messageDeliveryMethods?: string[];
            /** Facility status */
            status?: 'QA' | 'PRE_LIVE' | 'LIVE';
            facilityConfig?: {
                isAlertLocationEnabled?: boolean;
                routineCheckIntervalInMin?: number;
            };
        };
        enterprise?: {
            id?: string;
            name?: string;
            address?: Address;
            phoneNumber?: string;
            administrator?: StaffMember;
            facilities?: Facility[];
            logoUrl?: string;
            notificationApplicationId?: string;
        };
        resident?: {
            id?: string;
            salutation?: string;
            firstName?: string;
            middleName?: string;
            middleInitial?: string;
            lastName?: string;
            dateOfBirth?: string;
            age?: number;
            personalPhone?: string;
            createTimestampMs?: number;
            updateTimestampMs?: number;
            email?: string;
            /** Contact of a resident */
            emergencyContact?: {
                id?: string;
                firstName?: string;
                lastName?: string;
                primaryPhone?: string;
                secondaryPhone?: string;
                email?: string;
                relationship?: string;
                isLocal?: boolean;
                preferredContactMethod?: string;
                hasPowerOfAttorney?: boolean;
                address?: Address;
                salutation?: string;
                primaryLanguage?: string;
            };
            contacts?: Contact[];
            unit?: Unit;
            primaryCarePhysician?: {
                id?: string;
                companyName?: string;
                companyDescription?: string;
                salutation?: string;
                firstName?: string;
                lastName?: string;
                officePhoneNumber?: string;
                personalPhoneNumber?: string;
                email?: string;
                officeFax?: string;
            };
            specialists?: ProfessionalContact[];
            notes?: Note[];
            facility?: Facility;
            enterprise?: Enterprise;
            isRoutineCheckEnabled?: boolean;
            isOnHold?: boolean;
            isArchived?: boolean;
            archiveReason?: string;
            /** Resident health information */
            healthInfo?: {
                /** Resident memory health information */
                memoryHealthInfo?: {
                    memoryLevel?: string;
                };
                /** Resident continence health information */
                continenceHealthInfo?: {
                    continenceLevel?: string;
                    productProvider?: string;
                    wearsPads?: boolean;
                    wearsPullups?: boolean;
                    wearsBriefs?: boolean;
                };
                /** Resident's activities of daily living information */
                adlHealthInfo?: {
                    safetyLevel?: string;
                    feedingLevel?: string;
                    bathingLevel?: string;
                    toiletingLevel?: string;
                    transferringLevel?: string;
                    mobilityLevel?: string;
                    dressingLevel?: string;
                    groomingLevel?: string;
                };
                resuscitationStrategy?: string;
                /** Resident hospice health information */
                hospiceHealthInfo?: {
                    hospiceStatus: string;
                };
            };
            preferredHospital?: {
                id?: string;
                name?: string;
                phoneNumber?: string;
                faxNumber?: string;
                address?: Address;
            };
        };
        /** A residential unit */
        unit?: {
            id?: string;
            currentOccupants?: Resident[];
            devices?: DeviceAllocation[];
            phoneNumber?: string;
            displayName?: string;
            facility?: Facility;
            enterprise?: Enterprise;
            floor?: string;
            wing?: string;
            careType?: string;
            building?: string;
            ward?: string;
        };
        door?: {
            rid: string;
            facilityRid: string;
            name?: string;
            doorAlarmSchedule: {
                rid?: string;
                /** If left blank, it is assumed that this a default, facility wide schedule. */
                doorRid?: string;
                facilityRid: string;
                alwaysAlert: boolean;
                neverAlert: boolean;
                /** The object represents a union set of excluded time ranges. Because it is a union set, it is possible to have overlapping time ranges, which will not affect the evaluation of the schedule. In other words, if the current time matches any of the given time ranges, then the schedule is inside it's exclusion window. */
                dailySchedules?: {
                    /** A map where the key is a day of the week, and the value is a list of time ranges. It accepts All as a wildcard. */
                    dayOfWeekTimeRangeMap?: {
                        [key: string]: TimeRangeArray;
                    };
                    dayOfMonthTimeRangeMap?: {
                        [key: string]: TimeRangeArray;
                    };
                };
                /** An inverted representation of the current schedule for the day. It represents the times that a door alarm will fire on a given day. */
                currentDayDisplaySchedules?: string;
                holidaySchedules?: {
                    [key: string]: {
                        startTime?: string;
                        endTime?: string;
                    }[];
                };
            };
            unit: Unit;
            deviceAllocation?: {
                room?: string;
                device?: Device;
                id?: string;
                door?: Door;
                unit?: Unit;
                facility?: Facility;
                enterprise?: Enterprise;
                displayName?: string;
                resident?: Resident;
                isCheckIn?: boolean;
                isSilent?: boolean;
            };
        };
        /** A union type object to allow for content authors to be one of resident, staffMember or device. */
        creator?: {
            resident?: Resident;
            staffMember?: StaffMember;
            device?: {
                id?: string;
                type?: string;
                connectivityStatus?: string;
                activationStatus?: string;
                hardwareId?: string;
                creationTimestamp?: string;
                notes?: Note[];
                lastPressTimestamp?: string;
            };
            schedule?: TaskSchedule;
        };
        group?: {
            id?: string;
            name?: string;
            members?: StaffMember[];
            numberOfMembers?: number;
            iconUrl?: string;
            facility?: Facility;
            enterprise?: Enterprise;
        };
        schedule?: {
            id?: string;
            taskTemplate?: {
                title?: string;
                description?: string;
                severity?: string;
                assignee?: StaffMember;
                group?: Group;
                resident?: Resident;
                unit?: Unit;
            };
            scheduleRecurrenceDefinition?: {
                cronScheduleExpression?: string;
                futureExecutionTimeInMs?: number;
                durationInMinutes?: number;
            };
            creator?: StaffMember;
            lastUpdatedBy?: StaffMember;
            facility?: Facility;
            enterprise?: Enterprise;
            creationTimestamp?: string;
            lastUpdateTimestamp?: string;
            isRecurring?: boolean;
            hasRunOnce?: boolean;
            lastSuccessfulExecutionTimeInMs?: number;
        };
        dueDateAndTime?: string;
        isRecurring?: boolean;
        creationTimestamp?: string;
        lastUpdateTimestamp?: string;
        careProvided?: string[];
        alertOutcome?: {
            rid?: string;
            /** categorizes the resolution of an alert */
            type?: 'RESIDENT_INTERACTION' | 'TEST_ALERT' | 'FALSE_ALARM';
            taskUpdateRid?: string;
            taskRid?: string;
        };
        careEvent?: CareEvent & {
            /** A map of staff member RIDs to staff member details */
            staffMembers?: {
                [key: string]: StaffMember;
            };
        };
    }[];
};
export type CareProvided =
    | 'EMERGENCY_FALL'
    | 'EMERGENCY_OTHER'
    | 'ADL_TOILETING'
    | 'ADL_ROUTINE_CHECK'
    | 'ADL_ESCORT'
    | 'ADL_DRESSING'
    | 'ADL_TRANSFER'
    | 'ADL_EATING'
    | 'ADL_BATHING'
    | 'ADL_GROOMING'
    | 'REQUEST_MEDTECH'
    | 'REQUEST_OXYGEN'
    | 'REQUEST_REPAIR'
    | 'REQUEST_OTHER'
    | 'HOUSEKEEPING_TRASH'
    | 'HOUSEKEEPING_BED'
    | 'HOUSEKEEPING_LAUNDRY'
    | 'HOUSEKEEPING_BATHROOM'
    | 'HOUSEKEEPING_OTHER'
    | 'NON_EMERGENCY_ACCIDENTAL_PRESS'
    | 'NON_EMERGENCY_QUESTION'
    | 'NON_EMERGENCY_ILLNESS'
    | 'NON_EMERGENCY_FOUND_DEVICE'
    | 'NON_EMERGENCY_NO_CARE_GIVEN';
export type OutcomeType = 'COMPLETED' | 'NOT_COMPLETED' | 'REFUSED';
export type CreateCareOutcome = {
    careProvided: CareProvided;
    outcome: OutcomeType;
    /** Rid of associated legacy entity. Will be depreciated after migration. */
    legacyEntityRid?: string;
};
export type CreateLoggedCareRequest = {
    staffMemberRid: string;
    residentRid: string;
    facilityRid: string;
    careOutcomes: CreateCareOutcome[];
    note?: string;
};
export type RidResponse = {
    rid?: string;
};
export type CreatorEntity = 'ALERT' | 'LOGGED_CARE';
export type CreateCareOutcomesRequest = {
    creatorEntity?: CreatorEntity;
    /** Parent entity that initiated the documentation of the care outcomes */
    creatorEntityRid?: string;
    careOutcomes: CreateCareOutcome[];
    /** Rid of the staff that documented the care outcomes */
    documentedByRid: string;
    residentRid?: string;
    facilityRid: string;
    alertRid?: string;
    plannedCareRid?: string;
};
export type CareOutcome = {
    careProvided: CareProvided;
    outcome: OutcomeType;
    creatorEntityRid?: string;
    alertRid?: string;
};
export type SearchCareOutcomesResponse = {
    careOutcomes?: CareOutcome[];
};
export type SearchCareOutcomesRequest = {
    createdByRids?: string[];
};
export type Observation = {
    /** Supported options for observation types */
    observationType:
        | 'ABNORMAL_BOWEL_MOVEMENT'
        | 'ABNORMAL_URINE'
        | 'MOOD_BEHAVIOR_ISSUE'
        | 'PAIN'
        | 'SKIN_ISSUE';
    observationValue?: string;
};
export type NoteType = 'CREATION_NOTE' | 'CAREGIVER_NOTE' | 'NURSE_ACKNOWLEDGEMENT';
export type CreateCareEventNoteRequest = {
    noteType: NoteType;
    content: string;
    creatorRid: string;
};
export type CareEventNote = CreateCareEventNoteRequest & {
    /** The timestamp in milliseconds when the note was created. */
    createTimestampMs?: number;
    /** Rid of the staff that updated the note */
    lastUpdatedByRid?: string;
    /** The timestamp in milliseconds when the note was last updated. */
    updateTimestampMs?: number;
};
export type CareEvent = {
    rid?: string;
    residentRid?: string;
    facilityRid?: string;
    alertRid?: string;
    plannedCareRid?: string;
    /** Rid of the "logged care" task that was migrated to a care event. This field is only used for migration purposes
    and will be deprecated after the care event migration is complete.
     */
    loggedCareRid?: string;
    /** Rid of the staff that documented the care event */
    creatorRid?: string;
    /** Rid of the staff that updated the care event */
    lastUpdatedByRid?: string;
    careOutcomes?: CareOutcome[];
    observations?: Observation[];
    notes?: CareEventNote[];
    /** The timestamp in milliseconds when the care event was created */
    createTimestampMs?: number;
    /** The timestamp in milliseconds when the care event was last updated. */
    updateTimestampMs?: number;
    /** A list of all staff rids that responded to the care event. */
    respondingStaffRids?: string[];
};
export type GetCareEventResponse = {
    careEvent: CareEvent;
};
export type UpdateCareEventRequest = {
    lastUpdatedByRid: string;
    careOutcomes?: CareOutcome[];
    observations?: Observation[];
    notes?: CareEventNote[];
};
export type GetCareEventsResponse = {
    hasNextPage: boolean;
    pageSize: number;
    pageNumber: number;
} & {
    careEvents: CareEvent[];
};
export type CreateCareEventRequest = CareEvent;
export type CareEventMigrationResponse = {
    /** A list of task rids that failed to migrate due to an error. */
    failedTaskRids?: string[];
    /** A list of task rids that were successfully processed, but no care event was created because
    the task did not have any care outcomes.
     */
    notMigratedToCareEvent?: string[];
    /** A map of task rids to care event rids that were successfully created.
    The key is the task rid and the value is the care event rid.
     */
    migratedToCareEvent?: {
        [key: string]: string;
    };
};
export type Address = {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
};
export type Contact = {
    id?: string;
    firstName?: string;
    lastName?: string;
    primaryPhone?: string;
    secondaryPhone?: string;
    email?: string;
    relationship?: string;
    isLocal?: boolean;
    preferredContactMethod?: string;
    hasPowerOfAttorney?: boolean;
    address?: Address;
    salutation?: string;
    primaryLanguage?: string;
};
export type Enterprise = {
    id?: string;
    name?: string;
    address?: Address;
    phoneNumber?: string;
    administrator?: StaffMember;
    facilities?: Facility[];
    logoUrl?: string;
    notificationApplicationId?: string;
};
export type Facility = {
    id?: string;
    name?: string;
    address?: Address;
    phoneNumber?: string;
    administrator?: StaffMember;
    enterprise?: Enterprise;
    logoUrl?: string;
    emergencyNotificationNumber?: string;
    requestNotificationNumber?: string;
    taskNotificationNumber?: string;
    timezone?: string;
    careZones?: {
        id?: string;
        name?: string;
        facilityId?: string;
        /** A collection of tags used to describe a Unit. These tags will all be collected and used as search parameters, to find matching Units which may have a matching tag. */
        unitTags?: {
            floors?: string[];
            wings?: string[];
            careTypes?: string[];
            buildings?: string[];
            wards?: string[];
            /** Array of strings representing Rids. These will be converted to RIDs on the backend. */
            unitRids?: string[];
        };
    }[];
    /** Delivery methods in order of preference.  Will attempt to send all messages using the first delivery methods, falling back to second delivery method and so on. */
    messageDeliveryMethods?: string[];
    /** Facility status */
    status?: 'QA' | 'PRE_LIVE' | 'LIVE';
    facilityConfig?: {
        isAlertLocationEnabled?: boolean;
        routineCheckIntervalInMin?: number;
    };
};
export type CareZone = {
    id?: string;
    name?: string;
    facilityId?: string;
    /** A collection of tags used to describe a Unit. These tags will all be collected and used as search parameters, to find matching Units which may have a matching tag. */
    unitTags?: {
        floors?: string[];
        wings?: string[];
        careTypes?: string[];
        buildings?: string[];
        wards?: string[];
        /** Array of strings representing Rids. These will be converted to RIDs on the backend. */
        unitRids?: string[];
    };
};
export type StaffMember = {
    id?: string;
    firstName?: string;
    middleName?: string;
    middleInitial?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    /** Address */
    address?: {
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        country?: string;
    };
    facilities?: Facility[];
    enterprise?: Enterprise;
    jobTitle?: string;
    onCallStates?: {
        isOnCall?: boolean;
        lastModified?: string;
        facilityId?: string;
        companyPhoneId?: string;
    }[];
    shouldReceiveAllCareMessages?: boolean;
    isCareAdmin?: boolean;
    isArchived?: boolean;
    isAgencyStaff?: boolean;
    staffMemberFacilityConfigs?: {
        id?: string;
        facilityId?: string;
        staffMemberId?: string;
        careZones?: CareZone[];
        jobTitle?: string;
        isAgencyStaff?: boolean;
        isCareAdmin?: boolean;
        shouldReceiveAllCareMessages?: boolean;
    }[];
};
export type Note = {
    id?: string;
    creationTimestamp?: string;
    lastUpdatedTimestamp?: string;
    content?: string;
    subject?: string;
    creator?: StaffMember;
    lastUpdatedBy?: StaffMember;
};
export type Device = {
    id?: string;
    type?: string;
    connectivityStatus?: string;
    activationStatus?: string;
    hardwareId?: string;
    creationTimestamp?: string;
    notes?: Note[];
    lastPressTimestamp?: string;
};
export type TimeRangeArray = {
    startTime?: string;
    endTime?: string;
}[];
export type Door = {
    rid: string;
    facilityRid: string;
    name?: string;
    doorAlarmSchedule: {
        rid?: string;
        /** If left blank, it is assumed that this a default, facility wide schedule. */
        doorRid?: string;
        facilityRid: string;
        alwaysAlert: boolean;
        neverAlert: boolean;
        /** The object represents a union set of excluded time ranges. Because it is a union set, it is possible to have overlapping time ranges, which will not affect the evaluation of the schedule. In other words, if the current time matches any of the given time ranges, then the schedule is inside it's exclusion window. */
        dailySchedules?: {
            /** A map where the key is a day of the week, and the value is a list of time ranges. It accepts All as a wildcard. */
            dayOfWeekTimeRangeMap?: {
                [key: string]: TimeRangeArray;
            };
            dayOfMonthTimeRangeMap?: {
                [key: string]: TimeRangeArray;
            };
        };
        /** An inverted representation of the current schedule for the day. It represents the times that a door alarm will fire on a given day. */
        currentDayDisplaySchedules?: string;
        holidaySchedules?: {
            [key: string]: {
                startTime?: string;
                endTime?: string;
            }[];
        };
    };
    unit: Unit;
    deviceAllocation?: {
        room?: string;
        device?: Device;
        id?: string;
        door?: Door;
        unit?: Unit;
        facility?: Facility;
        enterprise?: Enterprise;
        displayName?: string;
        resident?: Resident;
        isCheckIn?: boolean;
        isSilent?: boolean;
    };
};
export type DeviceAllocation = {
    room?: string;
    device?: Device;
    id?: string;
    door?: Door;
    unit?: Unit;
    facility?: Facility;
    enterprise?: Enterprise;
    displayName?: string;
    resident?: Resident;
    isCheckIn?: boolean;
    isSilent?: boolean;
};
export type Unit = {
    id?: string;
    currentOccupants?: Resident[];
    devices?: DeviceAllocation[];
    phoneNumber?: string;
    displayName?: string;
    facility?: Facility;
    enterprise?: Enterprise;
    floor?: string;
    wing?: string;
    careType?: string;
    building?: string;
    ward?: string;
};
export type ProfessionalContact = {
    id?: string;
    companyName?: string;
    companyDescription?: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    officePhoneNumber?: string;
    personalPhoneNumber?: string;
    email?: string;
    officeFax?: string;
};
export type Resident = {
    id?: string;
    salutation?: string;
    firstName?: string;
    middleName?: string;
    middleInitial?: string;
    lastName?: string;
    dateOfBirth?: string;
    age?: number;
    personalPhone?: string;
    createTimestampMs?: number;
    updateTimestampMs?: number;
    email?: string;
    /** Contact of a resident */
    emergencyContact?: {
        id?: string;
        firstName?: string;
        lastName?: string;
        primaryPhone?: string;
        secondaryPhone?: string;
        email?: string;
        relationship?: string;
        isLocal?: boolean;
        preferredContactMethod?: string;
        hasPowerOfAttorney?: boolean;
        address?: Address;
        salutation?: string;
        primaryLanguage?: string;
    };
    contacts?: Contact[];
    unit?: Unit;
    primaryCarePhysician?: {
        id?: string;
        companyName?: string;
        companyDescription?: string;
        salutation?: string;
        firstName?: string;
        lastName?: string;
        officePhoneNumber?: string;
        personalPhoneNumber?: string;
        email?: string;
        officeFax?: string;
    };
    specialists?: ProfessionalContact[];
    notes?: Note[];
    facility?: Facility;
    enterprise?: Enterprise;
    isRoutineCheckEnabled?: boolean;
    isOnHold?: boolean;
    isArchived?: boolean;
    archiveReason?: string;
    /** Resident health information */
    healthInfo?: {
        /** Resident memory health information */
        memoryHealthInfo?: {
            memoryLevel?: string;
        };
        /** Resident continence health information */
        continenceHealthInfo?: {
            continenceLevel?: string;
            productProvider?: string;
            wearsPads?: boolean;
            wearsPullups?: boolean;
            wearsBriefs?: boolean;
        };
        /** Resident's activities of daily living information */
        adlHealthInfo?: {
            safetyLevel?: string;
            feedingLevel?: string;
            bathingLevel?: string;
            toiletingLevel?: string;
            transferringLevel?: string;
            mobilityLevel?: string;
            dressingLevel?: string;
            groomingLevel?: string;
        };
        resuscitationStrategy?: string;
        /** Resident hospice health information */
        hospiceHealthInfo?: {
            hospiceStatus: string;
        };
    };
    preferredHospital?: {
        id?: string;
        name?: string;
        phoneNumber?: string;
        faxNumber?: string;
        address?: Address;
    };
};
export type Group = {
    id?: string;
    name?: string;
    members?: StaffMember[];
    numberOfMembers?: number;
    iconUrl?: string;
    facility?: Facility;
    enterprise?: Enterprise;
};
export type TaskSchedule = {
    id?: string;
    taskTemplate?: {
        title?: string;
        description?: string;
        severity?: string;
        assignee?: StaffMember;
        group?: Group;
        resident?: Resident;
        unit?: Unit;
    };
    scheduleRecurrenceDefinition?: {
        cronScheduleExpression?: string;
        futureExecutionTimeInMs?: number;
        durationInMinutes?: number;
    };
    creator?: StaffMember;
    lastUpdatedBy?: StaffMember;
    facility?: Facility;
    enterprise?: Enterprise;
    creationTimestamp?: string;
    lastUpdateTimestamp?: string;
    isRecurring?: boolean;
    hasRunOnce?: boolean;
    lastSuccessfulExecutionTimeInMs?: number;
};
export type Author = {
    resident?: Resident;
    staffMember?: StaffMember;
    device?: {
        id?: string;
        type?: string;
        connectivityStatus?: string;
        activationStatus?: string;
        hardwareId?: string;
        creationTimestamp?: string;
        notes?: Note[];
        lastPressTimestamp?: string;
    };
    schedule?: TaskSchedule;
};
export type AlertOutcome = {
    rid?: string;
    /** categorizes the resolution of an alert */
    type?: 'RESIDENT_INTERACTION' | 'TEST_ALERT' | 'FALSE_ALARM';
    taskUpdateRid?: string;
    taskRid?: string;
};
export const {
    useCreateLoggedCareMutation,
    useCreateCareOutcomesMutation,
    useSearchCareOutcomesMutation,
    useGetCareEventQuery,
    useLazyGetCareEventQuery,
    useUpdateCareEventMutation,
    useAddCareEventNoteMutation,
    useGetCareEventsQuery,
    useLazyGetCareEventsQuery,
    useCreateCareEventMutation,
    useGetLatestCareEventsQuery,
    useLazyGetLatestCareEventsQuery,
    useMigrateTaskToCareEventMutation,
} = injectedRtkApi;
