import { useState, useContext, useMemo, SyntheticEvent } from 'react';

import { faSquareCheck, faSquareXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import CheckboxGroupOptions, { Variants } from 'src/components/fragments/CheckboxGroupOptions';
import { CareTypes } from 'src/constants/CareTypes';
import { Drawers } from 'src/constants/Drawers';
import {
    ResolutionCategoriesSorted,
    IndependentLivingResolutions,
    CareRefusalOptions,
    ResolutionTypes,
} from 'src/constants/ResolutionTypes';

interface SelectMultiOutcomeResolutionDrawerProps extends DrawerComponentProps {
    analyticsPrefix?: string;
    hideAccidentallyPressed?: boolean;
    hideFoundDevice?: boolean;
    hideRoutineCheck?: boolean;
    task?: any;
    isCareRefusal?: boolean;
}

const DrawerName = 'Select Resolution Type Drawer';

function SelectMultiOutcomeResolutionDrawer(props: SelectMultiOutcomeResolutionDrawerProps) {
    const {
        analyticsPrefix,
        hasNextDrawer = false,
        hideAccidentallyPressed = false,
        hideFoundDevice = false,
        hideRoutineCheck = true,
        isCareRefusal = false,
        onNext,
        title = 'Select all care provided',
        task,
    } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const { spacing } = useTheme();
    const isUnitIndependentLiving = task?.unit?.careType === CareTypes.IndependentLiving;

    // Determine which resolution types to show based on filters + care type
    const filteredResolutionOptions = useMemo(() => {
        const resolutionsForCareType = isCareRefusal
            ? CareRefusalOptions
            : isUnitIndependentLiving
            ? IndependentLivingResolutions
            : ResolutionCategoriesSorted;
        return resolutionsForCareType.map((category) => ({
            displayName: category.displayName,
            resolutionTypes: category.subTypes.filter((type) => {
                if (hideAccidentallyPressed && type.id === ResolutionTypes.AccidentallyPressed.id) {
                    return false;
                }

                if (hideRoutineCheck && type.id === ResolutionTypes.RoutineCheck.id) {
                    return false;
                }

                return true;
            }),
        }));
    }, [isUnitIndependentLiving, hideAccidentallyPressed, hideRoutineCheck, isCareRefusal]);

    const [selectedResolutions, setSelectedResolutions] = useState<Record<string, boolean>>({});
    const selectedResolutionsSet = useMemo(
        () =>
            new Set(
                Object.keys(selectedResolutions).filter(
                    (resolutionId) => selectedResolutions[resolutionId]
                )
            ),
        [selectedResolutions]
    );

    const isTestAlertSelected = useMemo(
        () => selectedResolutions[ResolutionTypes.FoundDevice.id],
        [selectedResolutions]
    );

    const selectedResolutionsArray = isTestAlertSelected
        ? [ResolutionTypes.FoundDevice.id]
        : Array.from(selectedResolutionsSet);

    const careOutcomes = selectedResolutionsArray.map((careProvided) => ({
        outcome: isCareRefusal ? 'REFUSED' : 'COMPLETED',
        careProvided,
    }));

    const numberResolutionsToSumbit = useMemo(
        () => (isTestAlertSelected ? 1 : selectedResolutionsSet.size),
        [isTestAlertSelected, selectedResolutionsSet]
    );

    const handleSelectResolution = (resolutionId: string, isChecked: boolean) => {
        trackEvent('Resolution Type Clicked', {
            resolutionId,
            isChecked,
            objectType: ObjectTypes.Checkbox,
            context: DrawerName,
        });
        setSelectedResolutions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [resolutionId]: isChecked,
        }));
    };

    const handleClearAll = () => {
        trackEvent('Clear All Clicked', {
            context: DrawerName,
            objectType: ObjectTypes.Button,
            numResolutionsCleared: selectedResolutionsSet.size,
        });

        setSelectedResolutions({});
    };

    const handleSelectButton = (e: SyntheticEvent) => {
        e.preventDefault();

        trackEvent('Select Button Clicked', {
            objectType: ObjectTypes.Button,
            context: DrawerName,
            taskId: task?.id,
            numResolutionsSelected: selectedResolutionsSet.size,
            isTestAlertSelected,
        });

        onNext(
            {
                analyticsPrefix,
                careOutcomes,
                alertOutcomeType: isTestAlertSelected ? 'TEST_ALERT' : 'RESIDENT_INTERACTION',
            },
            hasNextDrawer ? undefined : { type: Drawers.ResolveTask }
        );
    };

    return (
        <>
            <DrawerTitle sx={{ p: 0 }} gap={0}>
                <Box
                    sx={{
                        px: 2.5,
                        pt: 9,
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Divider />
            </DrawerTitle>
            <DrawerBody sx={{ p: 0 }}>
                <Stack divider={<Divider />}>
                    {filteredResolutionOptions.map((resolutionCategory) => (
                        <Stack
                            key={resolutionCategory.displayName}
                            sx={{
                                p: 2.5,
                                pt: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                    bgcolor: 'white.main',
                                    py: 2.5,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {resolutionCategory.displayName}
                                </Typography>
                            </Box>

                            <CheckboxGroupOptions
                                options={resolutionCategory.resolutionTypes.map(
                                    (resolutionType) => ({
                                        value: resolutionType.id,
                                        label: (
                                            <Stack
                                                direction="row"
                                                sx={{
                                                    alignItems: 'center',
                                                    py: 0.875,
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: isTestAlertSelected
                                                            ? 'grey.300'
                                                            : 'primary.main',
                                                        flex: '1',
                                                    }}
                                                >
                                                    {resolutionType.displayName}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: spacing(5),
                                                        height: spacing(5),
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {resolutionType.icon && (
                                                        <resolutionType.icon
                                                            opacity={!isTestAlertSelected ? 1 : 0.3}
                                                        />
                                                    )}
                                                </Box>
                                            </Stack>
                                        ),
                                        disabled: isTestAlertSelected,
                                    })
                                )}
                                onSelectOption={handleSelectResolution}
                                selectedOptions={selectedResolutions}
                                variant={Variants.Outlined}
                                checkboxProps={{
                                    color: isCareRefusal ? 'error' : 'primary',
                                    checkedIcon: isCareRefusal ? (
                                        <FontAwesomeIcon size="xl" icon={faSquareXmark} />
                                    ) : (
                                        <FontAwesomeIcon size="xl" icon={faSquareCheck} />
                                    ),
                                }}
                                formGroupProps={{
                                    'aria-label': 'Select Care Outcomes From List',
                                }}
                            />
                        </Stack>
                    ))}
                    {/* Test Alert */}
                    {!hideFoundDevice && (
                        <Stack
                            sx={{
                                p: 2.5,
                                pb: 4,
                                gap: 2.5,
                                bgcolor: 'grey.100',
                            }}
                        >
                            <Stack
                                sx={{
                                    gap: 1,
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    System Test
                                </Typography>
                                <Typography variant="subtitle1">
                                    A resident didn&apos;t set off this alert and nobody needs help.
                                </Typography>
                            </Stack>
                            <CheckboxGroupOptions
                                variant={Variants.Outlined}
                                selectedOptions={selectedResolutions}
                                options={[
                                    {
                                        value: ResolutionTypes.FoundDevice.id,
                                        label: ResolutionTypes.FoundDevice.displayName,
                                    },
                                ]}
                                onSelectOption={handleSelectResolution}
                                formGroupProps={{
                                    'aria-label': 'Test Alert Check Box',
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
            </DrawerBody>
            <DrawerFooter showShadow>
                <Stack
                    direction="row"
                    sx={{
                        gap: 1.5,
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleClearAll}
                        disableFocusRipple
                        disableElevation
                        disabled={selectedResolutionsSet.size === 0}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Clear All
                        </Typography>
                    </Button>
                    <Button
                        fullWidth
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={handleSelectButton}
                        disableFocusRipple
                        disableElevation
                        disabled={selectedResolutionsSet.size === 0}
                    >
                        {selectedResolutionsSet.size <= 0
                            ? 'Select'
                            : 'Select (' + numberResolutionsToSumbit + ')'}
                    </Button>
                </Stack>
            </DrawerFooter>
        </>
    );
}

export default SelectMultiOutcomeResolutionDrawer;
