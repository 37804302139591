import { useContext } from 'react';

import StackedButtonOptions from '../fragments/StackedButtonOptions';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { Drawers } from 'src/constants/Drawers';
import { ResolutionType, ResolutionTypes } from 'src/constants/ResolutionTypes';

const DrawerName = 'Select Emergency Reason Drawer';
const EmergencyReasonOptions = [
    {
        label: 'Fall',
        value: ResolutionTypes.Fall,
    },
    {
        label: 'Other Emergency',
        value: ResolutionTypes.OtherEmergency,
    },
];

interface SelectEmergencyReasonDrawerProps extends DrawerComponentProps {
    analyticsPrefix?: string;
    shouldPersist?: boolean;
}

function SelectEmergencyReasonDrawer(props: SelectEmergencyReasonDrawerProps) {
    const {
        analyticsPrefix,
        hasNextDrawer = false,
        onNext,
        onPrevious,
        shouldPersist = false,
        title = 'What was the emergency?',
    } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const handleSelectEmergencyReason = (emergencyReason: ResolutionType) => {
        trackEvent('Emergency Reason Clicked', {
            emergencyReason: emergencyReason.id,
            context: DrawerName,
        });

        onNext(
            {
                analyticsPrefix,
                careOutcomes: [{ outcome: 'COMPLETED', careProvided: emergencyReason.id }],
            },
            hasNextDrawer ? undefined : { type: Drawers.ResolveTask }
        );
    };

    const handleGoBack = () => {
        trackEvent('Go Back Clicked', { context: DrawerName });
        onPrevious(shouldPersist); // false to prevent adding this to the "future" queue
    };

    const cancelAction = { label: 'Go back', onSelect: () => handleGoBack() };

    return (
        <>
            <DrawerTitle title={title} />
            <DrawerFooter>
                <StackedButtonOptions
                    cancelAction={cancelAction}
                    onSelect={handleSelectEmergencyReason}
                    options={EmergencyReasonOptions}
                />
            </DrawerFooter>
        </>
    );
}

export default SelectEmergencyReasonDrawer;
