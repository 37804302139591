import { useContext } from 'react';

import { Button, Stack } from '@mui/material';

import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { Drawers } from 'src/constants/Drawers';
import { ResolutionTypes } from 'src/constants/ResolutionTypes';

interface SelectWasFallDrawerProps extends DrawerComponentProps {
    analyticsPrefix?: string;
}

const DrawerName = 'Select Was Fall Drawer';

function SelectWasFallDrawer(props: SelectWasFallDrawerProps) {
    const { onNext, onClose, hasNextDrawer } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const handleWasFall = (wasFall: boolean) => {
        const nextDrawer = hasNextDrawer ? undefined : { type: Drawers.ResolveTask };
        if (wasFall) {
            trackEvent('Was Fall Clicked', { context: DrawerName });
            onNext(
                {
                    careOutcomes: [{ outcome: 'COMPLETED', careProvided: ResolutionTypes.Fall.id }],
                    alertOutcomeType: 'RESIDENT_INTERACTION',
                },
                nextDrawer
            );
        } else {
            trackEvent('Was Not Fall Clicked', { context: DrawerName });
            onNext(
                {
                    careOutcomes: [
                        {
                            outcome: 'NOT_COMPLETED',
                            careProvided: ResolutionTypes.NoCareGiven.id,
                        },
                    ],
                    alertOutcomeType: 'FALSE_ALARM',
                },
                nextDrawer
            );
        }
    };

    const handleCancel = () => {
        trackEvent('Cancel Clicked', { context: DrawerName });
        onClose();
    };

    return (
        <>
            <DrawerTitle title="Was this a fall?" />
            <DrawerFooter>
                <Stack
                    sx={{
                        gap: 2,
                    }}
                >
                    <Button variant="outlined" color="primary" onClick={() => handleWasFall(true)}>
                        Yes
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => handleWasFall(false)}>
                        No
                    </Button>
                    <Button variant="text" color="primary" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Stack>
            </DrawerFooter>
        </>
    );
}

export default SelectWasFallDrawer;
