import { CareTypes } from 'src/constants/CareTypes';
import { DeviceTypes } from 'src/constants/DeviceTypes';
import { HospiceStatus } from 'src/constants/HospiceStatus';
import { ResuscitationStrategies } from 'src/constants/ResuscitationStrategies';

export const MockTrainingResident = {
    id: 'ri.model.0.mock-resident.001',
    salutation: 'MR',
    firstName: 'Sage',
    lastName: 'Resident',
    dateOfBirth: '1940-01-05',
    age: 85,
    personalPhone: '+15555555556',
    email: 'sage@resident.com',
    isRoutineCheckEnabled: false,
    isOnHold: false,
    isArchived: false,
    healthInfo: {
        memoryHealthInfo: {
            memoryLevel: 'UNKNOWN',
        },
        continenceHealthInfo: {
            continenceLevel: 'UNKNOWN',
            productProvider: 'UNKNOWN',
            wearsPads: false,
            wearsPullups: false,
            wearsBriefs: true,
        },
        adlHealthInfo: {
            safetyLevel: 'UNKNOWN',
            feedingLevel: 'UNKNOWN',
            bathingLevel: 'UNKNOWN',
            toiletingLevel: 'UNKNOWN',
            transferringLevel: 'UNKNOWN',
            mobilityLevel: 'UNKNOWN',
            dressingLevel: 'UNKNOWN',
            groomingLevel: 'UNKNOWN',
        },
        resuscitationStrategy: ResuscitationStrategies.CPR,
        hospiceHealthInfo: {
            hospiceStatus: HospiceStatus.UNKNOWN,
        },
    },
};

export const MockTrainingUnit = {
    id: 'ri.model.0.mock-unit.001',
    currentOccupants: [MockTrainingResident],
    devices: [],
    phoneNumber: '+12223334444',
    displayName: 'Sage Training - 101',
    careType: CareTypes.AssistedLiving,
    ward: CareTypes.AssistedLiving,
};

export const MockTrainingResidentPendant = {
    id: 'ri.model.0.mock-device.001',
    type: DeviceTypes.Button,
    hardwareId: 'TRAINING-001',
};

export const MockTrainingResidentPendantAllocation = {
    room: 'NONE',
    device: MockTrainingResidentPendant,
    id: 'ri.model.0.mock-device-allocation.001',
    displayName: 'TRAINING-001',
    isCheckIn: false,
    resident: MockTrainingResident,
    unit: MockTrainingUnit,
};
