import { Resident, LatestCareActivityMap } from 'src/store/sageAdminApi';

export const getTimeSinceLastCareActivity = (
    resident?: Resident,
    latestActivityMap?: LatestCareActivityMap
) => {
    if (resident?.id) {
        const latestActivity = latestActivityMap?.[resident.id];
        if (latestActivity?.timeSinceLastCareActivityInMin !== undefined) {
            return latestActivity.timeSinceLastCareActivityInMin;
        } else {
            // default to the time the resident was created to determine if
            // a check is overdue.
            const createdAt = resident.createTimestampMs || 0;
            const now = Date.now();
            return Math.floor((now - createdAt) / 60000); //  milliseconds => minutes
        }
    } else {
        return 0;
    }
};
