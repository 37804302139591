export const DeviceTypes = {
    Button: 'Sage Button',
    DoorAlarm: 'Door Alarm',
    FallDetectionDevice: 'Fall Detection Device',
};

export const DeviceTypeLabels = {
    [DeviceTypes.Button]: 'Button',
    [DeviceTypes.DoorAlarm]: 'Door Sensor',
    [DeviceTypes.FallDetectionDevice]: 'Fall Detection Device',
};
