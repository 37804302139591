import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmergencyAlert from '../components/snackbars/EmergencyAlert';
import {
    MockTrainingUnit,
    MockTrainingResident,
    MockTrainingResidentPendant,
} from '../constants/MockTrainingData';
import { TaskStatuses } from '../constants/TaskStatuses';
import { TaskUpdateTypes } from '../constants/TaskUpdateTypes';
import { selectCurrentUser } from '../store/configSlice';
import { Task, AlertOutcomeType } from '../store/sageAdminApi';
import { setTrainingAlert, selectTrainingAlert, cancelConfirmation } from '../store/trainingSlice';
import { snackActions } from '../utils/snackBarTools';
import { TaskSeverityLevels } from 'src/constants/TaskSeverityLevels';
import { CareProvided } from 'src/store/sageCareServiceApi';

interface UseTrainingProps {
    taskRid?: string;
}

const getRid = (prefix: string) => {
    return `${prefix}.${nanoid(10)}`;
};

const alertTitle = `${MockTrainingUnit.displayName} has reported an emergency`;

function useTraining(props?: UseTrainingProps) {
    const taskRid = props?.taskRid;
    const dispatch = useDispatch();
    const trainingAlert = useSelector(selectTrainingAlert);
    const currentUser = useSelector(selectCurrentUser);
    const isTrainingAlert = taskRid === trainingAlert?.id;

    const createTrainingAlert = useCallback(() => {
        const nowSeconds = Math.floor(Date.now() / 1000);
        const nowMs = Date.now();
        const taskId = getRid('ri.model.0.mock-task');

        const newAlert = {
            id: taskId,
            title: alertTitle,
            status: TaskStatuses.OpenUnclaimed.id,
            severity: TaskSeverityLevels.Emergency.id,
            updates: [
                {
                    id: getRid('ri.model.0.mock-task-update'),
                    type: TaskUpdateTypes.Created,
                    creationTimestamp: nowSeconds.toString(),
                    creationTimestampInMs: nowMs.toString(),
                    author: {
                        device: MockTrainingResidentPendant,
                    },
                },
            ],
            unit: MockTrainingUnit,
            resident: MockTrainingResident,
            creator: {
                device: MockTrainingResidentPendant,
            },
            creationTimestamp: nowSeconds.toString(),
            lastUpdateTimestamp: nowSeconds.toString(),
        } as Task;

        dispatch(setTrainingAlert(newAlert));

        snackActions.error('', {
            autoHideDuration: 15_000,
            content: (key) => <EmergencyAlert id={key} taskId={taskId} message={alertTitle} />,
        });
    }, [dispatch]);

    const claimTrainingAlert = useCallback(() => {
        dispatch(
            setTrainingAlert({
                ...trainingAlert,
                status: TaskStatuses.OpenClaimed.id,
                updates: [
                    ...trainingAlert?.updates!,
                    {
                        id: getRid('ri.model.0.mock-task-update'),
                        type: TaskUpdateTypes.Claim,
                        creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                        creationTimestampInMs: Date.now().toString(),
                        description: `${currentUser?.firstName} ${currentUser?.lastName} claimed the alert`,
                        author: {
                            staffMember: currentUser,
                        },
                    },
                    {
                        id: getRid('ri.model.0.mock-task-update'),
                        type: TaskUpdateTypes.StatusChange,
                        creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                        creationTimestampInMs: Date.now().toString(),
                        description: `${currentUser?.firstName} ${currentUser?.lastName} changed the alert status from ${TaskStatuses.OpenUnclaimed.displayName} to ${TaskStatuses.OpenClaimed.displayName}`,
                        author: {
                            staffMember: currentUser,
                        },
                    },
                ],
                assignee: currentUser,
            })
        );
    }, [currentUser, dispatch, trainingAlert]);

    const unClaimTrainingAlert = useCallback(() => {
        dispatch(
            setTrainingAlert({
                ...trainingAlert,
                status: TaskStatuses.OpenUnclaimed.id,
                updates: [
                    ...trainingAlert?.updates!,
                    {
                        id: getRid('ri.model.0.mock-task-update'),
                        type: TaskUpdateTypes.StatusChange,
                        creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                        creationTimestampInMs: Date.now().toString(),
                        description: `${currentUser?.firstName} ${currentUser?.lastName} changed the alert status from ${TaskStatuses.OpenClaimed.displayName} to ${TaskStatuses.OpenUnclaimed.displayName}`,
                        author: {
                            staffMember: currentUser,
                        },
                    },
                    {
                        id: getRid('ri.model.0.mock-task-update'),
                        type: TaskUpdateTypes.Unassignment,
                        creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                        creationTimestampInMs: Date.now().toString(),
                        description: `${currentUser?.firstName} ${currentUser?.lastName} unclaimed the alert`,
                        author: {
                            staffMember: currentUser,
                        },
                    },
                ],
                assignee: undefined,
            })
        );
    }, [currentUser, dispatch, trainingAlert]);

    const startConfirmingTrainingAlert = useCallback(() => {
        dispatch(
            setTrainingAlert({
                ...trainingAlert,
                status: TaskStatuses.OpenConfirming.id,
                updates: [
                    ...trainingAlert?.updates!,
                    {
                        id: getRid('ri.model.0.mock-task-update'),
                        type: TaskUpdateTypes.StatusChange,
                        creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                        creationTimestampInMs: Date.now().toString(),
                        description: `${currentUser?.firstName} ${currentUser?.lastName} changed the alert status from ${TaskStatuses.OpenClaimed.displayName} to ${TaskStatuses.OpenConfirming.displayName}`,
                        author: {
                            staffMember: currentUser,
                        },
                    },
                ],
            })
        );
    }, [currentUser, dispatch, trainingAlert]);

    const cancelConfirmingTrainingAlert = useCallback(() => {
        dispatch(cancelConfirmation());
    }, [dispatch]);

    const confirmTrainingAlert = useCallback(() => {
        dispatch(
            setTrainingAlert({
                ...trainingAlert,
                status: TaskStatuses.OpenConfirmed.id,
                updates: [
                    ...trainingAlert?.updates!,
                    {
                        id: getRid('ri.model.0.mock-task-update'),
                        type: TaskUpdateTypes.CareConfirmed,
                        creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                        creationTimestampInMs: Date.now().toString(),
                        description: `${currentUser?.firstName} ${currentUser?.lastName} confirmed the alert`,
                        author: {
                            device: MockTrainingResidentPendant,
                        },
                    },
                ],
            })
        );
    }, [currentUser?.firstName, currentUser?.lastName, dispatch, trainingAlert]);

    const resolveTrainingAlert = useCallback(
        (resolutionTypes: CareProvided[], alertOutcomeType: AlertOutcomeType, notes: string) => {
            const resolutionUpdates = resolutionTypes.map((resolutionType) => {
                return {
                    id: getRid('ri.model.0.mock-task-update'),
                    type: TaskUpdateTypes.ManualEntry,
                    creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                    creationTimestampInMs: Date.now().toString(),
                    description: resolutionType,
                    author: {
                        staffMember: currentUser,
                    },
                };
            });

            dispatch(
                setTrainingAlert({
                    ...trainingAlert,
                    status: TaskStatuses.ClosedResolved.id,
                    updates: [
                        ...trainingAlert?.updates!,
                        ...resolutionUpdates!,
                        {
                            id: getRid('ri.model.0.mock-task-update'),
                            type: TaskUpdateTypes.Closed,
                            creationTimestamp: Math.floor(Date.now() / 1000).toString(),
                            creationTimestampInMs: Date.now().toString(),
                            description: `${currentUser?.firstName} ${currentUser?.lastName} marked as resolved`,
                            author: {
                                staffMember: currentUser,
                            },
                        },
                    ],
                    notes: [
                        {
                            id: getRid('ri.model.0.mock-note'),
                            content: notes,
                            subject: 'Resolution Note',
                            creator: {
                                staffMember: currentUser,
                            },
                        },
                    ],
                })
            );
        },
        [currentUser, dispatch, trainingAlert]
    );

    const addNoteToTrainingAlert = useCallback(
        (notes: string) => {
            dispatch(
                setTrainingAlert({
                    ...trainingAlert,
                    notes: [
                        ...(trainingAlert?.notes || []),
                        {
                            id: getRid('ri.model.0.mock-note'),
                            content: notes,
                            subject: 'Task Note',
                            creator: {
                                staffMember: currentUser,
                            },
                        },
                    ],
                })
            );
        },
        [currentUser, dispatch, trainingAlert]
    );

    const removeTrainingAlert = () => {
        dispatch(setTrainingAlert(undefined));
    };

    return {
        trainingAlert,
        isTrainingAlert,
        startConfirmingTrainingAlert,
        cancelConfirmingTrainingAlert,
        confirmTrainingAlert,
        resolveTrainingAlert,
        createTrainingAlert,
        claimTrainingAlert,
        unClaimTrainingAlert,
        addNoteToTrainingAlert,
        removeTrainingAlert,
    };
}

export default useTraining;
