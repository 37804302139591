import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { faHeart, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, Divider, Stack, Typography, useTheme } from '@mui/material';

import RoutineCheckChip from '../fragments/RoutineCheckChip';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import ResidentAvatar from 'src/component-library/ResidentAvatar';
import ToggleContainer from 'src/component-library/ToggleContainer';
import HospiceChip from 'src/components/fragments/HospiceChip';
import ResuscitationChip from 'src/components/fragments/ResuscitationChip';
import UnitCheckInCard from 'src/components/fragments/UnitCheckInCard';
import { CareTypeLabelsAbbreviated, CareTypes } from 'src/constants/CareTypes';
import { CheckInStatuses } from 'src/constants/CheckIn';
import { Drawers } from 'src/constants/Drawers';
import useCheckIns from 'src/hooks/useCheckIns';
import usePinnedResidentIds from 'src/hooks/usePinnedResidentIds';
import useUserPermissions from 'src/hooks/useUserPermissions';
import { selectRoutineCheckInterval } from 'src/store/configSlice';
import {
    CheckIn,
    Resident,
    useGetLatestCareActivityQuery,
    useGetResidentQuery,
} from 'src/store/sageAdminApi';
import { validateHospiceStatus } from 'src/utils/HospiceUtils';
import { getTimeSinceLastCareActivity } from 'src/utils/latestCareTimeUtils';
import { snackActions } from 'src/utils/snackBarTools';

interface ResidentDetailsDrawerProps extends DrawerComponentProps {
    resident: Resident;
}

const DrawerName = 'Resident Details Drawer';

function ResidentDetailsDrawer(props: ResidentDetailsDrawerProps) {
    const { hasNextDrawer, onClose, onNext, resident } = props;

    const { palette } = useTheme();
    const routineCheckInterval = useSelector(selectRoutineCheckInterval);
    const navigate = useNavigate();

    const { id: residentId, firstName, lastName, age, unit } = resident;
    const residentName = `${firstName} ${lastName}`;
    const unitIsIL = unit?.careType === CareTypes.IndependentLiving;

    const { trackEvent } = useContext(AnalyticsContext);

    const { canSeePHI, isLoading: isLoadingPermissions } = useUserPermissions();
    const { addToPinned, removeFromPinned, pinnedResidentIds } = usePinnedResidentIds();
    const isPinned = pinnedResidentIds?.has(residentId!);

    const { data: residentData, isLoading: isLoadingResident } = useGetResidentQuery(
        { residentId: residentId! },
        { skip: !residentId }
    );

    const isOnHold = !!resident?.isOnHold;
    const isArchived = !!resident?.isArchived;
    const isActive = !isOnHold && !isArchived;

    const residentUnitId = residentData?.unit?.id;

    const showActivityProgress =
        resident?.isRoutineCheckEnabled && routineCheckInterval > 0 && isActive && !unitIsIL;

    const { minSinceLastActivity, isLoading: isLoadingCareActivity } =
        useGetLatestCareActivityQuery(
            { getLatestCareActivityRequest: { residentRids: [residentId!] } },
            {
                skip: !residentId,
                pollingInterval: showActivityProgress ? 60_000 : 0,
                selectFromResult: (results) => ({
                    ...results,
                    minSinceLastActivity: getTimeSinceLastCareActivity(resident, results.data),
                }),
            }
        );

    const { checkIns } = useCheckIns({ unitId: residentUnitId });

    const unitIsCheckedIn =
        checkIns?.some(
            (checkIn: CheckIn) =>
                checkIn?.unit?.id === residentUnitId && checkIn?.status === CheckInStatuses.Complete
        ) || false;

    const { hospiceHealthInfo, resuscitationStrategy } = residentData?.healthInfo || {};

    const handleTogglePinnedResident = () => {
        if (isActive) {
            if (isPinned) {
                trackEvent('Remove From Care Assignment Clicked', {
                    objectType: ObjectTypes.Button,
                    residentId,
                    context: DrawerName,
                });

                removeFromPinned(residentId!);
                snackActions.success('Resident Removed From Care Assignment', {
                    autoHideDuration: 2_000,
                });
            } else {
                trackEvent('Add To Care Assignment Clicked', {
                    objectType: ObjectTypes.Button,
                    residentId,
                    context: DrawerName,
                });

                addToPinned(residentId!);
                snackActions.success('Resident Added To Care Assignment', {
                    autoHideDuration: 2_000,
                });
            }
        }
    };

    const handleClickViewProfile = () => {
        trackEvent('View Profile Button Clicked', {
            residentId,
            context: DrawerName,
        });
        onClose();

        if (residentId) {
            navigate(`/residents/resident/${residentId}`);
        }
    };

    const handleClickLogCare = () => {
        trackEvent('Log Care Button Clicked', {
            objectType: ObjectTypes.Button,
            residentId,
            context: DrawerName,
        });

        onNext(
            {
                hideAccidentallyPressed: true,
                hideFoundDevice: true,
                hideRoutineCheck: false,
                redirectOnSuccess: false,
                resident: residentData,
                residentId,
                successMessage: `Care logged for ${residentName}`,
            },
            hasNextDrawer
                ? undefined
                : { type: Drawers.SelectMultiOutcomeResolution, showCloseIcon: true }
        );
    };

    const handleClickLogRefusal = () => {
        trackEvent('Log Refusal Button Clicked', {
            objectType: ObjectTypes.Button,
            residentId,
            context: DrawerName,
        });

        onNext(
            {
                hideAccidentallyPressed: true,
                hideFoundDevice: true,
                hideRoutineCheck: true,
                redirectOnSuccess: false,
                resident: residentData,
                residentId,
                isCareRefusal: true,
                successMessage: `Refused care logged successfully`,
                title: 'Select all care refused',
            },
            hasNextDrawer
                ? undefined
                : { type: Drawers.SelectMultiOutcomeResolution, showCloseIcon: true }
        );
    };

    return (
        <>
            <DrawerBody sx={{ pb: 0.5 }}>
                <Card
                    onClick={handleClickViewProfile}
                    data-testid="view-resident-profile"
                    sx={{ mt: 6, cursor: 'pointer' }}
                >
                    {showActivityProgress && (
                        <Box sx={{ pt: 2.5, px: 2.5 }}>
                            <RoutineCheckChip
                                minSinceLastActivity={minSinceLastActivity}
                                isLoading={isLoadingCareActivity}
                                showPrefix
                            />
                        </Box>
                    )}
                    <Stack
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            p: 2.5,
                            gap: 1.5,
                        }}
                    >
                        <ResidentAvatar
                            resident={resident}
                            badgePosition={{ vertical: 'top', horizontal: 'right' }}
                            isCheckedIn={unitIsCheckedIn}
                            isPinned={isActive && isPinned}
                            showProgress={showActivityProgress}
                            progress={{
                                current: minSinceLastActivity,
                                max: routineCheckInterval,
                            }}
                            size="large"
                        />
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                {residentName}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.900' }}>
                                {`Age: `}
                                <b>{age}</b>
                                {' • '}
                                {unit ? (
                                    <>
                                        Unit:{' '}
                                        <b>
                                            {unit.displayName}
                                            {' | '}
                                            {unit?.careType &&
                                                CareTypeLabelsAbbreviated[unit.careType]}
                                        </b>
                                    </>
                                ) : (
                                    'No Unit'
                                )}
                            </Typography>
                        </Box>
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            size="lg"
                            color={palette.primary.main}
                        />
                    </Stack>
                    {!isLoadingResident && !isLoadingPermissions && canSeePHI && (
                        <>
                            <Divider />
                            <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem />}
                                alignItems="center"
                            >
                                {/* DNR Display */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: 1,
                                        flexBasis: '50%',
                                        pt: 1,
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        sx={{ pb: 1.5, gap: 0.5 }}
                                    >
                                        <Typography component="div" variant="body2">
                                            Code Status
                                        </Typography>
                                        <ResuscitationChip
                                            resuscitationStrategy={resuscitationStrategy}
                                        />
                                    </Box>
                                </Box>

                                {/* Hospice Display */}

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: 1,
                                        flexBasis: '50%',
                                        pt: 1,
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        sx={{ pb: 1.5, gap: 0.5 }}
                                    >
                                        <Typography
                                            component="div"
                                            variant="body2"
                                            sx={{ whiteSpace: 'nowrap' }}
                                        >
                                            Hospice
                                        </Typography>
                                        <HospiceChip
                                            hospiceStatus={validateHospiceStatus(
                                                hospiceHealthInfo?.hospiceStatus
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </Stack>
                        </>
                    )}
                </Card>
            </DrawerBody>
            <DrawerFooter sx={{ gap: 2.5, justifyContent: 'center', alignItems: 'center' }}>
                {unitIsIL ? (
                    <UnitCheckInCard
                        unit={{ ...unit, currentOccupants: [resident] }}
                        onNext={onNext}
                    />
                ) : isActive ? (
                    <ToggleContainer
                        title="Care Assignment"
                        switchProps={{
                            checked: isPinned,
                            onClick: handleTogglePinnedResident,
                            icon: <FontAwesomeIcon icon={faHeart} />,
                            checkedIcon: <FontAwesomeIcon icon={faHeart} />,
                        }}
                    />
                ) : null}

                <Stack
                    direction="row"
                    sx={{
                        width: '100%',
                        gap: 2.5,
                    }}
                >
                    {!unitIsIL && isActive && (
                        <>
                            <Button
                                variant="contained"
                                color="primaryInverse"
                                disableElevation
                                onClick={handleClickLogRefusal}
                                sx={{ flexBasis: '50%', flexGrow: 1 }}
                            >
                                Log Refusal
                            </Button>

                            <Button
                                variant="contained"
                                disableElevation
                                onClick={handleClickLogCare}
                                sx={{ flexBasis: '50%', flexGrow: 1 }}
                            >
                                Log Care
                            </Button>
                        </>
                    )}
                </Stack>
            </DrawerFooter>
        </>
    );
}

export default ResidentDetailsDrawer;
